import View from './view'
import { connect } from 'react-redux'
import { filterUsersCustomOptionsByTrigger, getUsersSpecialOptionsByTrigger } from '@lenses/workflows'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@root/constants'
import { getActionsEmailOptions, getActionsUserOptions, getDynamicConfigByIdWorkflow, getTriggersByType } from '@selectors/workflows'
import keyBy from 'lodash/keyBy'
import get from 'lodash/get'
import { searchUsers, toggleAddUser } from '@actions/'
import { withRouter } from 'react-router-dom'
import { getMyOrg, getSelf } from '@selectors/me'
import { getCurrentOrg } from '@selectors/org'

const mapStateToProps = (state, ownProps) => {
  const { includeCustomUserFields, triggerType, specialUsers = EMPTY_ARRAY, value, onChange } = ownProps
  const currentOrg = getCurrentOrg(state)
  const idOrg = parseInt(ownProps.match.params.idOrg, 10) || currentOrg.id
  const idWorkflow = ownProps.match.params.idWorkflow

  const triggerTypes = getTriggersByType(state) || EMPTY_OBJECT
  const outputSchemaConfig = Object.values(get(triggerTypes[triggerType], 'outputSchema', {}))
  const outputSchemaConfigByTypes = keyBy(outputSchemaConfig, 'type')
  const triggerUsers = getUsersSpecialOptionsByTrigger(state, ownProps.triggerType) || EMPTY_ARRAY
  const actionUsers = (ownProps.field && ownProps.field.specialUsers) || EMPTY_ARRAY
  const customUserOptions = includeCustomUserFields ? filterUsersCustomOptionsByTrigger(state, getActionsUserOptions(state), triggerType) : EMPTY_ARRAY
  const customEmailOptions = outputSchemaConfig.find(config => config.id === 'triggerUser') ? getActionsEmailOptions(state) : EMPTY_ARRAY
  const dynamicConfigByIdWorkflow = getDynamicConfigByIdWorkflow(state)
  let emailOptionsByWorkflowConfig = []
  if (idWorkflow) {
    emailOptionsByWorkflowConfig = dynamicConfigByIdWorkflow?.[idWorkflow]?.emailOptionsFromTrigger ?? EMPTY_ARRAY
  }

  const allSpecialUsers = [
    ...emailOptionsByWorkflowConfig,
    ...specialUsers,
    ...triggerUsers,
    ...actionUsers,
    ...customUserOptions.map(option => ({ ...option, description: get(outputSchemaConfigByTypes[option.type], 'description', '') })),
    ...customEmailOptions.map(option => ({ ...option, description: get(outputSchemaConfigByTypes[option.type], 'description', '') }))
  ]

  const { addUser } = state.ui

  const currentUserIdOrg = getMyOrg(state)
  const isCurrentUserInOrg = currentUserIdOrg === idOrg
  let newValue = null
  if (Array.isArray(value) && value.find(val => val === 'currentUser')) {
    if (isCurrentUserInOrg) {
      newValue = value.map(val => val === 'currentUser' ? (getSelf(state) || {}).id : val)
    }
    onChange && onChange(newValue)
  } else {
    newValue = value
  }

  return {
    idOrg,
    specialUsers: keyBy(allSpecialUsers.map(u => ({ ...u, id: u.id || u.value })), 'value'),
    addUser,
    value: newValue
  }
}

const mapDispatchToProps = {
  searchUsers,
  toggleAddUser
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
