import React from 'react'
import { Grid, Row, Col } from '@toriihq/design-system'
import { css } from 'glamor'
import { fontWeight } from '@shared/style/sizes'
import Page from '@components/page'
import colors from '@shared/style/colors'
import ReportBox from '@components/reportBox'
import { ReportsTypes } from '@components/reportBox/constants'
import exportPDF from '@helpers/exportPDF'
import { exportExtensionStatusCsv } from '@shared/reports'
import VisibleFor from '@components/visibleFor'
import { SCOPES } from '@root/constants'
import Analytics from '@helpers/analytics'

const CSS = {
  header: css({
    fontSize: '25px',
    fontWeight: fontWeight.semiBold
  }),
  reportsContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    ' > *': {
      marginRight: '30px',
      marginBottom: '30px'
    }
  })
}

class ReportsPage extends React.Component {
  render () {
    const { idOrg, exportSSOAuditCSV, exportInactiveUsersCSV, exportCSVReport, isAppComplianceEnabledInPlan } = this.props
    return (
      <Page title='Reports'>
        <div>
          <section>
            <Grid>
              <Row>
                <Col>
                  <div {...CSS.reportsContainer}>
                    <VisibleFor scopes={[SCOPES.EXPENSE_READ]}>
                      <ReportBox
                        name='Expense analysis'
                        description='A complete view of your monthly/quarterly application expenses'
                        imgName={ReportsTypes.Monthly}
                        reportName='monthly'
                        color={colors.navy}
                        downloadEnabled={false}
                      />
                    </VisibleFor>
                    <ReportBox
                      name='Extension status'
                      description='Your users’ extension status on all different browsers'
                      imgName={ReportsTypes.ExtensionRolloutStatus}
                      reportName='extensionRollout'
                      onCsvDownload={() => exportExtensionStatusCsv({ idOrg })}
                      color={colors.blue}
                    />
                    <ReportBox
                      name='SSO audit'
                      description='Audit managed vs. unmanaged users in your SSO provider'
                      imgName={ReportsTypes.ThirdParty}
                      reportName='ssoAudit'
                      onCsvDownload={exportSSOAuditCSV}
                      onPdfDownload={exportPDF(idOrg, 'ssoAudit')}
                      color={colors.navy}
                      downloadEnabled={false}
                    />
                    <VisibleFor scopes={[SCOPES.USERS_READ]}>
                      <ReportBox
                        name='Inactive users'
                        description='See inactive users and their last usage time'
                        imgName={ReportsTypes.ThirdParty}
                        reportName='inactiveUsers'
                        onCsvDownload={exportInactiveUsersCSV}
                        onPdfDownload={exportPDF(idOrg, 'inactiveUsers')}
                        color={colors.blue}
                      />
                    </VisibleFor>
                    <VisibleFor scopes={[SCOPES.USERS_READ]}>
                      <ReportBox
                        name='Applications and Users'
                        description='Full export of all applications and their users'
                        imgName={ReportsTypes.ThirdParty}
                        reportName='appsAndUsers'
                        onCsvDownload={() => exportCSVReport({
                          idOrg,
                          type: 'appsUsers'
                        })}
                        onPdfDownload={exportPDF(idOrg, 'appsUsers')}
                        color={colors.navy}
                        viewEnabled={false}
                      />
                    </VisibleFor>
                    <VisibleFor scopes={[SCOPES.CONTRACTS_READ]}>
                      <ReportBox
                        name='Applications and Contracts'
                        description='Full export of all applications and their contracts'
                        imgName={ReportsTypes.ThirdParty}
                        reportName='appsAndContracts'
                        onCsvDownload={() => exportCSVReport({
                          idOrg,
                          type: 'appsAndContracts'
                        })}
                        onPdfDownload={exportPDF(idOrg, 'appsAndContracts')}
                        color={colors.blue}
                        viewEnabled={false}
                      />
                    </VisibleFor>
                    <VisibleFor scopes={[SCOPES.APPLICATIONS_READ]}>
                      <ReportBox
                        name='Application Certifications & Compliance'
                        description='Full export of all applications and their security and privacy certifications'
                        imgName={ReportsTypes.ThirdParty}
                        reportName={ReportsTypes.AppCompliance}
                        onCsvDownload={() => {
                          Analytics.track('Click on download compliance csv')
                          exportCSVReport({ idOrg, type: ReportsTypes.AppCompliance })
                        }}
                        onPdfDownload={exportPDF(idOrg, ReportsTypes.AppCompliance)}
                        color={colors.navy}
                        viewEnabled={false}
                        shouldLockReport={!isAppComplianceEnabledInPlan}
                      />
                    </VisibleFor>
                  </div>
                </Col>
              </Row>
            </Grid>
          </section>
        </div>
      </Page>
    )
  }
}

export default ReportsPage
