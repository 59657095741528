import { WORKFLOW_STEP_TYPE, WORKFLOW_TRIGGER_TYPE } from '@shared/types'
import type { IdApp } from '@store/types'
import { TextBadgeColors } from '@toriihq/design-system'

export interface ActionStep {
  type: string
  label: string
  fields: string[]
}

export interface ActionUiConfig {
  type: string
  audit: string
  steps: ActionStep[]
  label?: string
  placeholders?: Record<any, unknown>
  requirementsMessage?: string
  isHidden?: boolean
  isVisibleOnlyToToriiAdmin?: boolean
  isBeta?: boolean
}

export interface AppActionConfig {
  type: string
  uiConfig: ActionUiConfig
  inputSchema: any
}

export interface AppActionsConfig {
  idApp: number
  name: string
  actions: AppActionConfig[]
  imageUrl: string
}

type TriggerType = WORKFLOW_TRIGGER_TYPE
type TriggerOptionValue = `${IdApp}_${TriggerType}`
export type IdInputSchemaField = string
type IdOutputSchemaField = string

export type TriggerTypeToTriggerOptionsValues = Record<TriggerType, TriggerOptionValue>

interface TriggerOption {
  idApp: IdApp
  type: TriggerType
  label: string
  description: string
  src: string
  value: TriggerOptionValue
}

export interface AppTriggerOptions {
  idApp: IdApp
  name: string
  imageUrl: string
  options: TriggerOption[]
}

export interface TriggerInputSchemaFieldValue {
  id: IdInputSchemaField
  type: string
  label?: string
  labelAction?: string
  name?: string
  options?: { value: string, label: string, auditDescription?: string }[]
  autoSelectFirstOption?: boolean
  defaultValue?: any
  validations?: string[]
  requiredSchemaModels?: string[]
  clearStepFieldsOnClear?: boolean
  fieldsToClearOnChange?: string[]
  typeForTemplate?: string
  disabledUntilFieldsAreValid?: string[]
  fieldParams?: { fieldId: string, paramName: string }[]
  showOnFieldSpecificValue?: { id:string, value: string }
}

export interface TriggerOutputSchemaFieldValue {
  id?: IdOutputSchemaField
  type: string
  name?: string
  description: string
  placeholders?: Record<string, string>
  isMainData?: boolean
  expand?: boolean
  optionalForManualRun?: boolean
  enableBranching?: boolean
}

export interface TriggerStepUiConfig {
  type: WORKFLOW_STEP_TYPE.STEP_SETUP
  label: string
  fields: string[]
}

export interface TriggerUiConfig {
  type: TriggerType
  isHidden?: boolean
  label?: string
  description?: string
  imageSrc?: string
  badge?: string
  badgeColor?: TextBadgeColors
  audit: string
  header?: string
  disclaimer?: string
  preview?: {
    header: string
    subHeader?: string
    isFiltersFieldPreview?: boolean
  }
  steps?: TriggerStepUiConfig[]
}

export type TriggerInputSchemaFields = Record<IdInputSchemaField, TriggerInputSchemaFieldValue>
export type TriggerOutputSchemaFields = Record<IdOutputSchemaField, TriggerOutputSchemaFieldValue>

export interface TriggerConfiguration {
  type: TriggerType
  inputSchema?: TriggerInputSchemaFields
  outputSchema: TriggerOutputSchemaFields
  uiConfig: TriggerUiConfig
  supportPreview?: boolean
  supportWorkflowRetroactivelyRun?: boolean
  pollAfterActivation?: boolean
  supportSchedule?: boolean
  isWebhook: boolean
  featureFlag?: string
}

export type TriggersByType = Record<TriggerType, TriggerConfiguration>

// Taxonomy types replicated from torii_syncer on 2024-11-28
export enum ACTION_PRIMARY_CATEGORY {
  jedi = 'jedi',
  auxiliary = 'auxiliary',
  contactUser = 'contactUser',
  assignTask = 'assignTask',
  toriiManagement = 'toriiManagement'
}

export enum ASSIGN_TASK_ACTION_SECONDARY_CATEGORY {
  toriiTask = 'toriiTask',
  toriiOffboardingTask = 'toriiOffboardingTask',
  ticket = 'ticket'
}
