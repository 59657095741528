import { IdUser } from '@store/types'

export interface InputValue {
  testStartTime: string | null
  testStartedBy: IdUser | null
  testPayloadReceivedTime: string | null
  payload: any
  testError: string | null
  testWarning: string | null
}

export enum TRIGGER_PAYLOAD_TEST_STATE {
  READY_TO_TEST = 'READY_TO_TEST',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}
