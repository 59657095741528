import isFunction from 'lodash/isFunction'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'
import partition from 'lodash/partition'
import { getSourceByType, SOURCE_TYPES } from '@root/sourcesConfig'
import { sortAlphabeticallyCompareFunction } from '@lenses/utils'
import { EMPTY_ARRAY, EMPTY_OBJECT, INTEGRATION_CATEGORY } from '@root/constants'
import { INTEGRATION_TYPE } from '@shared/types'
import React from 'react'
import Analytics from '@helpers/analytics'
import { baseColors } from '@shared/style/colors'
import { Link, ThemeProvider } from '@toriihq/design-system'

const googleSourceName = SOURCE_TYPES.google.name

const MAP_SOURCE_NAME_TO_SHORT_NAME = {
  [googleSourceName]: 'Google',
  [SOURCE_TYPES.azure_ad.name]: 'Entra ID'
}

const MAP_APP_NAME_TO_DIRECT_SOURCE = {
  'Google Meet': googleSourceName,
  'Google Drive': googleSourceName,
  'Google Calendar': googleSourceName,
  'Google Chat': googleSourceName,
  'Google Workspace (G Suite)': googleSourceName,
  'Jira': 'Jira Cloud',
  '1Password': 'One Password'
}

const getAppName = ({ name }) => {
  return MAP_APP_NAME_TO_DIRECT_SOURCE[name] || name
}

export const getSourceName = ({ sourceConfig, appName }) => {
  if (isFunction(sourceConfig.name)) {
    return sourceConfig.name({ appName })
  }

  const isSSO = sourceConfig.isSSO || false
  const isDirectSource = sourceConfig.name === getAppName({ name: appName })

  const sourceName = MAP_SOURCE_NAME_TO_SHORT_NAME[sourceConfig.name] || sourceConfig.name
  return isDirectSource ? sourceName : (isSSO ? `${sourceName} logins` : sourceName)
}

export const sortSourcesList = ({ sourcesList, appName }) => {
  const [ directAndExtensionSources, ssoSources ] = partition(sourcesList, source => source === 'extension' || getSourceByType(source).name === getAppName({ name: appName }))
  const [ extensionSource, directSource ] = partition(directAndExtensionSources, source => source === 'extension')
  const sortedSsoSources = ssoSources.sort(sortAlphabeticallyCompareFunction)
  return directSource.concat(sortedSsoSources).concat(extensionSource)
}

export const getUsageAndUsersSources = ({ service = EMPTY_OBJECT, dataSourceCategory = 'usage', defaultSources = EMPTY_ARRAY, usageType = '', pageName = '' }) => {
  const disabledSource = dataSourceCategory === 'users' ? getDisabledUsersSource({ service }) : getDisabledUsageSource({ service, defaultSources, usageType, pageName })
  const { sourceToDisable: directSource } = disabledSource
  const sources = isEmpty(disabledSource) ? defaultSources : uniq([ directSource, ...defaultSources ])
  return { sources, disabledSource }
}

const getDisabledUsersSource = ({ service }) => {
  const { isConnected, integrationCategory, source, appName } = service
  const isExpenseSoftware = integrationCategory === INTEGRATION_CATEGORY.EXPENSE_SOFTWARE
  const showDisabledUsersSource = isExpenseSoftware && isConnected
  return showDisabledUsersSource
    ? {
      sourceToDisable: source,
      tooltipProps: { label: <ThemeProvider>Torii does not sync {appName} users data</ThemeProvider> }
    }
    : EMPTY_OBJECT
}

const getDisabledUsageSource = ({ service, defaultSources, usageType, pageName }) => {
  const { isConnected, supportsUsage: isServiceSupportsUsage, source, isNeverSyncedCustomIntegration } = service
  const isUsageSynced = defaultSources.includes(source)
  const isNoUsageHasBeenSynced = !isServiceSupportsUsage || (isServiceSupportsUsage && !isUsageSynced)
  const showDisabledUsageSource = isConnected && isNoUsageHasBeenSynced && !isNeverSyncedCustomIntegration
  return showDisabledUsageSource
    ? {
      sourceToDisable: source,
      tooltipProps: getDisabledUsageSourceTooltipProps({ service, usageType, pageName }),
      reason: !isServiceSupportsUsage ? 'not supported' : 'not synced'
    }
    : EMPTY_OBJECT
}

const getDisabledUsageSourceTooltipProps = ({ service, usageType, pageName }) => {
  const { appName, supportsUsage, helpCenterLink, integrationType, integrationCategory } = service
  const isToriibot = integrationType === INTEGRATION_TYPE.TORII_BOT
  const isExpenseSoftware = integrationCategory === INTEGRATION_CATEGORY.EXPENSE_SOFTWARE

  const onClick = (pageName, usageType) => Analytics.track(`Click on Read how to sync app usage`, {
    'UI area': pageName ? `${usageType} chart / ${pageName} page` : 'Data sources widget',
    'Application': appName
  })

  return supportsUsage
    ? {
      label: (
        <ThemeProvider>
          <div style={{ padding: '12px', fontSize: '11px', maxWidth: '315px', color: baseColors.grey1 }}>
            <div>{`${appName} API did not sync ${usageType}.  You might be required to reconnect ${appName} or reconfigure the settings on the app admin console.`}</div>
            {helpCenterLink && <Link href={helpCenterLink} target='_blank'
              onClick={() => onClick(pageName, usageType)}>{`Read how to sync ${appName} ${usageType}`}</Link>}
          </div>
        </ThemeProvider>
      )
    }
    : {
      label: isExpenseSoftware
        ? `Torii does not sync ${appName} usage data`
        : isToriibot
          ? `${appName} Torii bot integration does not sync usage data`
          : `${appName} API does not provide usage data`
    }
}
