import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { AppIcon } from '@toriihq/design-system'
import texts from '../../../shared/style/texts'
import { colors } from '@shared/style/colors'
import ComingSoonBadge from './comingSoonBadge'
import SelectionBlockItem from './selectionBlockItem'
import SelectionRowItem from './selectionRowItem'
import EnableFor from '../../enableFor'

const CSS = {
  header: css(texts.headers.small, {
    textTransform: 'uppercase'
  }),
  group: css({
    borderRadius: '4px',
    border: `1px solid ${colors.lightBlue2}`,
    marginTop: '18px',
    padding: '10px 20px',
    paddingRight: '1px',
    width: '100%'
  }),
  noBorder: css({
    border: 'none'
  }),
  groupHeader: css({
    display: 'flex',
    alignItems: 'center',
    padding: '15px 10px'
  }),
  groupName: css(texts.subheading, {
    color: colors.darkText,
    marginLeft: '15px'
  }),
  optionsContainer: css({
    margin: '-10px',
    '>div:first-child': {
      borderTop: 'none',
      marginTop: '1px'
    },
    '>div:last-child': {
      marginBottom: '1px'
    }
  })
}

class SelectionBox extends React.Component {
  renderGroup = ({ imageUrl, name, isDisabled, options }, selectedValue, onChange, isToriiAdmin, hideHeader, showComingSoonBadge, noBorder, disableUpdateValue, overrideStyle) => {
    const { display, allowedScopes = [] } = this.props

    const Component = (display === 'row') ? SelectionRowItem : SelectionBlockItem
    const groupHeader = <div {...CSS.groupHeader}>
      {imageUrl && <AppIcon appImageUrl={imageUrl} appName={name} />}
      <div {...CSS.groupName}>{name}</div>
      {isDisabled && showComingSoonBadge && <ComingSoonBadge />}
    </div>

    return (
      <div key={name.replace(' ', '_')} {...css(CSS.group, noBorder && CSS.noBorder, overrideStyle)}>

        <div {...CSS.optionsContainer}>
          {!hideHeader && groupHeader}
          {options.map(option => {
            const { value, label, description, src, badge, badgeColor, isDisabled, isToriiAdminsOnly } = option
            const comingSoon = (isDisabled || (isToriiAdminsOnly && !isToriiAdmin)) && showComingSoonBadge
            const isSelected = value === selectedValue
            const disableIfOtherValueWasSelected = Boolean(disableUpdateValue && selectedValue)
            return (
              <EnableFor scopes={allowedScopes} key={value}>
                <Component
                  label={label}
                  description={description}
                  src={src}
                  badge={badge}
                  badgeColor={badgeColor}
                  onChange={() => onChange && onChange(option)}
                  isSelected={isSelected}
                  comingSoon={comingSoon}
                  disabled={isDisabled || disableIfOtherValueWasSelected}
                />
              </EnableFor>
            )
          })}
        </div>
      </div>
    )
  }

  render () {
    const { header, selectedValue, groups, onChange, isToriiAdmin, hideHeader, showComingSoonBadge, noBorder, disableUpdateValue, overrideStyle } = this.props
    return <>
      {header ? <div {...CSS.header}>{header}</div> : null}
      {groups.map(group => this.renderGroup(group, selectedValue, onChange, isToriiAdmin, hideHeader, showComingSoonBadge, noBorder, disableUpdateValue, overrideStyle))}
    </>
  }
}

export const groupOptions = PropTypes.shape({
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  isDisabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]),
    label: PropTypes.string,
    description: PropTypes.string,
    isDisabled: PropTypes.bool,
    idApp: PropTypes.number,
    type: PropTypes.string
  }))
})

SelectionBox.propTypes = {
  header: PropTypes.string,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func,
  groups: PropTypes.arrayOf(groupOptions),
  display: PropTypes.oneOf(['row', 'block']),
  disableUpdateValue: PropTypes.bool,
  overrideStyle: PropTypes.object
}

SelectionBox.defaultProps = {
  display: 'row',
  disableUpdateValue: false
}

export default SelectionBox
