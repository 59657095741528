import React from 'react'
import { Redirect } from 'react-router-dom'
import { Grid, Row, Col } from '@toriihq/design-system'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import Page from '../../components/page'
import UserApps from '../../components/userApps'
import { getDisplayName } from '@lenses/users'
import { USER_LIFECYCLE_STATUS } from '@shared/types'
import { fontSize } from '@shared/style/sizes'
import ActiveUser from './activeUser'
import OffboardedUser from './offboardedUser'
import OffboardingUser from './offboardingUserV2'
import OffboardingUserFooter from './offboardingUserFooter'
import Placeholder from '../../components/placeholder'
import TabsPage from '@pages/tabs'
import Details from '@components/details'

const CSS = {
  main: css({
    margin: '25px 0',
    paddingTop: '5px',
    position: 'relative'
  }),
  description: css({
    fontSize: fontSize.small,
    borderTop: `1px solid ${colors.border}`,
    paddingTop: '20px',
    lineHeight: '20px'
  }),
  confirmationText: css(texts.body, {
    margin: '6px 0 10px 0'
  }),
  confirmationExplanation: css(texts.body, {
    paddingLeft: '20px',
    lineHeight: '20px'
  }),
  footer: css({
    position: 'sticky',
    bottom: 0,
    marginTop: '15px',
    padding: '10px 0 25px 0',
    backgroundColor: colors.white,
    borderTop: `1px solid ${colors.border}`
  })
}

const USER_FIELDS = ['firstName', 'lastName', 'fullName', 'email', 'additionalEmails', 'allIdUsers', 'allUsers', 'idParentUser', 'isExternal', 'idRole', 'idOrg', 'status', 'lifecycleStatus', 'isDeletedInIdentitySources', 'identitySourcesDeletionTime', 'activeAppsCount', 'totalAppsCount', 'removedAppsCount', 'totalAppAccountsCount', 'removedAppAccountsCount', 'idApps', 'photoUrl', 'offboardingStartTime', 'offboardingEndTime', 'ignoredAndRemovedAppsCount', 'ignoredAndRemovedAppAccountsCount', 'offboardingProgress', 'offboardingStartedByIdUser', 'offboardingStartedByIdWorkflowAction', 'offboardingStartedByUser', 'isAdmin', 'userType', 'annualCostConverted']

class UserPage extends React.Component {
  componentDidMount () {
    this.fetchData()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.idUser !== this.props.idUser || prevProps.idOrg !== this.props.idOrg) {
      this.fetchData()
    }
  }

  isChildUser = () => Boolean(this.props.user.idParentUser)

  fetchData () {
    const { idOrg, idUser, deprecatedGetApps, getUser, getUserOffboardingAuditLogs, getAppsOfUserFromServer, getOffboardingStatusOfUser, getUserDetailsFields, getUserDetails } = this.props
    if (this.isChildUser()) {
      return
    }

    getUser({ idOrg, idUser, fields: USER_FIELDS })
    getUserDetails({ idOrg, idUser })
    getUserDetailsFields({ idOrg })
    deprecatedGetApps({ idOrg })
    getAppsOfUserFromServer({ idOrg, idUser })
    getUserOffboardingAuditLogs({ idOrg, idUser })
    getOffboardingStatusOfUser({ idOrg, idUser })
  }

  renderHeader = ({ isActiveUser, isOffboardingUser, isOffboardedUser }) => {
    const { user, idUser, idOrg, apps, startedByUser } = this.props
    if (isActiveUser) {
      return <ActiveUser user={user} idUser={idUser} idOrg={idOrg} />
    }

    if (isOffboardedUser) {
      return <OffboardedUser user={user} idUser={idUser} idOrg={idOrg} startedByUser={startedByUser} />
    }

    if (isOffboardingUser) {
      return <OffboardingUser user={user} idUser={idUser} idOrg={idOrg} apps={apps} startedByUser={startedByUser} />
    }

    return <div />
  }

  renderApps = ({ isActiveUser, isOffboardingUser, isOffboardedUser }) => {
    const { user, apps, idOrg, idUser, loadingApps } = this.props

    return (
      <UserApps
        isActive={isActiveUser}
        isOffboarding={isOffboardingUser}
        isOffboarded={isOffboardedUser}
        user={user}
        apps={apps}
        idOrg={idOrg}
        idUser={idUser}
        loading={loadingApps}
      />
    )
  }

  onHistory = (detail) => {
    const { idUser, toggleUserDetailHistoryPopup } = this.props
    toggleUserDetailHistoryPopup({ isOpen: true, idUser, detail })
  }

  renderDetails = () => {
    const { loadingDetails, details, groups, usersById } = this.props

    return <Details
      header='User details'
      details={details}
      groups={groups}
      editable={false}
      removable={false}
      loading={loadingDetails}
      usersById={usersById}
      onHistory={this.onHistory}
    />
  }

  renderFooter = ({ isOffboardingUser }) => {
    const { user, idUser, idOrg, apps } = this.props

    if (isOffboardingUser) {
      return <OffboardingUserFooter user={user} idUser={idUser} idOrg={idOrg} apps={apps} overrideStyle={CSS.footer} onUserLifecycleStatusUpdated={() => this.fetchData()} />
    }
  }

  render () {
    const { user, loadingBasicInfo, apps, idOrg, idUser, details, loadingApps, loadingDetails } = this.props
    if (this.isChildUser()) {
      return <Redirect to={`${this.props.user.idParentUser}`} />
    }

    const fullName = getDisplayName(user)

    if (!user || !user.lifecycleStatus) {
      return null
    }

    const isActiveUser = user.lifecycleStatus === USER_LIFECYCLE_STATUS.ACTIVE
    const isOffboardingUser = user.lifecycleStatus === USER_LIFECYCLE_STATUS.OFFBOARDING
    const isOffboardedUser = user.lifecycleStatus === USER_LIFECYCLE_STATUS.OFFBOARDED

    const tabsName = ['apps', 'details']
    const tabsHeader = [
      { header: 'Apps', counter: apps.length, isLoading: loadingApps },
      { header: 'Details', counter: details.length, isLoading: loadingDetails }
    ]
    const tabsContent = [
      this.renderApps({ isActiveUser, isOffboardingUser, isOffboardedUser }),
      this.renderDetails()
    ]

    return (
      <Page title={fullName}>
        <div {...CSS.main}>
          <Grid>
            <Placeholder loading={loadingBasicInfo} type='text' rows={2} style={{ height: '50px', width: '50px' }}>
              {this.renderHeader({ isActiveUser, isOffboardingUser, isOffboardedUser })}
            </Placeholder>
            <Row>
              <Col>
                <TabsPage
                  pageName='User'
                  path={`/team/${idOrg}/user/${idUser}`}
                  tabsName={tabsName}
                  tabsHeader={tabsHeader}
                  tabsContent={tabsContent}
                />
              </Col>
            </Row>
          </Grid>
          {this.renderFooter({ isOffboardingUser })}
        </div>
      </Page>
    )
  }
}

export default UserPage
