import React from 'react'
import PropTypes from 'prop-types'
import { DATE_FORMAT, formFieldTypes } from '../../../constants'
import moment from 'moment'
import { getDisplayName } from '../../../lenses/users'
import EditableField from '@components/editableField'
import Select from '../../select'
import MultipleCheckboxSelect from '../../multipleCheckboxSelect'
import noop from 'lodash/noop'
import isUndefined from 'lodash/isUndefined'
import UserDetails from '../../userDetails'
import isNil from 'lodash/isNil'
import SelectUsers from '@components/selectUsers'
import { formatDateAsUTC } from '@shared/utils'
import { round } from 'lodash'
import Currency from '@components/currencyOld'
import { DatePicker, TextArea } from '@toriihq/design-system'
import SelectState from '@components/selectState'
import Input from '@components/form/input'

const simpleEmailRegex = /^\S+@\S+\.\S+/
const validateEmail = (value) => simpleEmailRegex.test(value)

class FieldByType extends React.Component {
  state = {
    usersCacheById: {}
  }

  renderOption = (item) => {
    return <UserDetails {...item} overrideStyle={{ width: '100%' }} />
  }

  render () {
    const { field, readonly, disabled, identifier, label, value, onSave, onRemove, onHistory, showHistoryButton, currencySymbol, loading, usersById: usersByIdFromStore, ignoreEditMode, removable } = this.props
    const usersById = {
      ...this.state.usersCacheById,
      ...usersByIdFromStore
    }
    const basicProps = {
      readonly,
      disabled,
      key: identifier,
      label,
      value: isNil(value) ? '' : value,
      onSave,
      onRemove,
      onHistory,
      showHistoryButton: showHistoryButton && !isUndefined(value),
      ignoreEditMode,
      removable
    }
    switch (field.type) {
      case formFieldTypes.singleLine: {
        return (
          <EditableField
            {...basicProps}
          >
            {({ isEditMode, onChange, value, isValid }) => <Input type='text' hasError={!isValid} autoFocus readOnly={disabled} disabled={!isEditMode} onChange={onChange} value={value} border={false} />}
          </EditableField>
        )
      }
      case formFieldTypes.email: {
        return (
          <EditableField
            {...basicProps}
            validate={validateEmail}
          >
            {({ isEditMode, onChange, value, isValid }) => (
              <Input type='email' hasError={!isValid} autoFocus readOnly={disabled} disabled={!isEditMode} onChange={onChange} value={value} border={false} />
            )}
          </EditableField>
        )
      }
      case formFieldTypes.number: {
        return (
          <EditableField
            {...basicProps}
            parse={value => value && !isNaN(parseInt(value, 10)) ? parseInt(value, 10) : value}
            validate={value => value && !isNaN(parseInt(value, 10)) && parseInt(value, 10) >= 0}
          >
            {({ isEditMode, onChange, value, isValid }) => <Input hasError={!isValid} autoFocus readOnly={disabled} disabled={!isEditMode} onChange={onChange} value={value} border={false} />}
          </EditableField>
        )
      }
      case formFieldTypes.currency: {
        return (
          <EditableField
            {...basicProps}
            value={value ? (value / 100).toString() : null}
            validate={value => value && !isNaN(parseFloat(value, 10))}
            displayFormat={value => <Currency value={value} currencySymbol={currencySymbol} format={'$0,0.[00]'} />}
            onSave={value => {
              value = value.replace(/,/g, '')
              onSave(round(parseFloat(value, 10) * 100))
            }}
          >
            {({ isEditMode, onChange, value, isValid }) => <Input hasError={!isValid} prefix={currencySymbol} autoFocus readOnly={disabled} disabled={!isEditMode} onChange={onChange} value={value} border={false} />}
          </EditableField>
        )
      }
      case formFieldTypes.cardNumber: {
        return (
          <EditableField
            {...basicProps}
            prefix='XXXX XXXX XXXX '
            value={value ? value.toString() : value}
            parse={value => value.substr(0, 4)}
            validate={value => value && value.length === 4}
            overrideStyle={{ ' .value': { direction: 'rtl' } }}
          >
            {({ isEditMode, onChange, value }) => <Input autoFocus readOnly={disabled} disabled={!isEditMode} onChange={onChange} value={value} border={false} />}
          </EditableField>
        )
      }
      case formFieldTypes.datePicker: {
        return (
          <EditableField
            {...basicProps}
            value={value ? new Date(formatDateAsUTC(value)) : ''}
            displayFormat={value => value ? moment(value).format(DATE_FORMAT) : ''}
            format={value => value}
          >
            {({ isEditMode, onChange, value, onSave }) =>
              isEditMode
                ? (
                  <DatePicker
                    onDayPickerHide={onSave}
                    value={value ? moment(value).format('MM/DD/YYYY') : value}
                    inputProps={{ autoFocus: true }}
                    onDayChange={(date) => {
                      onChange(date && moment.utc(date, 'YYYY-MM-DD').format('YYYY-MM-DD'))
                    }}
                  />
                )
                : null
            }
          </EditableField>
        )
      }
      case formFieldTypes.multiValueString:
      case formFieldTypes.multiLine: {
        return (
          <EditableField
            {...basicProps}
          >
            {({ isEditMode, onChange, value, isValid }) => <TextArea hasError={!isValid} autoFocus readOnly={disabled} disabled={!isEditMode} onChange={onChange} value={value} border={false} />}
          </EditableField>
        )
      }
      case formFieldTypes.dropdown: {
        return (
          <EditableField
            {...basicProps}
            format={value => (field.options.find(item => item.value === value) || {}).label}
            removable={field.systemKey !== 'state'}
          >
            {({ isEditMode, onChange, onSave, value: label, isValid }) => {
              const selectedOption = (field.options.find(item => item.label === label) || {})
              return isEditMode
                ? (field.systemKey === 'state') ? (
                  <SelectState
                    options={field.options.filter(option => !option.isDeleted)}
                    selectedValue={selectedOption}
                    allowHideApp={false}
                    onChange={selection => {
                      onChange(selection.value)
                      setTimeout(onSave, 0)
                    }}
                    clearable={false}
                    autoFocus
                    openOnFocus
                  />
                ) : (
                  <Select
                    options={field.options.filter(option => !option.isDeleted)}
                    clearable={false}
                    searchable={false}
                    hasError={!isValid}
                    name={field.systemKey}
                    onChange={selection => {
                      onChange(selection.value)
                      setTimeout(onSave, 0)
                    }}
                    onBlur={onSave}
                    autoFocus
                    openOnFocus
                    value={selectedOption}
                  />
                )
                : null
            }}
          </EditableField>

        )
      }
      case formFieldTypes.dropdownMulti: {
        const format = (values) => (values || [])
          .map(value => (field.options.find(item => item.value === value) || {}).label)
          .join(', ')

        return (
          <EditableField
            {...basicProps}
            value={field.values || basicProps.value}
            format={format}
          >
            {({ isEditMode, onChange, onSave, value: values }) =>
              isEditMode
                ? (
                  <MultipleCheckboxSelect
                    ref={select => select && select.open && select.open()}
                    options={field.options.filter(option => !option.isDeleted)}
                    selectedValues={field.values}
                    isLoading={loading}
                    onChange={onChange}
                    onBlur={onSave}
                    autoFocus
                    showValues
                  />
                )
                : null}
          </EditableField>
        )
      }
      case formFieldTypes.user:
      case formFieldTypes.usersDropdown: {
        return (
          <EditableField
            {...basicProps}
            format={value => usersById[value] && getDisplayName(usersById[value])}
          >
            {({ isEditMode, onChange, onSave }) => (
              isEditMode
                ? (
                  <SelectUsers
                    placeholder='Search users'
                    autoFocus
                    openOnFocus
                    onChange={user => {
                      this.setState(prevState => ({ usersCacheById: { ...prevState.usersCacheById, [user.id]: user } }))
                      onChange(user && user.id)
                      setTimeout(onSave, 0)
                    }}
                    onBlur={onSave}
                    value={usersById[value]}
                  />
                )
                : null
            )}
          </EditableField>
        )
      }
      case formFieldTypes.usersDropdownMulti: {
        return (
          <EditableField
            {...basicProps}
            value={field.values || basicProps.value}
            format={values => values && [].concat(values).map(v => usersById[v] && getDisplayName(usersById[v])).join(', ')}
          >
            {({ isEditMode, onChange, onSave }) =>
              isEditMode
                ? (
                  <SelectUsers
                    placeholder='Search users'
                    autoFocus
                    openOnFocus
                    multi
                    onChange={users => {
                      const updatedUsers = {}
                      users.forEach(user => {
                        updatedUsers[user.id] = user
                      })
                      this.setState(prevState => ({ usersCacheById: { ...prevState.usersCacheById, ...updatedUsers } }))
                      users && onChange(users.map(u => u && u.id))
                    }}
                    value={(field.values ?? (value || [])).map(id => usersById[id])}
                    onBlur={onSave}
                  />
                )
                : null
            }
          </EditableField>
        )
      }
      case formFieldTypes.contractsDropdownMulti: {
        return (
          <EditableField
            {...basicProps}
            format={value => {
              const values = Object.values(value)
              return field.options.filter(option => values.includes(option.value)).map(option => option.label).join(', ')
            }}
          >
            {({ isEditMode, onChange, onSave, isValid }) => {
              return isEditMode
                ? (
                  <Select
                    hasError={!isValid}
                    multi
                    value={field.options.filter(option => value.includes(option.value))}
                    options={field.options}
                    clearable={false}
                    searchable={false}
                    searchPromptText={field.searchPromptText}
                    onChange={selections => {
                      onChange(selections.map(contract => contract.value))
                      setTimeout(onSave, 0)
                    }}
                    onBlur={onSave}
                    autoFocus
                    openOnFocus
                  />
                )
                : null
            }
            }
          </EditableField>
        )
      }
      default: {
        return null
      }
    }
  }
}

FieldByType.propTypes = {
  currencySymbol: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  userMap: PropTypes.object.isRequired,
  readonly: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  identifier: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array]),
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onHistory: PropTypes.func.isRequired,
  showHistoryButton: PropTypes.bool.isRequired,
  field: PropTypes.object.isRequired
}

FieldByType.defaultProps = {
  onHistory: noop,
  onSave: noop,
  onRemove: noop,
  showHistoryButton: false,
  readonly: false,
  disabled: false,
  users: [],
  userMap: {},
  field: {}
}

export default FieldByType
