import { WORKFLOW_OUTPUT_FIELD_TYPES } from '@root/constants'
import { BaseTag } from '@shared/workflows/types'
import { capitalize, partition } from 'lodash'

const FIELD_TYPE_TO_LABEL = {
  [WORKFLOW_OUTPUT_FIELD_TYPES.APP]: 'Application',
  [WORKFLOW_OUTPUT_FIELD_TYPES.USER]: 'User'
}

export const mapOutputFieldToBranchingEntity = (outputField: any): { label: string, value: string } => {
  const { type } = outputField

  return {
    label: FIELD_TYPE_TO_LABEL[type] || capitalize(type),
    value: type
  }
}

export enum WORKFLOW_EDIT_HISTORY_TYPE {
  CREATE = 'create',
  UPDATE = 'update',
  ENABLE = 'enable',
  DISABLE = 'disable'
}

export const ACTION_DESCRIPTION_BY_EDIT_HISTORY_TYPE: Record<string, string> = {
  [WORKFLOW_EDIT_HISTORY_TYPE.CREATE]: 'created',
  [WORKFLOW_EDIT_HISTORY_TYPE.UPDATE]: 'updated',
  [WORKFLOW_EDIT_HISTORY_TYPE.ENABLE]: 'activated',
  [WORKFLOW_EDIT_HISTORY_TYPE.DISABLE]: 'deactivated'
}

export const getSortedTags = (tags: BaseTag[]): BaseTag[] => {
  const [orgCustomTags, predefinedTags] = partition(tags, tag => tag.idOrg)
  orgCustomTags.sort((a, b) => a.id - b.id)
  predefinedTags.sort((a, b) => a.label.localeCompare(b.label))

  return [...predefinedTags, ...orgCustomTags]
}
