import ToriiPopup from '@components/popups/ToriiPopupV2'
import { IMPORT_TYPE } from '@selectors/importUsers/types'
import { RendererProps } from '@root/components/popups/wizardPopup/types'
import { SharedProps, SharedState, WIZARD_STEPS } from '../types'
import { useDispatch } from 'react-redux'
import { UsePolling } from '../../utils/usePolling'
import { SYNC_STATUS } from '@root/constants'
import { getServicesSyncData, getSyncDataForAppAccount, updateImportAppUsersStatus } from '@actions/'
import { IMPORT_APP_USERS_STATUS } from '@reducers/appUsers/types'
import useEffectOnce from '@root/shared/hooks/useEffectOnce'
import { Content } from './style'
import { Spinner, SpinnerColor, Stack, toast, ToastType } from '@toriihq/design-system'
import { CenteredSubtitle, Text } from '../../styles'

const LoadingAfterDeletion = ({ sharedProps, navigateToStep }: RendererProps<SharedState, SharedProps, WIZARD_STEPS>): JSX.Element => {
  const dispatch = useDispatch()

  const { idOrg, idAppAccount, lastSyncTime, importMode, appAccountName } = sharedProps

  const onPollFailure = () => {
    clearPolling()
  }

  const { startPolling, clearPolling } = UsePolling({
    onPollFailure
  })

  const pollLogic = async ({ idOrg, idAppAccount, lastSyncTime }) => {
    const response = await dispatch(getSyncDataForAppAccount({ idOrg, idAppAccount }))
    const { syncStatus } = response
    const { syncStatus: appAccountSyncStatus, lastSyncTime: newSyncTime } = syncStatus[0]
    const isFinishLaterSync = lastSyncTime ? lastSyncTime < newSyncTime : true
    if (appAccountSyncStatus === SYNC_STATUS.FINISHED_SUCCESSFULLY && isFinishLaterSync) {
      clearPolling()
      refreshDataAndClosePopup()
    } else if (appAccountSyncStatus === SYNC_STATUS.FINISHED_FAILED) {
      navigateToStep(WIZARD_STEPS.FAILURE)
    }
  }

  const refreshDataAndClosePopup = () => {
    const SECONDS_TO_WAIT_AFTER_FINISH = 3

    const { idOrg } = sharedProps

    dispatch(updateImportAppUsersStatus(IMPORT_APP_USERS_STATUS.SYNC_IN_PROGRESS))

    setTimeout(() => {
      toast({ type: ToastType.SUCCESS, message: importMode === IMPORT_TYPE.USERS ? 'Users deleted successfully' : 'User license changes reset successfully' })
      dispatch(updateImportAppUsersStatus(IMPORT_APP_USERS_STATUS.SYNC_PRESUMABLY_ENDED))
      dispatch(getServicesSyncData({ idOrg }))
      sharedProps.closePopup()
    }, SECONDS_TO_WAIT_AFTER_FINISH * 1000)
  }

  useEffectOnce(() => {
    const manuallyFinishDeletion = () => {
      const DELETE_USERS_DURATION_SECONDS = 7
      setTimeout(() => {
        refreshDataAndClosePopup()
      }, DELETE_USERS_DURATION_SECONDS * 1000)
    }

    const fetchData = async () => {
      if (importMode === IMPORT_TYPE.LICENSES) {
        await startPolling(() => pollLogic({ idOrg, lastSyncTime, idAppAccount }))
      } else {
        manuallyFinishDeletion()
      }
    }

    fetchData()
  })

  const importUsersLoadingMessage = <Content>
    <CenteredSubtitle>We're deleting the imported users</CenteredSubtitle>
    <Text>It may take a few seconds to complete</Text>
  </Content>

  const importLicensesLoadingMessage = <Content>
    <CenteredSubtitle>We're deleting the imported license data<br />and resyncing it from the integration</CenteredSubtitle>
    <Text>It may take a few minutes to complete</Text>
  </Content>

  const popupContent = importMode === IMPORT_TYPE.USERS ? importUsersLoadingMessage : importLicensesLoadingMessage

  return (
    <>
      <ToriiPopup.Header header='Resetting manual changes' subHeader={appAccountName} />
      <ToriiPopup.Content>
        <Content>
          <Stack gap='space-200' alignItems='center' justifyContent='center'>
            <Spinner color={SpinnerColor.active} />
            {popupContent}
          </Stack>
        </Content>
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        showMainButton={false}
        showCancelButton={false}
      />
    </>
  )
}

export default LoadingAfterDeletion
