import View from './view'
import { connect } from 'react-redux'
import { getLicensesTypes as getLicensesTypesSelector, isLoadedLicensesTypes, isLoadingLicensesTypes } from '@selectors/licenses'
import { getLicensesTypes } from '@actions/'
import { getCurrentOrg } from '@selectors/org'
import { EMPTY_ARRAY } from '../../constants'

const mapStateToProps = (state, ownProps) => {
  const { id: idOrg } = getCurrentOrg(state)
  const { idApp, idAppAccount, disableInactiveClick, fullHeight } = ownProps

  const licensesInfoByIdApp = getLicensesTypesSelector(state)
  const licenseTypes = licensesInfoByIdApp[idApp] || EMPTY_ARRAY
  const loading = isLoadingLicensesTypes(state)
  const isLoaded = isLoadedLicensesTypes(state)
  const licenses = idAppAccount ? licenseTypes.filter(license => license.idAppAccount === idAppAccount) : licenseTypes

  return {
    loading: !isLoaded || (loading && licenseTypes.length === 0),
    licenses,
    idOrg,
    idAppAccount,
    disableInactiveClick,
    fullHeight
  }
}

const mapDispatchToProps = {
  getLicensesTypes
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
