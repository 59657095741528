import React from 'react'
import Linkify from 'linkify-react'
import { Link } from '@toriihq/design-system'

const ToriiLinkify = ({ value }: { value: string }): JSX.Element => (
  <Linkify options={{
    render: {
      url: ({ attributes, content }) => {
        return <Link {...attributes} target={'_blank'} onClick={e => { e.stopPropagation() }}>{content}</Link>
      }
    }
  }}>
    {value}
  </Linkify>
)

export default ToriiLinkify
