import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getAppAccountStatuses, getCurrentApp } from '@selectors/apps'
import { getIsSmallScreen } from '@selectors/ui'
import isEmpty from 'lodash/isEmpty'
import { getCurrencySymbol, getSupportedFeatures, isLoadedSupportedFeatures } from '@selectors/org'
import { EMPTY_OBJECT, INTEGRATION_CATEGORY, SCOPES, SYNC_TYPE } from '@root/constants'
import { getConstantServices, getSyncStatus, getServicesWithConnectStatusMultipleAccounts, getConstantServicesByIdApp } from '@selectors/services'
import { getServiceInfo } from '@shared/services'
import {
  toggleConnectService,
  toggleConnectSource,
  toggleConnectSCIMService,
  toggleLicenseAuditNotSupportedPopup
} from '@actions/'
import get from 'lodash/get'
import AccessControl from '@lenses/accessControl'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'
import { getLast12MonthsExpensesAggsPerApp } from '@selectors/transactions'
import { setLicenseAuditData } from '@root/store/actions'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const idApp = parseInt(ownProps.match.params.idApp, 10)
  const currencySymbol = getCurrencySymbol(state)

  const { app = EMPTY_OBJECT, loading: isLoadingApp } = getCurrentApp(state) || EMPTY_OBJECT

  const { name, appearsInCatalog } = app
  const isAppInUse = !isEmpty(app)

  const config = getConstantServices(state)
  const isConfigLoaded = config.length > 0
  const isServicesLoaded = !!getSyncStatus(state)
  const supportedFeatures = getSupportedFeatures(state)
  const services = (isServicesLoaded && isConfigLoaded) ? getServicesWithConnectStatusMultipleAccounts(state) : EMPTY_OBJECT
  const service = getServiceInfo({ services, idApp, appName: name, supportedFeatures })

  const { isConnected } = service
  const hasIntegration = !isEmpty(service)

  const isNewAppLoading = get(app, ['id']) && get(app, ['id']) !== idApp
  const loading = (isEmpty(app) || isEmpty(services)) && (isLoadingApp || isNewAppLoading)
  const showAlertBox = (isAppInUse && !loading) && hasIntegration && !isConnected

  const isLoadedFeatures = isLoadedSupportedFeatures(state)

  const hasExpensesAccess = AccessControl.isAccessibleWithState({ scopes: [SCOPES.EXPENSE_READ, getScopeByIdOrgAndIdApp(SCOPES.EXPENSE_READ, idOrg, idApp)], state })
  const last12MonthsExpensesAggsPerApp = getLast12MonthsExpensesAggsPerApp(state)

  const { appAccounts: allAppAccounts = [] } = getAppAccountStatuses(state)
  const serviceConfig = getConstantServicesByIdApp(state)[idApp] || {}
  const isAppFinanceOrContractsCategory = [INTEGRATION_CATEGORY.EXPENSE_SOFTWARE, INTEGRATION_CATEGORY.CONTRACT_MANAGEMENT_SOFTWARE].includes(serviceConfig.integrationCategory)
  const allAppAccountsWithoutUnSyncedCustom = allAppAccounts.filter(({ source, lastSyncTime }) => source !== SYNC_TYPE.CUSTOM || lastSyncTime)
  const appAccounts = isAppFinanceOrContractsCategory ? allAppAccountsWithoutUnSyncedCustom.filter(({ source }) => source === SYNC_TYPE.MANUAL) : allAppAccountsWithoutUnSyncedCustom

  return {
    name,
    currencySymbol,
    idOrg,
    idApp,
    isSmallScreen: getIsSmallScreen(state),
    isInUse: isAppInUse,
    loading,
    appearsInCatalog,
    service,
    showAlertBox,
    hasExpensesAccess,
    last12MonthsExpensesAggs: last12MonthsExpensesAggsPerApp[idApp],
    isLoadedFeatures,
    appAccounts
  }
}

const mapDispatchToProps = {
  toggleLicenseAuditNotSupportedPopup,
  toggleConnectSource,
  toggleConnectService,
  toggleConnectSCIMService,
  setLicenseAuditData
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
