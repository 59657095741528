import {
  UPDATE_USER,
  UPDATE_USER_LIFECYCLE,
  UPDATE_USERS_LIFECYCLE,
  INVITE_USER,
  GET_CURRENT_USERS,
  GET_CURRENT_USERS_AMOUNT,
  GET_USER_TYPES_AMOUNT,
  GET_CURRENT_USERS_FIELD_VALUES,
  GET_PAST_USERS_AMOUNT,
  GET_PAST_USERS_FIELD_VALUES,
  GET_PAST_USERS,
  GET_EXTERNAL_USERS_FIELD_VALUES,
  GET_EXTERNAL_USERS_AMOUNT,
  GET_EXTERNAL_USERS,
  GET_TEAM_MEMBERS,
  REVOKE_MEMBER,
  GET_USER,
  GET_CATALOG_USERS,
  GET_CURRENT_USERS_WITH_CHILDREN_AMOUNT
} from '@root/constants'
import { USER_LIFECYCLE_STATUS } from '@shared/types'
import { mergeArraysByKey } from '@lenses/utils'
import keyBy from 'lodash/keyBy'
import merge from 'lodash/merge'

const initialState = {
  lazyLoadedUsers: {},
  loadingLazyLoadedUsers: false,
  currentUsers: {
    loading: false,
    loadingMore: false,
    total: 0,
    users: []
  },
  pastUsers: {
    loading: false,
    loadingMore: false,
    total: 0,
    users: []
  },
  externalUsers: {
    loading: false,
    loadingMore: false,
    total: 0,
    users: []
  },
  currentUsersTotalAmount: 0,
  pastUsersTotalAmount: 0,
  externalUsersTotalAmount: 0,
  userTypeAmount: {
    employee: 0
  },
  teamMembers: {
    isLoaded: false,
    loading: false,
    total: 0,
    users: []
  },
  catalogUsers: {
    loading: false,
    users: [],
    total: 0
  }
}

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${UPDATE_USERS_LIFECYCLE}_RESPONSE`: {
      const { users } = action.payload
      const usersById = keyBy(users, 'id')

      return {
        ...state,
        lazyLoadedUsers: merge(state.lazyLoadedUsers, usersById)
      }
    }
    case `${UPDATE_USER}_PENDING`: {
      const { idUser, idRole } = action.meta
      const prevUsers = state.teamMembers.users
      const nextUsers = [{ id: idUser, idRole }]

      const users = mergeArraysByKey(prevUsers, nextUsers, 'id')

      return {
        ...state,
        teamMembers: { ...state.teamMembers, users }
      }
    }
    case `${UPDATE_USER}_FAILED`: {
      const { idUser, prevIdRole } = action.meta
      const prevUsers = state.teamMembers.users
      const nextUsers = [{ id: idUser, idRole: prevIdRole }]

      const users = mergeArraysByKey(prevUsers, nextUsers, 'id')

      return {
        ...state,
        teamMembers: { ...state.teamMembers, users }
      }
    }
    case `${INVITE_USER}_RESPONSE`:
    case `${UPDATE_USER}_RESPONSE`: {
      const { user } = action.payload
      const prevUsers = state.teamMembers.users
      const nextUsers = [user]
      const users = mergeArraysByKey(prevUsers, nextUsers, 'id')

      return {
        ...state,
        teamMembers: { ...state.teamMembers, users }
      }
    }
    case `${UPDATE_USER_LIFECYCLE}_RESPONSE`: {
      const { user } = action.payload

      return {
        ...state,
        lazyLoadedUsers: {
          ...state.lazyLoadedUsers,
          [user.id]: user
        }
      }
    }
    case `${REVOKE_MEMBER}_FAILED`: {
      const { idUser, prevIdRole } = action.meta
      const prevUsers = state.teamMembers.users
      const nextUsers = [{ id: idUser, idRole: prevIdRole, isAdmin: true }]

      const users = mergeArraysByKey(prevUsers, nextUsers, 'id')

      return {
        ...state,
        teamMembers: { ...state.teamMembers, users }
      }
    }
    case `${REVOKE_MEMBER}_RESPONSE`: {
      const { user } = action.payload
      const prevUsers = state.teamMembers.users
      const nextUsers = [{ ...user, isAdmin: false }]
      const users = mergeArraysByKey(prevUsers, nextUsers, 'id')

      return {
        ...state,
        teamMembers: { ...state.teamMembers, users }
      }
    }
    case `${GET_USER}_PENDING`: {
      return {
        ...state,
        loadingLazyLoadedUsers: true
      }
    }
    case `${GET_USER}_FAILED`: {
      return {
        ...state,
        loadingLazyLoadedUsers: false
      }
    }
    case `${GET_USER}_RESPONSE`: {
      const { user } = action.payload

      return {
        ...state,
        lazyLoadedUsers: {
          ...state.lazyLoadedUsers,
          [user.id]: {
            details: [],
            ...state.lazyLoadedUsers[user.id],
            ...user,
            lifecycleStatus: user.lifecycleStatus ?? USER_LIFECYCLE_STATUS.ACTIVE
          }
        },
        loadingLazyLoadedUsers: false
      }
    }
    case `${GET_CURRENT_USERS}_PENDING`: {
      const { reset = true } = action.meta
      return {
        ...state,
        currentUsers: {
          ...state.currentUsers,
          loading: true,
          loadingMore: !reset
        }
      }
    }
    case `${GET_CURRENT_USERS}_FAILED`: {
      return {
        ...state,
        currentUsers: {
          ...state.currentUsers,
          loading: false,
          loadingMore: false
        }
      }
    }
    case `${GET_CURRENT_USERS}_RESPONSE`: {
      const { users, total } = action.payload
      const { reset = true } = action.meta

      return {
        ...state,
        currentUsers: {
          users: reset ? users : (state.currentUsers.users).concat(users),
          total,
          loading: false,
          loadingMore: false
        }
      }
    }
    case `${GET_CURRENT_USERS_FIELD_VALUES}_RESPONSE`: {
      return {
        ...state,
        currentUsersFieldsValues: {
          ...state.currentUsersFieldsValues,
          ...action.payload
        }
      }
    }
    case `${GET_CURRENT_USERS_AMOUNT}_PENDING`: {
      return {
        ...state,
        loadingCurrentUsersTotalAmount: true
      }
    }
    case `${GET_CURRENT_USERS_AMOUNT}_FAILED`: {
      return {
        ...state,
        loadingCurrentUsersTotalAmount: false
      }
    }
    case `${GET_CURRENT_USERS_AMOUNT}_RESPONSE`: {
      const { total } = action.payload

      return {
        ...state,
        currentUsersTotalAmount: total,
        loadingCurrentUsersTotalAmount: false
      }
    }
    case `${GET_CURRENT_USERS_WITH_CHILDREN_AMOUNT}_PENDING`: {
      return {
        ...state,
        loadingCurrentUsersWithChildrenTotalAmount: true
      }
    }
    case `${GET_CURRENT_USERS_WITH_CHILDREN_AMOUNT}_FAILED`: {
      return {
        ...state,
        loadingCurrentUsersWithChildrenTotalAmount: false
      }
    }
    case `${GET_CURRENT_USERS_WITH_CHILDREN_AMOUNT}_RESPONSE`: {
      const { total } = action.payload

      return {
        ...state,
        currentUsersWithChildrenTotalAmount: total,
        loadingCurrentUsersWithChildrenTotalAmount: false
      }
    }

    case `${GET_USER_TYPES_AMOUNT}_PENDING`: {
      return {
        ...state,
        loadingUserTypeAmount: true
      }
    }
    case `${GET_USER_TYPES_AMOUNT}_FAILED`: {
      return {
        ...state,
        loadingUserTypeAmount: false
      }
    }
    case `${GET_USER_TYPES_AMOUNT}_RESPONSE`: {
      const { total } = action.payload
      const { userType } = action.meta

      const userTypeAmount = { ...state.userTypeAmount }
      userTypeAmount[userType] = total

      return {
        ...state,
        userTypeAmount,
        loadingUserTypeAmount: false
      }
    }

    case `${GET_PAST_USERS}_PENDING`: {
      const { reset = true } = action.meta
      return {
        ...state,
        pastUsers: {
          ...state.pastUsers,
          loading: true,
          loadingMore: !reset
        }
      }
    }
    case `${GET_PAST_USERS}_FAILED`: {
      return {
        ...state,
        pastUsers: {
          ...state.pastUsers,
          loading: false,
          loadingMore: false
        }
      }
    }
    case `${GET_PAST_USERS}_RESPONSE`: {
      const { users, total } = action.payload
      const { reset = true } = action.meta

      return {
        ...state,
        pastUsers: {
          users: reset ? users : (state.pastUsers.users).concat(users),
          total,
          loading: false,
          loadingMore: false
        }
      }
    }
    case `${GET_PAST_USERS_FIELD_VALUES}_RESPONSE`: {
      return {
        ...state,
        pastUsersFieldsValues: {
          ...state.pastUsersFieldsValues,
          ...action.payload
        }
      }
    }
    case `${GET_PAST_USERS_AMOUNT}_PENDING`: {
      return {
        ...state,
        loadingPastUsersTotalAmount: true
      }
    }
    case `${GET_PAST_USERS_AMOUNT}_FAILED`: {
      return {
        ...state,
        loadingPastUsersTotalAmount: false
      }
    }
    case `${GET_PAST_USERS_AMOUNT}_RESPONSE`: {
      const { total } = action.payload

      return {
        ...state,
        pastUsersTotalAmount: total,
        loadingPastUsersTotalAmount: false
      }
    }
    case `${GET_EXTERNAL_USERS}_PENDING`: {
      const { reset = true } = action.meta
      return {
        ...state,
        externalUsers: {
          ...state.externalUsers,
          loading: true,
          loadingMore: !reset
        }
      }
    }
    case `${GET_EXTERNAL_USERS}_FAILED`: {
      return {
        ...state,
        externalUsers: {
          ...state.externalUsers,
          loading: false,
          loadingMore: false
        }
      }
    }
    case `${GET_EXTERNAL_USERS}_RESPONSE`: {
      const { users, total } = action.payload
      const { reset = true } = action.meta

      return {
        ...state,
        externalUsers: {
          users: reset ? users : (state.externalUsers.users).concat(users),
          total,
          loading: false,
          loadingMore: false
        }
      }
    }
    case `${GET_EXTERNAL_USERS_FIELD_VALUES}_RESPONSE`: {
      return {
        ...state,
        externalUsersFieldsValues: {
          ...state.externalUsersFieldsValues,
          ...action.payload
        }
      }
    }
    case `${GET_EXTERNAL_USERS_AMOUNT}_PENDING`: {
      return {
        ...state,
        loadingExternalUsersTotalAmount: true
      }
    }
    case `${GET_EXTERNAL_USERS_AMOUNT}_FAILED`: {
      return {
        ...state,
        loadingExternalUsersTotalAmount: false
      }
    }
    case `${GET_EXTERNAL_USERS_AMOUNT}_RESPONSE`: {
      const { total } = action.payload

      return {
        ...state,
        externalUsersTotalAmount: total,
        loadingExternalUsersTotalAmount: false
      }
    }
    case `${GET_TEAM_MEMBERS}_PENDING`: {
      return {
        ...state,
        teamMembers: {
          ...state.teamMembers,
          loading: true
        }
      }
    }
    case `${GET_TEAM_MEMBERS}_FAILED`: {
      return {
        ...state,
        teamMembers: {
          ...state.teamMembers,
          loading: false
        }
      }
    }
    case `${GET_TEAM_MEMBERS}_RESPONSE`: {
      const { users, total } = action.payload
      const { reset = true } = action.meta

      return {
        ...state,
        teamMembers: {
          users: reset ? users : (state.teamMembers.users).concat(users),
          total,
          loading: false,
          isLoaded: true
        }
      }
    }

    case `${GET_CATALOG_USERS}_PENDING`: {
      return {
        ...state,
        catalogUsers: {
          ...state.catalogUsers,
          loading: true
        }
      }
    }

    case `${GET_CATALOG_USERS}_RESPONSE`: {
      const { users, total } = action.payload
      const { reset = true } = action.meta

      return {
        ...state,
        catalogUsers: {
          users: reset ? users : (state.catalogUsers.users).concat(users),
          total,
          loading: false
        }
      }
    }

    default: {
      return state
    }
  }
}

export default usersReducer
