import React, { ReactElement } from 'react'
import { WORKFLOW_UI_NODE_TYPE } from '@pages/workflow_v2/workflowEditor/workflowGraph/types'
import { WorkflowNodeWrapper } from '../workflowNodeWrapper'
import { useTriggerConfiguration } from '@pages/workflow_v2/hooks/useTriggerConfiguration'
import { WorkflowNodeContent } from '../workflowNodeContent'
import { BadgeWrapper, Container } from './styles'
import TriggerIconUrl from './triggerIcon.svg'
import { getNodeStatus } from '../utils/getNodeStatus'
import { useWorkflowNavigation } from '@pages/workflow_v2/hooks/useWorkflowNavigation'
import { useWorkflowEditorContext } from '@pages/workflow_v2/workflowEditor/context'
import { WORKFLOW_STEP_TYPE } from '@root/shared/types'
import { useSelectedWorkflow } from '@pages/workflow_v2/hooks/useSelectedWorkflow'
import { ID_TRIGGER_NODE } from '@shared/workflows/actions/consts'
import { TextBadge, TextBadgeColors } from '@toriihq/design-system'

export const TriggerNode = (): ReactElement => {
  const workflow = useSelectedWorkflow()
  const {
    triggerLabel,
    badgeLabel,
    badgeColor = 'blue',
    isTriggerValid,
    triggerSteps,
    triggerDescription,
    triggerErrorMsg
  } = useTriggerConfiguration({ workflow })
  const { idSelectedNode } = useWorkflowNavigation()
  const { idNewNode } = useWorkflowEditorContext()

  const hasStepSetup = triggerSteps.some(step => step.type === WORKFLOW_STEP_TYPE.STEP_SETUP)

  const { statusType, text: status } = getNodeStatus({
    isNew: idNewNode === WORKFLOW_UI_NODE_TYPE.TRIGGER && hasStepSetup,
    isValid: isTriggerValid,
    isSelected: idSelectedNode === WORKFLOW_UI_NODE_TYPE.TRIGGER,
    nodeType: WORKFLOW_UI_NODE_TYPE.TRIGGER,
    customConfigurationText: hasStepSetup ? '' : triggerDescription
  })

  return (
    <WorkflowNodeWrapper id={ID_TRIGGER_NODE}>
      <Container>
        <WorkflowNodeContent
          title={triggerLabel ?? ''}
          iconUrl={TriggerIconUrl}
          statusType={statusType}
          status={status}
          errorMsg={triggerErrorMsg}
        />
        {badgeLabel && <BadgeWrapper>
          <TextBadge color={badgeColor as TextBadgeColors} size='Small'>{badgeLabel}</TextBadge>
        </BadgeWrapper> }
      </Container>
    </WorkflowNodeWrapper>
  )
}
