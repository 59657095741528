import React, { Fragment } from 'react'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import Table from '../table'
import { css } from 'glamor'
import RelativeTeamLink from '../relativeTeamLink'
import texts from '../../shared/style/texts'
import { getDisplayName } from '@lenses/users'
import SafeTemplate from '../safeTemplate'
import pluralize from 'pluralize'
import { dateTimeColumn, wrapColumn } from './columns'
import ExecutionStatus from './executionStatus'
import { TABLES, WORKFLOW_EXECUTION_TYPES, WORKFLOW_OUTPUT_FIELD_TYPES } from '@root/constants'
import reduce from 'lodash/reduce'
import isNil from 'lodash/isNil'
import { hoverActions, insideTableRowsClass } from '@shared/style/mixins'
import Analytics from '@helpers/analytics'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import { Link, ProgressBar, AlertBox, AlertBoxType } from '@toriihq/design-system'
import ReactJson from 'react-json-view'

const CSS = {
  progress: css(texts.caption, hoverActions, {
    display: 'flex'
  }),
  progressBar: css({
    display: 'flex',
    width: '100%',
    margin: '5px 0',
    transform: 'translateY(100%)',
    transition: 'transform .2s',
    [insideTableRowsClass]: {
      transform: 'translateY(0)'
    }
  }),
  alertBoxWrapper: css({
    marginBottom: 12
  })
}

const POLL_INTERVAL_IN_SECONDS = 7

const WORKFLOW_EXECUTION_TYPE_TO_TEXT = {
  [WORKFLOW_EXECUTION_TYPES.ON_ACTIVATION]: 'Run during workflow activation by ',
  [WORKFLOW_EXECUTION_TYPES.MANUAL_API_WORKFLOW]: 'Triggered via API call'
}

class WorkflowExecutions extends React.Component {
  state = { refreshButtonClicks: 0 }

  constructor (props) {
    super(props)
    this.columns = this.getColumns()
  }

  componentDidMount () {
    this.fetchWorkflowData()
    this.fetchExecutionsData(true)
  }

  fetchWorkflowData () {
    const { idOrg, idWorkflow, getWorkflow, getWorkflowsTriggersConfig } = this.props
    getWorkflow({ idOrg, idWorkflow })
    getWorkflowsTriggersConfig()
  }

  fetchExecutionsData = debounce(async (reset = false) => {
    const { idOrg, idWorkflow, executions, getWorkflowExecutions } = this.props

    const { total } = await getWorkflowExecutions({ idOrg, idWorkflow, offset: reset ? 0 : executions.length, reset }) || {}
    if (total === 0) {
      this.updatePolling(false)
    }
  }, 500, { leading: true, trailing: false })

  updatePolling (hasData) {
    if (!hasData) {
      if (!this.interval) {
        this.interval = setInterval(() => { this.fetchExecutionsData(true) }, POLL_INTERVAL_IN_SECONDS * 1000)
        setTimeout(() => clearInterval(this.interval), 60 * 1000)
      }
    } else {
      clearInterval(this.interval)
      this.interval = null
    }
  }

  componentDidUpdate (prevProps) {
    const { total } = this.props
    const hasData = total > 0
    const hadData = prevProps.total > 0

    if (!hadData && hasData) {
      this.updatePolling(true)
    }
  }

  getHeader = () => this.props.total ? `Triggered ${pluralize('time', this.props.total, true)}`.toUpperCase() : ''

  getColumns = () => {
    return [
      {
        Header: 'Trigger',
        accessor: 'triggerOutput',
        Cell: ({ value: triggerOutput, row: { triggerType, triggeredBy, type } }) => {
          const { triggerTypes = {}, idTriggerUsersToParents = {} } = this.props
          return getTriggerInfo({ triggerTypes, triggerType, triggerOutput, idTriggerUsersToParents, triggeredBy, executionType: type })
        },
        maxWidth: 400,
        sortable: false,
        ...wrapColumn
      },
      {
        Header: 'Triggered at (UTC)',
        accessor: 'creationTime',
        ...dateTimeColumn
      },
      {
        Header: 'Completed at (UTC)',
        accessor: 'completionTime',
        ...dateTimeColumn
      },
      {
        Header: 'Progress',
        accessor: 'completedActions',
        Cell: ({
          value: completedActions,
          row: {
            maxPossibleRemainingActionsCount = 0,
            completionTime,
            totalActionExecutions
          }
        }) => {
          const isWorkflowCompleted = Boolean(completionTime)
          let total = completedActions
          if (!isWorkflowCompleted) {
            total = totalActionExecutions + maxPossibleRemainingActionsCount
          }

          const progress = completedActions / total
          return (
            <>
              <div {...CSS.progressBar}>
                <ProgressBar value={progress} max={1} size='Large' />
              </div>
              <div {...CSS.progress}>{completedActions}/{pluralize('action', total, true)}</div>
            </>
          )
        },
        width: 230,
        sortable: false
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: 'completionTime',
        Cell: ({
          value: completionTime,
          row: {
            hasError,
            latestExecutedActionHasError
          }
        }) =>
          <ExecutionStatus
            completionTime={completionTime}
            hasError={completionTime ? hasError : latestExecutedActionHasError}
          />,
        width: 200,
        sortable: false
      },
      {
        accessor: 'id',
        Cell: ({ value: id }) => (
          <span {...CSS.hoverActions}>
            <RelativeTeamLink to={`/${this.props.executionsPathPrefix}/${this.props.idWorkflow}/executions/${id}`}><Link>View details</Link></RelativeTeamLink>
          </span>
        ),
        width: 200,
        sortable: false
      },
      {
        accessor: 'triggerType',
        show: false
      },
      {
        accessor: 'triggeredBy',
        show: false
      },
      {
        accessor: 'maxPossibleRemainingActionsCount',
        show: false
      },
      {
        accessor: 'hasError',
        show: false
      },
      {
        accessor: 'type',
        show: false
      },
      {
        accessor: 'latestExecutedIdAction',
        show: false
      },
      {
        accessor: 'totalActionExecutions',
        show: false
      },
      {
        accessor: 'latestExecutedActionHasError',
        show: false
      }
    ]
  }

  onRefreshButtonClick = () => {
    const { refreshButtonClicks } = this.state
    const { entitiesAmount, entityType, triggerType } = this.props

    Analytics.track('Click on refresh-to-view-more-results-link', {
      'Entities': entitiesAmount,
      'Entity type': entityType,
      'Trigger name': triggerType,
      'Refresh clicks': refreshButtonClicks + 1
    })

    this.setState({ refreshButtonClicks: refreshButtonClicks + 1 })
    this.fetchExecutionsData(true)
  }

  render () {
    const { executions, loading, total, triggerTypes, loadingMore, entities, isManualActionRun, showRefreshAlertBox } = this.props

    return (
      <Fragment>
        {showRefreshAlertBox ? (
          <div {...CSS.alertBoxWrapper}>
            <AlertBox
              type={AlertBoxType.INFORMATIVE}
              description={`You asked to run the ${isManualActionRun ? 'action' : 'workflow'} on many ${entities} at once. It might take several minutes to see the full log.`}
              secondaryButton={{
                label: 'Refresh',
                onClick: this.onRefreshButtonClick
              }}
            />
          </div>
        ) : null}
        <Table
          tableKey={TABLES.workflowsExecutionsTable.key}
          data={executions}
          columns={this.columns}
          header={this.getHeader}
          emptyStateMessage='Not triggered yet'
          loading={loading}
          loadingMore={loadingMore}
          manual
          fetchData={this.fetchExecutionsData}
          triggerTypes={triggerTypes}
          totalCount={total}
        />
      </Fragment>
    )
  }
}

const getTriggerInfo = ({ triggerTypes, triggerType, triggerOutput, idTriggerUsersToParents, triggeredBy, executionType }) => {
  const template = get(triggerTypes[triggerType], ['uiConfig', 'audit'], '')
  const outputSchema = get(triggerTypes[triggerType], ['outputSchema'], {})
  const userFields = reduce(outputSchema, (result, field) => {
    if (field.type === WORKFLOW_OUTPUT_FIELD_TYPES.USER && triggerOutput[field.id]) {
      const user = triggerOutput[field.id]
      result[field.id] = <RelativeTeamUserLink idUser={user.id}><Link>{getDisplayName(user)}</Link></RelativeTeamUserLink>
    }
    return result
  }, {})
  const user = triggerOutput.triggerUser
  const parent = idTriggerUsersToParents[user?.id]
  const app = triggerOutput.app || {}
  const triggerApp = triggerOutput.triggerApp || {}
  const license = triggerOutput.triggerLicense || {}
  const triggerPayload = triggerOutput.triggerPayload || {}

  const triggerBy = <>
    {Boolean(triggeredBy) && <div style={{ fontSize: 11 }}>
      <span>{`(${WORKFLOW_EXECUTION_TYPE_TO_TEXT[executionType] || 'Manually triggered by '}`}</span>
      {executionType !== WORKFLOW_EXECUTION_TYPES.MANUAL_API_WORKFLOW && <RelativeTeamUserLink idUser={triggeredBy.id} isSupportTeamUser={triggeredBy.isSupport}><Link>{getDisplayName(triggeredBy)}</Link></RelativeTeamUserLink>}
      <span>)</span>
    </div>}
  </>

  const data = {
    ...triggerOutput,
    ...userFields,
    triggerUser: user
      ? <span>
        <RelativeTeamUserLink idUser={user.id}>
          <Link>{getDisplayName(parent || user)}</Link>
        </RelativeTeamUserLink>{` (${user.email})`}
      </span>
      : 'a contract or an expense',
    app: <RelativeTeamLink to={`/app/${app.id}`}><Link>{app.name}</Link></RelativeTeamLink>,
    triggerApp: <RelativeTeamLink to={`/app/${triggerApp.id}`}><Link>{triggerApp.name}</Link></RelativeTeamLink>,
    triggerLicense: <span>{license.name}</span>,
    triggerPayload: isNil(triggerPayload) ? '' : <div><b>Payload:</b><br /><ReactJson name={false} displayDataTypes={false} displayObjectSize={false} collapsed src={triggerPayload} /></div>
  }

  return (<>
    <SafeTemplate template={template} data={data} highlightData />
    {triggerBy}
  </>)
}

export default WorkflowExecutions
