import PropTypes from 'prop-types'
import InfoTooltip from '@components/infoTooltip'
import { getSourceByType } from '@root/sourcesConfig'

const IDAPP_TO_INFO = {
  [getSourceByType('google').idApp]: 'Unassigned license count might be different from what you see in the Google Workspace console.  This is because Google API provides this data with ~2 days lag.  Active and inactive license count is updated daily and should be accurate.'
}

const LicenseInfoTooltip = ({ idApp }) => {
  const info = IDAPP_TO_INFO[idApp]

  if (!info) {
    return null
  }

  return <InfoTooltip tooltipText={info} />
}

LicenseInfoTooltip.propTypes = {
  idApp: PropTypes.number.isRequired
}

export default LicenseInfoTooltip
