import AppIconWithBadge from '@root/components/appIconWithBadge'
import { Body1, Body2, H3, Stack } from '@toriihq/design-system'
import { HeaderProps } from './types'
import { WIZARD_STEPS } from '../types'
import StepperCheck from '@media/StepperCheck.svg'
import StepperCurrent from '@media/StepperCurrent.svg'
import StepperNumber2 from '@media/StepperNumber2.svg'
import StepperNumber3 from '@media/StepperNumber3.svg'
import { STEPS_NAMES } from '../config'
import { BackButton, Container } from '@pages/licenseAudit/wizardSteps/header/style'

const Header = ({ accountName, currentStep, app, onBackClick }: HeaderProps) => {
  const { name: appName, imageUrl, isCustom } = app

  const getSvg = (indexOfStep: number, i: number) => {
    if (indexOfStep > i) {
      return StepperCheck
    } else if (indexOfStep === i) {
      return StepperCurrent
    } else if (i === 1) {
      return StepperNumber2
    } else {
      return StepperNumber3
    }
  }

  return (
    <Container>
      <Stack direction='column' gap='space-300'>
        <BackButton linkText='Back to application' onClick={onBackClick} />
        <Stack gap={'space-0'} direction={'row'} justifyContent={'space-between'} alignItems='flex-start'>
          <Stack gap={'space-300'} direction={'row'}>
            <AppIconWithBadge name={appName} src={imageUrl} badge={Boolean(isCustom) && 'CUSTOM'} size='x-large' />
            <Stack gap={'space-025'} direction={'column'} alignItems={'start'} >
              <H3>
                {appName} License Audit
              </H3>
              <Body2>
                {accountName}
              </Body2>
            </Stack>
          </Stack>
          <Stack gap={'space-200'} direction={'row'} alignItems='flex-start'>
            {Object.values(WIZARD_STEPS).map((step, i) => {
              const indexOfStep = Object.values(WIZARD_STEPS).indexOf(currentStep)
              const svg = getSvg(indexOfStep, i)
              const stepName = STEPS_NAMES[step]
              return <Stack gap={'space-100'} direction={'row'}>
                <img alt='step icon' src={svg} />
                <Body1>
                  {stepName}
                </Body1>
              </Stack>
            })}
          </Stack>
        </Stack>
      </Stack>
    </Container>)
}

export default Header
