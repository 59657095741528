import { useSelector, useDispatch } from 'react-redux'
import { toggleDeleteImportedUsers } from '@actions/'
import Wizard from '../wizardPopup'
import { stepsConfig } from './steps/config'
import { getIdOrg } from '@selectors/org'
import { getDeleteImportedUsersPopupProperties } from '@selectors/importUsers/importUsers'
import ImportUsersAnalytics from '@components/popups/importUsersPopup/analytics'
import { SharedProps, SharedState, WIZARD_STEPS } from './steps/types'

const ImportUsersDeletionPopup = (): JSX.Element => {
  const idOrg = useSelector(getIdOrg)
  const { isOpen, idApp, importMode, idAppAccount, appAccountName, workflowsToInvalidate, lastSyncTime } = useSelector(getDeleteImportedUsersPopupProperties)
  const dispatch = useDispatch()

  const closePopup = () => {
    dispatch(toggleDeleteImportedUsers({ isOpen: false }))
  }

  const onCloseButton = () => {
    ImportUsersAnalytics.onCloseDeletePopup(importMode, 'X')
    closePopup()
  }

  const onCancelButton = () => {
    ImportUsersAnalytics.onCloseDeletePopup(importMode, 'Cancel')
    closePopup()
  }

  const initialSharedState: SharedState = {
    isFirstLoaded: false
  }

  return (
    <Wizard<SharedState, SharedProps, WIZARD_STEPS>
      isPopup
      name={'importUsersDeletion'}
      isOpen={isOpen}
      stepsConfig={stepsConfig()}
      initialSharedState={initialSharedState}
      sharedProps={{ idOrg, idApp, importMode, idAppAccount, appAccountName, workflowsToInvalidate, lastSyncTime, closePopup }}
      onCancel={onCancelButton}
      onClose={onCloseButton}
      width='518px'
      onSubmitSuccess={() => {}}
    />
  )
}

export default ImportUsersDeletionPopup
