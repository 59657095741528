import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  Stack,
  Body2,
  H4,
  ExpandableSection,
  Subtitle1,
  Grid,
  Row,
  Col,
  Spacer,
  Subtitle2
} from '@toriihq/design-system'
import { RendererProps } from '@root/components/popups/wizardPopup/types'
import { SharedProps, SharedState, WIZARD_STEPS } from '../wizardSteps/types'
import { FullHeight, FullHeightWidth, FullWidth, WithBorder } from './style'
import Header from '../wizardSteps/header'
import Footer from '../wizardSteps/footer'
import CopyTextPopup from '@components/copyTextPopup'
import config from '@root/config'
import { SUPPORT_ARTICLES } from '@root/articles'
import Currency from '@components/currency'
import AppAccountLicenseUsers from '@pages/licenseAudit/appAccountLicenseUsers'
import { sumBy, get } from 'lodash'
import LicensesChart from '@root/components/licensesChart'
import { getCurrentOrg } from '@root/store/selectors/org'

const Recommendation = ({
  sharedState, sharedProps, setState, navigateToStep, onCancel
}: RendererProps<SharedState, SharedProps, WIZARD_STEPS>): JSX.Element => {
  const { idOrg, app, appName, licenses, idApp, idAppAccount, appAccount } = sharedProps
  const { appNotInUsePeriod } = useSelector(getCurrentOrg)

  const licensesDetails = useMemo(() => {
    const paidLicenses = licenses.filter(license => {
      const pricePerUser = get(license, 'pricePerUser', 0)
      return pricePerUser > 0
    })
    const totalPaidLicenses = sumBy(paidLicenses, 'totalAmount')
    const activeUsersAmount = sumBy(paidLicenses, 'activeAmount')
    const inactiveUsersAmount = sumBy(paidLicenses, 'inactiveAmount')
    const unassignedUsersAmount = sumBy(paidLicenses, 'unassignedAmount')
    const savings = sumBy(paidLicenses, 'savingsConverted')
    return { totalPaidLicenses, activeUsersAmount, inactiveUsersAmount, unassignedUsersAmount, savings }
  }, [licenses])

  const onBackClick = () => {
    navigateToStep(WIZARD_STEPS.LICENSES_REVIEW)
    window.history.pushState({}, '', `/team/${idOrg}/app/${idApp}/account/${idAppAccount}/licenseAudit/${WIZARD_STEPS.LICENSES_REVIEW}`)
  }

  const Bullet = (text: string) => <Stack direction='row' gap='space-100'><Body2>•</Body2><Body2>{text}</Body2></Stack>

  const licensesBreakdown = <WithBorder>
    <Stack direction='column' gap='space-200'>
      <Subtitle2>Based on your {appNotInUsePeriod} days of license utilization:</Subtitle2>
      <Stack direction='column' gap='space-025'>
        {Bullet(`Total paid licenses: ${licensesDetails.totalPaidLicenses}`)}
        {Bullet(`Recommended to keep active: ${licensesDetails.activeUsersAmount}`)}
        {Bullet(`Recommended to remove: ${licensesDetails.inactiveUsersAmount ? `${licensesDetails.inactiveUsersAmount} unused` : ''}${licensesDetails.inactiveUsersAmount && licensesDetails.unassignedUsersAmount ? ' + ' : ''}${licensesDetails.unassignedUsersAmount ? `${licensesDetails.unassignedUsersAmount} unassigned` : ''}`)}
      </Stack>
      <Body2>Expected savings: <Currency value={licensesDetails.savings} /></Body2>
    </Stack>
  </WithBorder>

  return (
    <FullHeight>
      <Stack direction={'column'} gap={'space-400'} alignItems={'start'} >
        <Header app={app} accountName={appAccount.appAccountName} currentStep={WIZARD_STEPS.RECOMMENDATIONS} onBackClick={onCancel} />
        <FullWidth>
          <div style={{ marginBottom: '12px' }}>
            <Stack direction={'column'} gap={'space-400'} alignItems={'start'}>
              <FullWidth>
                <Stack direction={'row'} justifyContent={'space-between'} gap={'space-200'} alignItems='flex-start'>
                  <H4>Rightsizing Recommendations</H4>
                  <CopyTextPopup
                    icon='Share'
                    label='Share'
                    textToCopy={`${config.appBaseUrl}/team/${idOrg}/app/${idApp}/account/${idAppAccount}/licenseAudit/${WIZARD_STEPS.LICENSES_REVIEW}`}
                    analyticsText='Click on Copy renewal rightsizing recommendations share link'
                    bodyText={`Share ${appName} license audit`}
                    supportArticleLink={SUPPORT_ARTICLES.RENEWAL_CALENDAR_SHARE}
                  />
                </Stack>
              </FullWidth>
              <FullWidth>
                <FullHeight>
                  <FullWidth>
                    <Grid columns={2} gap='space-200'>
                      <Row sm={12} style={{ marginBottom: 0 }}>
                        <Col sm={6}>
                          <FullHeight>
                            {licensesBreakdown}
                          </FullHeight>
                        </Col>
                        <Col sm={6}>
                          <FullHeightWidth>
                            <LicensesChart idApp={idApp} appName={app.name} idAppAccount={idAppAccount} disableInactiveClick fullHeight />
                          </FullHeightWidth>
                        </Col>
                      </Row>
                    </Grid>
                  </FullWidth>
                </FullHeight>
              </FullWidth>
              <FullWidth>
                <Stack gap={'space-150'} direction={'column'}>
                  <Subtitle1>License breakdown</Subtitle1>
                  <Stack direction='column' gap={'space-200'}>
                    {licenses.map(license => {
                      return <ExpandableSection title={license.name} key={license.id}>
                        <AppAccountLicenseUsers idApp={idApp} idLicense={license.id} idAppAccount={idAppAccount} idOrg={idOrg} license={license} />
                      </ExpandableSection>
                    })}
                  </Stack>
                </Stack>
              </FullWidth>
            </Stack>
          </div>
        </FullWidth>
        <Spacer bottom='space-025' ><div /></Spacer>
      </Stack>
      <Footer
        shouldShowBackButton
        onBackClick={onBackClick}
        onCloseClick={onCancel}
      />
    </FullHeight>
  )
}

export default Recommendation
