import styled from 'styled-components'

export const FlexContainer = styled.div`
  flex: 1;
`

export const LicenseTableWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  border-radius: ${({ theme }) => theme.corner('radius-050')};
`
