import React from 'react'
import { css } from 'glamor'
import ToriiSelect from '../../select'
import Input from '../../form/input'
import { Stack } from '@toriihq/design-system'

const CSS = {
  selectContainer: css({
    width: '100%'
  })
}

class Threshold extends React.Component {
  onChange = (value, locallyOnly = false) => {
    const { threshold, onChange } = this.props
    onChange({ ...threshold, ...value }, locallyOnly)
  }

  render () {
    const { options, threshold, disabled } = this.props

    return (
      <div>
        <Stack gap='space-150' direction='row'>
          <div {...CSS.selectContainer}>
            <ToriiSelect
              options={options}
              placeholder='Threshold'
              onChange={(option) => this.onChange({ type: option.value })}
              clearable={false}
              value={threshold.type}
              disabled={disabled}
            />
          </div>
          <Input
            type='number'
            value={threshold.number}
            onBlur={(e) => this.onChange({ number: e.target.value })}
            onChange={(e) => this.onChange({ number: e.target.value }, true)}
            disabled={disabled}
          />
        </Stack>
      </div>
    )
  }
}

export default Threshold
