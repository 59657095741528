import React from 'react'
import { createSelector } from 'reselect'
import identity from 'lodash/identity'
import minBy from 'lodash/minBy'
import sumBy from 'lodash/sumBy'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import pick from 'lodash/pick'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import flatten from 'lodash/flatten'
import moment from 'moment'
import {
  APP_OWNERS_FIELDS,
  CONTRACT_STATUS,
  EMPTY_ARRAY,
  formFieldTypes,
  numberOfDaysToConsiderAsNew,
  scoreSpecialValues,
  TABLES
} from '@root/constants'
import { getAppDetailsByIdAppIdField, getAppStateInfo, getStateInfoForEsFilters, isNew } from '@lenses/apps'
import { getSourceByType } from '@root/sourcesConfig'
import orderBy from 'lodash/orderBy'
import RiskIcon, { permissionsLegend } from '@components/riskIcon'
import { fieldsValuesToOptions } from '@lenses/utils'
import { getContractsByIdApp, getContractsResources, isLoadingContracts } from '@selectors/contracts'
import { createIdAppParameterSelector } from '@shared/utils'
import { getSyncStatus } from '@selectors/services'
import range from 'lodash/range'
import uniqBy from 'lodash/uniqBy'
import compact from 'lodash/compact'

export const isAppsLoaded = createSelector(
  state => state.apps.isLoaded,
  identity
)

export const isAppsLoading = createSelector(
  state => state.apps.loading,
  identity
)

export const isAppsV2Loading = createSelector(
  state => state.apps.appsV2.loading,
  identity
)

export const isExpensesReportAppsLoading = createSelector(
  state => state.apps.expenseReportApps.loading,
  identity
)

export const isAppPermissionsLoading = createSelector(
  state => state.apps.loadingPermissions,
  identity
)

/**
 * @deprecated use apps from opensearch, see getAppsV2 action for reference
 */
export const deprecatedGetApps = createSelector(
  state => state.apps.apps,
  identity
)

export const getAppsV2 = createSelector(
  state => state.apps.appsV2,
  identity
)

export const getExpensesReportApps = createSelector(
  state => state.apps.expenseReportApps,
  identity
)

export const getLoadingSuggestedOwners = createSelector(
  state => state.apps.loadingSuggestedOwners,
  identity
)

export const getAppResources = createSelector(
  state => state.apps.appsResources || {},
  identity
)

export const getSuggestedOwners = createSelector(
  state => state.apps.suggestedOwners,
  identity
)

/**
 * @deprecated use apps from opensearch, see getAppsV2 action for reference
 */
export const deprecatedGetAppsByIds = createSelector(
  [deprecatedGetApps],
  apps => keyBy(apps, 'id')
)

export const getPaidApps = createSelector(
  deprecatedGetApps,
  apps => apps.filter(app => sumBy(app.expenses, month => month.total) !== 0)
)

export const getUsedApps = createSelector(
  [deprecatedGetApps],
  apps => apps.filter(app => app.sources && app.sources.length > 0)
)

export const getUsedAppsByIds = createSelector(
  [getUsedApps],
  apps => keyBy(apps, 'id')
)

export const getAppsPermissions = createSelector(
  state => state.apps.permissions,
  identity
)

export const isAppPermissionsLoaded = createSelector(
  state => state.apps.isPermissionsLoaded,
  identity
)

export const getOldestAppByCreationTime = createSelector(
  getUsedApps,
  usedApps => minBy(usedApps, item => new Date(item.creationTime))
)

export const getAppDetailsFields = createSelector(
  state => sortBy(get(state, ['appDetails', 'fields'], []), 'position'),
  identity
)

export const getAppV2DetailsFields = createSelector(
  state => get(state, ['apps', 'appsV2', 'resources', 'fields'], []),
  identity
)

export const getExpenseReportsAppsDetailsFields = createSelector(
  state => get(state, ['apps', 'expenseReportApps', 'resources', 'fields'], []),
  identity
)

export const getAppDetailsResources = createSelector(
  state => get(state, ['appDetails', 'resources'], []),
  identity
)

export const getAppAndDetailsResources = createSelector(
  [getAppResources, getAppDetailsResources],
  (appResources, appDetailsResources) => {
    return {
      apps: {
        ...appResources.apps,
        ...appDetailsResources.apps
      },
      users: {
        ...appResources.users,
        ...appDetailsResources.users
      }
    }
  }
)

export const getAppDetailsFieldsHistory = createSelector(
  state => get(state, ['appDetails', 'fieldsHistory'], {}),
  identity
)

export const isAppDetailsFieldsHistoryLoading = createSelector(
  state => get(state, ['appDetails', 'loadingFieldsHistory']),
  identity
)

export const getEditableAppDetailsFieldsV2 = createSelector(
  getAppV2DetailsFields,
  (appDetails) => appDetails
    .filter(detail => (![formFieldTypes.fileUpload, formFieldTypes.usersDropdownMulti].includes(detail.type) || detail.systemKey === APP_OWNERS_FIELDS.appOwners) && detail.isShown)
    .map(detail => detail.systemKey === 'state' ? { ...detail, disableDelete: true } : detail)
)

export const getConfigurableAppDetailsFields = createSelector(
  getAppDetailsFields,
  (appDetailsFields) => appDetailsFields
    .filter(detail => detail.systemKey === 'state' || (detail.category === 'app_details' && detail.type !== 'fileUpload'))
    .map(detail => ({ ...detail, options: detail.options.filter(option => !option.isDeleted) }))
)

export const getConfigurableAppDetailsFieldsByGroup = createSelector(
  getConfigurableAppDetailsFields,
  fields => groupBy(fields, 'idGroup')
)

export const getAppDetailsFieldsByIdField = createSelector(
  state => get(state, ['appDetails', 'fields'], []),
  fields => keyBy(fields, 'idField')
)

export const getAppDetailsValues = createSelector(
  state => get(state, ['appDetails', 'values'], {}),
  identity
)

export const getAppContactsDetails = createSelector(
  [getAppDetailsFields, getAppDetailsValues, getContractsByIdApp, createIdAppParameterSelector, getAppDetailsResources],
  (fields, values, contractsByIdApp, idApp, resources) => {
    const details = fields.map(field => {
      const appValues = getAppDetailsByIdAppIdField({ idApp, idField: field.idField, values })
      return {
        ...field,
        idApp,
        values: appValues
      }
    })
    const contractOwnersIds = (contractsByIdApp[idApp] || []).flatMap(contract => contract.status === CONTRACT_STATUS.closed ? [] : contract.owner ? [contract.owner] : [])
    const contractOwnersFieldType = contractOwnersIds.length === 1 ? formFieldTypes.usersDropdown : formFieldTypes.usersDropdownMulti
    const contractOwnersDetails = contractOwnersIds.length ? [{ idField: 0, type: contractOwnersFieldType, systemKey: 'contractOwners', name: 'Contract Owners', values: [...new Set(contractOwnersIds)] }] : []

    const sortedDetails = details.filter(detail => {
      if (![formFieldTypes.usersDropdown, formFieldTypes.usersDropdownMulti].includes(detail.type)) {
        return false
      }
      if ([APP_OWNERS_FIELDS.primary, APP_OWNERS_FIELDS.appOwners].includes(detail.systemKey)) {
        return false
      }
      if (detail.isPluginGroup) {
        return false
      }

      return detail.isShown
    })

    const sortedDetailsWithUniqueContacts = sortedDetails.map(field => ({ ...field, values: uniqBy(compact(field.values.map(idContact => resources.users[idContact])), 'email').map(u => u.id) }))
    return sortedDetailsWithUniqueContacts.concat(contractOwnersDetails)
  }
)

export const getAppContactsUsers = createSelector(
  [getAppAndDetailsResources, getContractsResources],
  ({ users: usersById }, { users: contractUsers }) => ({ ...contractUsers, ...usersById })
)

export const isLoadingAppDetailsValues = createSelector(
  state => get(state, ['appDetails', 'loadingAppStates'], false),
  identity
)

export const getAppDetailsGroups = createSelector(
  state => get(state, ['appDetails', 'groups'], []),
  groups => sortBy(groups, 'position')
)

export const getAppDetailsByIdApp = createSelector(
  [getAppDetailsFields, getAppDetailsValues, createIdAppParameterSelector],
  (fields, values, idApp) => {
    return fields.map(field => {
      const appValues = getAppDetailsByIdAppIdField({ idApp, idField: field.idField, values })
      return {
        ...field,
        idApp,
        values: appValues
      }
    }).filter(detail => ![formFieldTypes.fileUpload].includes(detail.type) && detail.isShown)
  }
)

export const getAppDetailsGroupsForSelectGroup = createSelector(
  state => get(state, ['appDetails', 'groups'], []),
  groups => {
    const groupsForSelect = groups.map(group => {
      return {
        label: group.label.toUpperCase(),
        value: group.id
      }
    })
    return orderBy(groupsForSelect, [group => group.label.toLowerCase()], ['asc'])
  }
)

export const isAppDetailsValuesLoading = createSelector(
  state => get(state, ['appDetails', 'loadingValues']),
  identity
)

export const isAppDetailsFieldsLoading = createSelector(
  state => get(state, ['appDetails', 'loadingFields']),
  identity
)

export const isAppDetailsGroupsLoading = createSelector(
  state => get(state, ['appDetails', 'loadingGroups']),
  identity
)

export const shouldShowNewBadge = createSelector(
  getOldestAppByCreationTime,
  oldestAppByCreationTime => {
    const now = moment()
    const { creationTime } = oldestAppByCreationTime || {}
    return creationTime ? (now.diff(moment(creationTime), 'days') > numberOfDaysToConsiderAsNew) : false
  }
)

export const getAppsV2ConfigurableColumnsOptions = createSelector(
  [getAppV2DetailsFields],
  (dynamicFields) => {
    const dynamicOptions = dynamicFields
      .filter(field => field.category === 'app_details' && field.isShown)
      .map(field => ({ value: field.systemKey, label: field.name }))
    const { preDefinedColumnsMapping } = TABLES.appsV2Table
    const preDefinedOptions = map(preDefinedColumnsMapping, (value, key) => {
      return { value: key, label: value }
    })

    return preDefinedOptions.concat(dynamicOptions)
  }
)

export const getFieldValues = createSelector(
  state => state.apps.appsV2.fieldsValues,
  fieldsValuesToOptions
)

export const getExpenseReportAppsFieldValues = createSelector(
  state => state.apps.expenseReportApps.fieldsValues,
  fieldsValuesToOptions
)

export const getTotalOrgApps = createSelector(
  state => state.apps.appsV2.totalOrgApps,
  identity
)

export const getHiddenApps = createSelector(
  state => get(state, ['hiddenApps', 'apps'], []),
  identity
)

export const getHiddenAppsUsersSelector = createSelector(
  state => get(state, ['hiddenApps', 'resources', 'users'], []),
  identity
)

export const isLoadingHiddenApps = createSelector(
  state => get(state, ['hiddenApps', 'loading'], false),
  identity
)

export const getSSOAuditReport = createSelector(
  state => get(state, ['reports', 'ssoAudit']),
  identity
)

const getUsageScore = (score = 0, lastVisitTime) => {
  if (score > 0) {
    return score
  }

  if (!lastVisitTime) {
    return scoreSpecialValues.notCollectingUsage
  }

  const isInLast30Days = moment().diff(moment(lastVisitTime), 'days') <= 30
  if (isInLast30Days) {
    return scoreSpecialValues.notCollectingUsage
  }

  return scoreSpecialValues.noUsageInLast30Days
}

export const getAppsWithExtraData = createSelector(
  [getUsedApps, getAppDetailsValues, getAppDetailsFieldsByIdField, shouldShowNewBadge, getAppDetailsFields],
  (allApps, appDetailsValues, appDetailsFieldsByIdField, shouldShowNewBadge, appDetailsFields) => {
    return allApps.map(app => {
      const stateInfo = getAppStateInfo({ values: appDetailsValues, idApp: app.id, fields: appDetailsFields })
      const expenses = (sumBy(app.expenses, month => month.total) || 0) / 100
      const appDetails = appDetailsValues[app.id] || EMPTY_ARRAY
      const appDetailsObject = appDetails
        .reduce((result, detail) => {
          const type = (appDetailsFieldsByIdField[detail.idField] || {}).type
          const isMulti = (type === formFieldTypes.dropdownMulti)
          if (isMulti) {
            result[detail.idField] = detail.values
          } else if ([formFieldTypes.number, formFieldTypes.currency].includes(type)) {
            result[detail.idField] = parseInt(detail.values[0])
          } else if (formFieldTypes.datePicker === type) {
            result[detail.idField] = new Date(detail.values[0])
          } else if (formFieldTypes.fileUpload === type) {
            result[detail.idField] = (detail.values || []).length
          } else {
            result[detail.idField] = detail.values[0]
          }
          return result
        }, {})

      return {
        ...app,
        isNew: shouldShowNewBadge && isNew(app),
        expenses,
        score: getUsageScore(app.score, app.lastVisitTime),
        users: app.activeUsersCount,
        stateInfo,
        filterableState: stateInfo.selectedValue,
        path: `/app/${app.id}`,
        ...appDetailsObject,
        creationTime: app.creationTime && new Date(app.creationTime)
      }
    })
  }
)

export const getAppsWithExtraDataBySystemKey = createSelector(
  [getUsedApps, getAppDetailsValues, getAppDetailsFieldsByIdField, shouldShowNewBadge, getAppDetailsFields],
  (allApps, appDetailsValues, appDetailsFieldsByIdField, shouldShowNewBadge, appDetailsFields) => {
    return allApps.map(app => {
      const stateInfo = getAppStateInfo({ values: appDetailsValues, idApp: app.id, fields: appDetailsFields })
      const expenses = (sumBy(app.expenses, month => month.total) || 0) / 100
      const appDetails = appDetailsValues[app.id] || EMPTY_ARRAY
      const appDetailsObject = appDetails
        .reduce((result, detail) => {
          const type = (appDetailsFieldsByIdField[detail.idField] || {}).type
          const isMulti = (type === formFieldTypes.dropdownMulti)
          if (isMulti) {
            result[detail.systemKey] = detail.values
          } else if ([formFieldTypes.number, formFieldTypes.currency].includes(type)) {
            result[detail.systemKey] = parseInt(detail.values[0])
          } else if (formFieldTypes.datePicker === type) {
            result[detail.systemKey] = new Date(detail.values[0])
          } else if (formFieldTypes.fileUpload === type) {
            result[detail.systemKey] = (detail.values || []).length
          } else {
            result[detail.systemKey] = detail.values[0]
          }
          return result
        }, {})

      return {
        ...app,
        isNew: shouldShowNewBadge && isNew(app),
        expenses,
        score: getUsageScore(app.score, app.lastVisitTime),
        users: app.activeUsersCount,
        stateInfo,
        filterableState: stateInfo.selectedValue,
        path: `/app/${app.id}`,
        ...appDetailsObject,
        creationTime: app.creationTime && new Date(app.creationTime)
      }
    })
  }
)

export const getAppStateOptions = createSelector(
  getAppsWithExtraData,
  (apps) => get(apps, ['0', 'stateInfo', 'options'], [])
)

export const getAppStateOptionsForEsFilters = createSelector(
  [getAppDetailsFields],
  (appDetailsFields) => {
    return getStateInfoForEsFilters({ fields: appDetailsFields })
  }
)

export const getAppStateOrder = createSelector(
  getAppStateOptions,
  (stateOptions) => {
    const stateOrder = {}
    stateOptions.forEach((option, index) => {
      stateOrder[option.value] = index
    })
    return stateOrder
  }
)

export const getAppsLicensesSelector = createSelector(
  state => state.apps.appsLicenses,
  identity
)

export const getAppsLicensesLoadingSelector = createSelector(
  state => state.apps.loadingAppsLicenses,
  identity
)

const mostUsedAppsSelector = createSelector(
  state => get(state, ['apps', 'mostUsedApps', 'apps'], []),
  identity
)

export const isLoadingMostUsedApps = createSelector(
  state => get(state, ['apps', 'mostUsedApps', 'loading']),
  identity
)

export const getAppsCategory = createSelector(
  [deprecatedGetApps],
  apps => {
    const unsortedAppsCategoryCount = apps.reduce((acc, app) => {
      const appCategory = acc.find(appCategory => appCategory.name === app.category)
      appCategory ? appCategory.value++ : acc.push({ name: app.category, value: 1 })
      return acc
    }, [])
    return orderBy(unsortedAppsCategoryCount, ['value', 'name'], ['desc', 'asc'])
  }
)

export const isLoadingApps = createSelector(
  state => get(state, ['apps', 'loading']),
  identity
)

export const isLoadedMostUsedApps = createSelector(
  state => get(state, ['apps', 'mostUsedApps', 'isLoaded']),
  identity
)

export const getMostUsedApps = createSelector(
  [mostUsedAppsSelector, isLoadedMostUsedApps, isLoadingMostUsedApps],
  (apps, isLoaded, isLoading) => {
    const APPS_AMOUNT = 10
    const MAX_ACTIVE_USERS = 30
    const loading = !isLoaded || (isLoading && apps.length === 0)
    const missingAppsAmount = APPS_AMOUNT - apps.length
    const smallestActiveUsers = get(apps, apps.length - 1, {}).activeUsers || MAX_ACTIVE_USERS

    const gap = Math.floor((smallestActiveUsers) / missingAppsAmount)
    const missingApps = range(1, missingAppsAmount + 1).map(index => {
      const loadingActiveUsers = index % 2 ? 60 : 80
      const activeUsers = smallestActiveUsers - (index * gap)
      return {
        key: `gap_${index}`,
        name: '',
        imageUrl: '',
        activeUsers: loading ? loadingActiveUsers : activeUsers
      }
    })
    const data = apps.concat(missingApps)
    return { apps: data, loading }
  }
)

export const getUserDataAccessByCategory = createSelector(
  state => get(state, ['apps', 'userDataAccess', 'categories'], []),
  identity
)

export const getUserDataAccessOfCategorySelector = createSelector(
  state => get(state, ['apps', 'userDataAccessOfCategory'], {}),
  identity
)

export const getUserDataAccessOfCategoryForTable = createSelector(
  [getUserDataAccessOfCategorySelector],
  ({ apps }) => {
    return apps
  }
)

export const isLoadedUserDataAccessByCategory = createSelector(
  state => get(state, ['apps', 'userDataAccess', 'isLoaded']),
  identity
)

export const isLoadingUserDataAccessByCategory = createSelector(
  state => get(state, ['apps', 'userDataAccess', 'loading']),
  identity
)

export const getCustomApps = createSelector(
  state => get(state, ['customApps', 'apps'], []),
  identity
)

export const isLoadingCustomApps = createSelector(
  state => get(state, ['customApps', 'loading'], false),
  identity
)

export const getAppChargeback = createSelector(
  state => get(state, ['apps', 'chargeback'], {}),
  identity
)

export const getSourcesWithApps = createSelector(
  [getAppsPermissions, getAppDetailsValues, getAppDetailsFields],
  (permissionsInfo, appDetailsValues, appDetailsFields) => {
    return map(permissionsInfo, (sourceApps, source) => {
      const apps = sourceApps
        .map(app => {
          const stateInfo = getAppStateInfo({ values: appDetailsValues, idApp: app.idApp, fields: appDetailsFields })

          return {
            ...pick(app, ['idApp', 'activeUsers', 'activeUsersWithPermissions', 'permissions', 'name', 'imageUrl', 'category', 'url', 'riskLevel']),
            stateInfo
          }
        })

      return {
        ...pick(getSourceByType(source), ['idApp', 'name', 'icon', 'id']),
        apps
      }
    }).filter(source => source.apps.length)
  }
)

export const getLicenseAuditData = createSelector(
  state => get(state, ['apps', 'licenseAuditData'], {}),
  identity
)

export const getRiskLevel = createSelector(
  getSourcesWithApps,
  sourcesWithApps => {
    const connectedAppsPermissions = flatten(sourcesWithApps.map(source => source.apps))

    const basicChartInfo = sortBy(map(permissionsLegend, (permission, index) => { return { name: permission.text, color: permission.innerColor, riskLevel: parseInt(index) } }), permission => -permission.riskLevel)
    return basicChartInfo
      .map(permissionOption => ({
        ...permissionOption,
        value: connectedAppsPermissions.filter(app => app.riskLevel === permissionOption.riskLevel).length,
        icon: <RiskIcon riskLevel={permissionOption.riskLevel} />
      }))
  }
)

export const getCatalogApps = createSelector(
  state => get(state, ['apps', 'catalog', 'apps'], []),
  identity
)

export const isLoadingCatalogApps = createSelector(
  state => get(state, ['apps', 'catalog', 'loading']),
  identity
)

export const getCatalogPoliciesInfo = createSelector(
  state => get(state, ['apps', 'catalog', 'appsAggregations'], []),
  identity
)

export const getCatalogRequestNewAppPoliciesAmount = createSelector(
  state => get(state, ['apps', 'catalog', 'requestNewAppPoliciesAmount'], []),
  identity
)

export const getCatalogAppsWithExtraData = createSelector(
  [getCatalogApps, deprecatedGetAppsByIds],
  (allApps, appsWithExtraDataById) => {
    return allApps.map(app => {
      return {
        ...app,
        addedBy: appsWithExtraDataById[app.id]?.addedBy,
        score: getUsageScore(app.score, app.lastVisitTime),
        creationTime: app.creationTime && new Date(app.creationTime)
      }
    })
  }
)

export const getDefaultPolicyCatalogAppsData = createSelector(
  [getCatalogApps, getCatalogPoliciesInfo],
  (allApps, { idAppsWithDefaultPolicy }) => {
    return allApps.filter(app => idAppsWithDefaultPolicy.includes(app.id))
  }
)

export const getComparisonIdApps = createSelector(
  state => state.apps.comparisonApps.idApps,
  identity
)

export const getExtensionOnlyApps = createSelector(
  state => state.apps.comparisonApps.extensionOnlyApps,
  identity
)

export const getComparisonRecommendations = createSelector(
  state => state.apps.comparisonApps.comparisonRecommendations,
  identity
)

export const getAppsComparisonData = createSelector(
  state => state.apps.comparisonApps,
  identity
)

export const isLoadingAppsComparisonData = createSelector(
  [isAppPermissionsLoading, isLoadingApps, isLoadingContracts, getAppsComparisonData],
  (isLoadingAppPermissions, isLoadingApps, isLoadingContracts, { loadingComparisonApps, loadingComparisonActiveUsers, loadingComparisonUsage, loadingComparisonUsersOverlaps, loadingComparisonUsersOverlapsWithLicenses }) => {
    return isLoadingAppPermissions || isLoadingApps || isLoadingContracts || loadingComparisonApps || loadingComparisonActiveUsers || loadingComparisonUsage || loadingComparisonUsersOverlaps || loadingComparisonUsersOverlapsWithLicenses
  }
)

export const getShowAppsCompareTab = createSelector(
  state => state.apps.comparisonApps.showAppsCompareTab,
  identity
)

export const getCurrentApp = createSelector(
  state => state.apps.currentAppV2,
  identity
)

export const getAppOwnersList = createSelector(
  state => get(state, ['apps', 'appOwners', 'owners']),
  identity
)

export const isLoadingAppOwners = createSelector(
  state => get(state, ['apps', 'appOwners', 'loading']),
  identity
)

export const getAppDetailsStateField = createSelector([getAppV2DetailsFields, getAppDetailsFields],
  (appV2DetailsFields, appDetailsFields) => {
    const fields = appV2DetailsFields.length ? appV2DetailsFields : appDetailsFields
    return fields.find(detail => detail.systemKey === 'state')
  }
)

export const getAppAccountStatuses = createSelector(
  [getCurrentApp, getSyncStatus],
  (app, accountSyncStatuses) => {
    if (!app || app.loading) {
      return { loading: true }
    }

    const idApp = app.app.id

    const appAccounts = (accountSyncStatuses || [])
      .filter(accountSyncStatus => idApp === accountSyncStatus.idApp)
      .map(accountSyncStatus => {
        const { idAppAccount, appAccountName, lastSyncTime, lastUsersAndLicensesFileUploadedBy: lastSyncBy, lastUsersAndLicensesFileLastSyncTime,
          source, licensesManagedManually, workflowsToInvalidate, syncStatus } = accountSyncStatus

        return {
          idAppAccount,
          appAccountName,
          lastSyncTime,
          lastSyncBy,
          lastUsersAndLicensesFileLastSyncTime,
          source,
          licensesManagedManually,
          workflowsToInvalidate,
          syncStatus
        }
      })

    return { loading: false, appAccounts }
  }
)

export const getAppDetailsOwnersFields = createSelector(
  [getAppDetailsValues, createIdAppParameterSelector],
  (details, idApp) => {
    const appOwnersSystemKeys = Object.values(APP_OWNERS_FIELDS)
    const appDetails = get(details, [idApp], [])
    return appDetails
      .filter(detail => appOwnersSystemKeys.includes(detail.systemKey))
      .sort((detail1) => detail1.systemKey === APP_OWNERS_FIELDS.primary ? -1 : 0)
  }
)

export const getOwnersByIdApp = createSelector(
  [getAppDetailsOwnersFields, getAppDetailsResources],
  (ownerFields, resources) => {
    return keyBy(ownerFields.map(field => ({
      ...field,
      values: uniqBy(field.values.map(idUser => {
        if (idUser === null) {
          return null
        }
        return { ...(resources.users?.[idUser] || {}) }
      }), 'email')
    })), 'systemKey')
  }
)

export const isLoadedAppCompliance = createSelector(
  state => !get(state, ['apps', 'appCompliance', 'loading']),
  identity
)

export const getAppComplianceByIdApp = createSelector(
  (state, idApp) => get(state, ['apps', 'appCompliance', idApp], {}),
  identity
)
