import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '@root/shared/style/colors'
import texts from '@root/shared/style/texts'
import { clearButton, oneLiner } from '@root/shared/style/mixins'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import OverflowTooltip from '@components/overflowTooltip'
import { ButtonSize, ButtonType, Button, Tooltip, theme } from '@toriihq/design-system'
import isNumber from 'lodash/isNumber'
import onClickOutside from 'react-onclickoutside'
import ToriiLinkify from '@components/_shared/ToriiLinkify'

const CSS = {
  main: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  }),
  field: css({
    position: 'relative',
    minHeight: '64px',
    width: '100%',
    borderTop: `1px solid ${theme.palette.border.primary}`,
    borderBottom: `1px solid ${theme.palette.border.primary}`,
    padding: '15px 20px',
    display: 'flex',
    gap: '32px',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'background-color .2s',
    ':before': {
      content: `''`,
      transition: 'opacity .2s',
      borderLeft: `6px solid ${colors.blue}`,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: 0,
      opacity: 0
    },
    ':hover': {
      backgroundColor: colors.lightBlue3
    },
    '&.edit': {
      backgroundColor: colors.lightBlue3,
      ':before': {
        opacity: 1
      }
    }
  }),
  label: css(texts.subheading, {
    flex: '0 0 125px',
    color: colors.grey1
  }),
  labelInvalid: css({
    color: colors.error
  }),
  value: css(clearButton, oneLiner, texts.body, {
    flex: 1,
    textAlign: 'left',
    padding: '0 5px 0 0',
    color: colors.black
  }),
  valueMultiLine: css({
    whiteSpace: 'pre-wrap'
  }),
  actions: css({
    alignItems: 'center',
    display: 'none',
    textAlign: 'right',
    '&.ef-show': {
      display: 'flex'
    },
    justifyContent: 'flex-end'
  }),
  inputWrapper: css(texts.body, {
    flex: 1,
    maxWidth: '700px'
  })
}

class EditableField extends React.Component {
  constructor (props) {
    super(props)

    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.onEdit = this.onEdit.bind(this)
    this.onSave = this.onSave.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)

    this.state = {
      isHover: false,
      isEditMode: false,
      value: props.value,
      originalValue: props.value
    }
  }

  handleClickOutside (e) {
    const { isEditMode } = this.state
    if (!isEditMode) {
      return
    }

    this.onSave()
  }

  onEdit (e) {
    const { onEdit, ignoreEditMode } = this.props
    if (!ignoreEditMode) {
      this.setState({ isEditMode: true })
    }
    onEdit(e)
  }

  onSave () {
    const { onSave, validate } = this.props
    const { value, originalValue } = this.state
    if (value && !validate(value)) {
      return
    }
    const result = !isEqual(originalValue, value) ? onSave(value) : ''
    Promise.resolve(result).then(() => this.setState({
      isEditMode: false,
      originalValue: value
    }))
  }

  onChange (e) {
    const { parse } = this.props
    const value = parse(get(e, ['currentTarget', 'value'], e))
    this.setState({ value })
  }

  onSubmit (e) {
    e.preventDefault()
    this.onSave()
  }

  componentDidUpdate (prevProps) {
    if (!isEqual(prevProps.value, this.props.value) && !isEqual(this.state.value, this.props.value)) {
      this.setState({
        value: this.props.value,
        originalValue: this.props.value
      })
    }
  }

  render () {
    const { disabled, historyButtonDisabled, label, onMouseEnter, onMouseLeave, onEdit, onRemove, onHistory, className, prefix, readonly, format, displayFormat, validate, input, overrideStyle, ignoreEditMode, showRemoveButton, showHistoryButton, emptyValue, children: Children, removable } = this.props
    const { isHover, isEditMode, value } = this.state
    const formattedValue = format(value)
    const isValid = !value || validate(value)
    const isValueNumber = isNumber(value)
    const isMultiline = (input === 'textarea')
    const finalText = ((formattedValue || isValueNumber) && prefix ? `${prefix}${displayFormat(formattedValue)}` : (formattedValue || isValueNumber) ? displayFormat(formattedValue) : emptyValue)

    return (
      <div {...CSS.main} className={className}>
        <form {...CSS.form} onSubmit={this.onSubmit}>
          <div
            className={[css(CSS.field, overrideStyle), isEditMode && !readonly ? 'edit' : '', 'ef-field'].join(' ')}
            onMouseEnter={e => { this.setState({ isHover: true }) || onMouseEnter(e) }}
            onMouseLeave={e => { this.setState({ isHover: false }) || onMouseLeave(e) }}>
            <div className={[css(CSS.label), 'ef-label'].join(' ')}>{label}</div>
            {isEditMode
              ? (
                <div {...CSS.inputWrapper}>
                  {prefix}
                  <Children {...{ onEdit, isEditMode, onSave: this.onSave, onChange: this.onChange, onRemove, value: formattedValue, isValid }} />
                </div>
              ) : (<button disabled={disabled} type='button' className={[css(CSS.value, isMultiline && CSS.valueMultiLine), 'value'].join(' ')} onClick={this.onEdit}>
                <OverflowTooltip
                  position='top'
                  label={finalText}>
                  <ToriiLinkify value={finalText} />
                </OverflowTooltip>
              </button>
              )
            }
            <div {...CSS.actions} className={[(!readonly && (isHover || isEditMode)) ? 'ef-show' : '', 'ef-actions'].join(' ')}>
              {showHistoryButton &&
                <Tooltip
                  placement='top'
                  hide={historyButtonDisabled}
                  label={<span>{`Show record history`}</span>}
                >
                  <Button type={ButtonType.tertiary} size={ButtonSize.small} htmlButtonType='button' disabled={historyButtonDisabled} onClick={onHistory} icon='History' />
                </Tooltip>}
              {(removable && (showRemoveButton || (!isEditMode && Boolean(value)))) &&
                <Tooltip
                  placement='top'
                  hide={disabled}
                  label={<span>{`Delete`}</span>}
                >
                  <Button type={ButtonType.tertiary} size={ButtonSize.small} disabled={disabled} onClick={onRemove} icon='Trash' />
                </Tooltip>}
              {!ignoreEditMode && !isEditMode &&
              <Tooltip
                placement='top'
                hide={disabled}
                label={<span>{`Edit`}</span>}
              >
                <Button type={ButtonType.tertiary} size={ButtonSize.small} disabled={disabled} onClick={this.onEdit} icon='Edit' />
              </Tooltip>}
              {!ignoreEditMode && isEditMode && <Button type={ButtonType.tertiary} size={ButtonSize.small} disabled={disabled || !isValid} onClick={this.onSave} icon='Check' />}
            </div>
          </div>
        </form>
      </div>
    )
  }
}

EditableField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array]),
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onHistory: PropTypes.func,
  format: PropTypes.func,
  displayFormat: PropTypes.func,
  parse: PropTypes.func,
  validate: PropTypes.func,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  input: PropTypes.oneOf(['input', 'textarea']),
  overrideStyle: PropTypes.object,
  ignoreEditMode: PropTypes.bool,
  showRemoveButton: PropTypes.bool,
  removable: PropTypes.bool,
  historyButtonDisabled: PropTypes.bool,
  emptyValue: PropTypes.string
}

const noop = () => {}
const identity = value => value
const truthy = value => true

EditableField.defaultProps = {
  readonly: false,
  disabled: false,
  value: '',
  prefix: '',
  onMouseEnter: noop,
  onMouseLeave: noop,
  onSave: noop,
  onEdit: noop,
  onRemove: noop,
  onHistory: noop,
  format: identity,
  displayFormat: identity,
  parse: identity,
  validate: truthy,
  input: 'input',
  ignoreEditMode: false,
  showRemoveButton: false,
  removable: true,
  showHistoryButton: false,
  historyButtonDisabled: false,
  emptyValue: '-'
}

export default onClickOutside(EditableField)
