import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Stack,
  Body2,
  H4,
  Link,
  AlertBox,
  AlertBoxType,
  AlertBoxLayout,
  Spacer
} from '@toriihq/design-system'
import { getLicensesTypes, toggleImportUsers } from '@store/actions'
import { getIdOrg } from '@selectors/org'
import AppAccountUsers from '@pages/licenseAudit/appAccountUsers'
import { noop, get } from 'lodash'
import Footer from '../wizardSteps/footer'
import Header from '../wizardSteps/header'
import { SharedProps, SharedState, WIZARD_STEPS } from '../wizardSteps/types'
import { RendererProps } from '@root/components/popups/wizardPopup/types'
import { IMPORT_TYPE } from '@root/store/selectors/importUsers/types'
import { AppAccountUsersWrapper, FullHeight, FullWidth } from './styles'
import { isLoadingLicensesTypes } from '@root/store/selectors/licenses'
import { getConnectedServicesByIdApp } from '@selectors/services'

const UserReview = ({
  sharedState, sharedProps, setState, navigateToStep, onCancel
}: RendererProps<SharedState, SharedProps, WIZARD_STEPS>): JSX.Element => {
  const { app, appName, idAppAccount, idApp, appAccount, licenses } = sharedProps
  const dispatch = useDispatch()
  const idOrg = useSelector(getIdOrg)
  const [hasLicenseData, setHasLicenseData] = useState(false)
  const [isLoadingLicenseData, setIsLoadingLicenseData] = useState(true)
  const isLoadingLicenses = useSelector(isLoadingLicensesTypes)

  useEffect(() => {
    setState({ ...sharedState, appAccountLicenses: licenses })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenses])

  const onImportUsersSuccess = () => {
    dispatch(getLicensesTypes({ idOrg, idApp }))
  }

  const connectedServices = get(useSelector(getConnectedServicesByIdApp), idApp, [])

  const onAdjustUserLicensesClick = () => {
    const { lastSyncTime } = appAccount
    const connectedServiceAccount = connectedServices.find(service => service.idAppAccount === idAppAccount)
    const importMode = connectedServiceAccount ? IMPORT_TYPE.LICENSES : IMPORT_TYPE.USERS
    dispatch(toggleImportUsers({
      isOpen: true,
      idApp,
      appName,
      importMode,
      isFirstTimeImport: !appAccount.licensesManagedManually,
      lastSyncTime,
      idAppAccount,
      appAccountName: appAccount.appAccountName,
      onSubmitSuccess: onImportUsersSuccess
    }))
  }

  const descriptionWithImportUsersLink = <Body2><Link onClick={onAdjustUserLicensesClick}>Import an updated user list</Link> to ensure data completeness if needed.</Body2>

  const onNextClick = () => {
    navigateToStep(WIZARD_STEPS.LICENSES_REVIEW)
    window.history.pushState({}, '', `/team/${idOrg}/app/${idApp}/account/${idAppAccount}/licenseAudit/${WIZARD_STEPS.LICENSES_REVIEW}`)
  }

  return (
    <FullHeight>
      <Stack direction={'column'} gap={'space-400'} alignItems={'start'}>
        <Header accountName={appAccount.appAccountName} app={app} currentStep={WIZARD_STEPS.USER_REVIEW} onBackClick={onCancel} />
        <FullWidth>
          <Stack direction={'column'} gap={'space-400'} alignItems={'start'} >
            <div style={{ width: '100%' }}>
              <Stack gap='space-100' direction={'column'} justifyContent={'start'}>
                <Stack gap={'space-100'}>
                  <H4>Review {appName} users</H4>
                  <Stack direction='column' gap='space-0'>
                    <Body2>Review {appName} users and make sure their licenses are updated before proceeding.</Body2>
                    {hasLicenseData
                      ? descriptionWithImportUsersLink
                      : isLoadingLicenseData ? null : <AlertBox type={AlertBoxType.NOTICE} layout={AlertBoxLayout.HORIZONTAL} title={'User list seems partial and license data is missing'} description={descriptionWithImportUsersLink} dismissible={false} onDismiss={noop} />
                    }
                  </Stack>
                </Stack>
              </Stack>
            </div>
            <FullWidth>
              <Stack direction='column' gap={'space-100'} alignItems={'start'}>
                <AppAccountUsersWrapper>
                  <AppAccountUsers
                    idApp={idApp}
                    idAppAccount={idAppAccount}
                    idOrg={idOrg}
                    setHasLicenseData={setHasLicenseData}
                    isLoadingLicenses={isLoadingLicenses}
                    setIsLoadingLicenseData={setIsLoadingLicenseData}
                  />
                </AppAccountUsersWrapper>
              </Stack>
            </FullWidth>
          </Stack>
        </FullWidth>
        <Spacer bottom='space-050' ><div /></Spacer>
      </Stack>
      <Footer
        shouldShowNextButton
        isNextButtonDisabled={!hasLicenseData}
        onNextClick={onNextClick}
        onCloseClick={onCancel}
      />
    </FullHeight>
  )
}

export default UserReview
