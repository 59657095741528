import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrencySymbol, getIdOrg } from '@selectors/org'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import DetailHistoryTable from '@components/detailHistoryTable'
import { EMPTY_ARRAY, EMPTY_OBJECT, TABLES } from '@root/constants'
import { getUserDetailsHistoryValues, toggleUserDetailHistoryPopup } from '@store/actions'
import { getUserPreferences } from '@selectors/ui'
import { getLazyLoadedUsersDetailsHistory, getLoadingFieldsHistory } from '@selectors/userDetails'

const CSS = {
  popup: {
    modal: {
      width: '700px',
      height: '548px',
      padding: '0px',
      display: 'flex',
      flexDirection: 'column'
    }
  }
}

const tableStyle = { minHeight: 250 }

const UserDetailHistoryPopup = () => {
  const dispatch = useDispatch()

  const idOrg = useSelector(getIdOrg)
  const { isOpen, idUser, detail } = useSelector(state => state.ui.userDetailHistoryPopup)
  const { defaultSort = EMPTY_ARRAY } = useSelector(getUserPreferences)[TABLES.userDetailHistoryPopupTable.key] || EMPTY_OBJECT
  const currencySymbol = useSelector(getCurrencySymbol)
  const loading = useSelector(getLoadingFieldsHistory)
  const usersHistory = useSelector(getLazyLoadedUsersDetailsHistory)

  const historyDetailsOfUser = usersHistory[idUser]
  const historyDetails = (historyDetailsOfUser?.[detail.idField]?.[detail.idGroup] || {})
  const { history = [], resources = {} } = historyDetails
  const usersMap = resources.users ?? {}

  useEffect(() => {
    dispatch(getUserDetailsHistoryValues({ idOrg, idUser, idUserField: detail.idField, idAppAccount: detail.idGroup }))
  }, [dispatch, idOrg, idUser, detail.idField, detail.idGroup])

  const close = () => {
    dispatch(toggleUserDetailHistoryPopup({ isOpen: false }))
  }

  return (
    <ToriiPopup
      isOpen={isOpen}
      onCloseAction={close}
      styles={CSS.popup}
    >
      <ToriiPopup.Header header={`History for: ${detail.name}`} />
      <ToriiPopup.Content>
        <DetailHistoryTable
          tableKey={TABLES.userDetailHistoryPopupTable.key}
          data={history}
          detail={detail}
          usersMap={usersMap}
          loading={loading}
          defaultSort={defaultSort}
          close={close}
          tableStyle={tableStyle}
          currencySymbol={currencySymbol}
          hideChangedByColumn
        />
      </ToriiPopup.Content>
    </ToriiPopup>
  )
}

export default UserDetailHistoryPopup
