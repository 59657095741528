import React from 'react'
import { setAppsDetails, toggleAppDetailHistoryPopup } from '@actions/'
import { useDispatch, useSelector } from 'react-redux'
import { getAppAndDetailsResources, getAppDetailsByIdApp, getAppDetailsGroups, isAppsLoaded } from '@selectors/apps'
import Details from '@components/details'
import { getIdOrg } from '@selectors/org'
import { useParamSelector } from '@shared/utils'

const APP_COMPLIANCE_GROUP_LABEL = 'certifications & compliance'

const AppDetailsList = (props) => {
  const dispatch = useDispatch()

  const { idApp, loading, disabled } = props
  const idOrg = useSelector(getIdOrg)

  const details = useParamSelector(getAppDetailsByIdApp, { idApp })
  const groups = useSelector(getAppDetailsGroups)
  const filteredGroups = groups.filter(group => !(group.label?.toLowerCase() === APP_COMPLIANCE_GROUP_LABEL && group.isPredefined))
  const loadingDetails = [{ idField: 1, idGroup: 1 }, { idField: 2, idGroup: 1 }, { idField: 3, idGroup: 1 }, { idField: 4, idGroup: 1 }, { idField: 5, idGroup: 1 }]
  const loadingGroup = [{ id: 1, label: '' }]
  const loadingAppDetails = loading && details.length === 0
  const usersById = useSelector(getAppAndDetailsResources).users
  const isAppLoaded = useSelector(isAppsLoaded)

  const onSave = (idField, value) => {
    const values = [].concat(value)
    dispatch(setAppsDetails({ idOrg, idApps: [idApp], fieldsValues: [{ idField, values }] }))
  }

  const onRemove = (idField) => {
    dispatch(setAppsDetails({ idOrg, idApps: [idApp], fieldsValues: [{ idField, values: [null] }] }))
  }

  const onHistory = (detail) => {
    const { idApp } = props
    dispatch(toggleAppDetailHistoryPopup({ isOpen: true, idApp, detail }))
  }

  return (
    <Details
      header='Application details'
      idApp={idApp}
      details={loadingAppDetails ? loadingDetails : details}
      groups={loadingAppDetails ? loadingGroup : filteredGroups}
      loading={loadingAppDetails && isAppLoaded}
      usersById={usersById}
      onSave={onSave}
      onRemove={onRemove}
      onHistory={onHistory}
      disabled={disabled}
      editable
      removable
    />
  )
}

export default AppDetailsList
