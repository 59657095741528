import styled from 'styled-components'

export const FullWidth = styled.div`
  padding: 0px 1px;
  width: 100%;
`

export const FullHeight = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const WithBorder = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  border-radius: ${({ theme }) => theme.corner('radius-100')};
  padding: 16px 24px;
  height: 100%;
`

export const FullHeightWidth = styled.div`
  height: 100%;
  width: 100%;
`
