import { IdUser } from '@store/types'

type IdSpecialUser = string // For example: `appOwner`

interface SpecialUserOption {
  value: IdSpecialUser
}

type MultipleUsersValue = Array<IdUser | IdSpecialUser | SpecialUserOption>

export const getKeyByUsersValues = (users: MultipleUsersValue): string => {
  const initialValueKey = 'initialValue'
  if (!users?.length) {
    return initialValueKey
  }

  return users
    .map(valueItem => (valueItem as SpecialUserOption).value ?? valueItem)
    .join('_')
}
