
import { zIndex } from '@shared/style/sizes'
import styled from 'styled-components'
import { ELEVATION_WEIGHT } from '@toriihq/design-system'

const DRAWER_WIDTH = '612px'

const drawerZIndex = zIndex.zendeskWidget + 1

const SMALL_SCREEN = 600

export const DrawerContainer = styled.div<{ isOpen?: boolean, windowWidth: number }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.background.primary};
  box-shadow: ${({ theme }) => theme.elevation(ELEVATION_WEIGHT.SHADOW_500)};
  height: 100%;
  width: ${({ windowWidth }) => windowWidth <= SMALL_SCREEN ? '100%' : DRAWER_WIDTH};
  position: fixed;
  top: 0;
  z-index: ${drawerZIndex};
  transition: right 250ms ease-in-out;
  ${({ isOpen }) => isOpen
    ? 'right: 0;'
    : `right: -${DRAWER_WIDTH};`
}
`

export const HeaderContainer = styled.div`
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
`

export const BodyContainer = styled.div`
  padding: 24px;
  height: 100%;
`
export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px;
  border-top: 1px solid ${({ theme }) => theme.palette.border.primary};
`

export const FullHeight = styled.div`
  height: 100%;
`

export const CloseButton = styled.div`
  cursor: pointer;
`
