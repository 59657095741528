import send from '../../shared/redux-fetch'
import {
  FORGOT,
  LOGIN,
  LOGOUT,
  RESET,
  GET_ME,
  GET_SAML_URL,
  SIGNUP,
  IS_LOGGED_IN,
  LOGIN_IS_USING_SAML,
  RESET_LOGOUT,
  TRIAL_SIGNUP
} from '../../constants'
import qs from 'query-string'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

export const forgot = data =>
  send(FORGOT, {
    url: '/api/login/forgot',
    method: 'POST',
    body: data
  })

export const login = data => {
  const query = qs.parse(window.location.search)
  return send(LOGIN, {
    url: '/api/login',
    method: 'POST',
    body: {
      ...data,
      nextUrl: query.next
    }
  })
}

export const isLoginUsingSAML = ({ email, captcha }) => {
  const query = qs.parse(window.location.search)
  return send(LOGIN_IS_USING_SAML, {
    url: '/api/login/isSAML',
    query: omitBy({
      email,
      captcha,
      nextUrl: query.next
    }, isUndefined),
    meta: {
      email
    }
  })
}

export const logout = () =>
  send(LOGOUT, {
    url: '/api/logout',
    method: 'DELETE'
  })

export const resetLogout = () => dispatch => {
  dispatch({
    type: RESET_LOGOUT
  })
}

export const reset = data =>
  send(RESET, {
    url: '/api/login/reset',
    method: 'POST',
    body: data
  })

export const getMe = () =>
  send(GET_ME, {
    url: '/api/users/my'
  })

export const getIsLoggedIn = () =>
  send(IS_LOGGED_IN, {
    url: '/api/login/isLoggedIn'
  })

export const getSAMLUrl = (email, nextUrl) => {
  let query = { email }
  query = nextUrl ? { ...query, nextUrl } : query
  return send(GET_SAML_URL, {
    url: '/api/saml/login',
    query
  })
}

export const signup = (email, next) =>
  send(SIGNUP, {
    url: '/api/signup/email_validation',
    method: 'POST',
    body: {
      email,
      next
    }
  })

export const trialSignup = (email, utmParams) =>
  send(TRIAL_SIGNUP, {
    url: '/api/signup/trial_email_validation',
    method: 'POST',
    body: {
      email,
      utmParams
    },
    meta: {
      email
    }
  })
