import { ReactElement } from 'react'
import { Button, ButtonSize, ButtonType, Stack, Tooltip } from '@toriihq/design-system'
import { ScheduledReportsActionsProps } from '@components/scheduledReportsActions/types'

const ScheduledReportsActions = (props: ScheduledReportsActionsProps): ReactElement => {
  const { handleDownloadAction, handleEditAction, handleDeleteAction } = props

  return (
    <Stack gap='space-050' direction='row' justifyContent='right'>
      <Tooltip label='Download'><Button type={ButtonType.tertiary} size={ButtonSize.small} icon='Download' onClick={handleDownloadAction} /></Tooltip>
      <Tooltip label='Edit'><Button type={ButtonType.tertiary} size={ButtonSize.small} icon='Edit' onClick={handleEditAction} /></Tooltip>
      <Tooltip label='Delete'><Button type={ButtonType.tertiary} size={ButtonSize.small} icon='Trash' onClick={handleDeleteAction} /></Tooltip>
    </Stack>
  )
}

export default ScheduledReportsActions
