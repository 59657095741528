import React from 'react'
import ToriiToggle from '@components/toriiToggle'
import moment from 'moment'
import capitalize from 'lodash/capitalize'
import { TextBadge, Tooltip } from '@toriihq/design-system'
import { DATE_FORMAT, EMPTY_OBJECT, SYNC_STATUS } from '@root/constants'
import LinkableAvatar from '@components/_shared/linkableAvatar'
import ScheduledReportsActions from '@components/scheduledReportsActions'
import { hoverActions } from '@shared/style/mixins'
import { css } from 'glamor'

export interface Column {
  Header?: string
  accessor?: string
  minWidth?: number
  Cell?: ({ value, original }: { value: any, original: any }) => any
  sortMethod?: (a: any, b: any) => any
  show?: boolean
  maxWidth?: number
  id?: string
  sortable?: boolean
  className?: string
}

export const getColumns = ({ usersMap = EMPTY_OBJECT, handleEnableToggle, handleDownloadAction, handleEditAction, handleDeleteAction, columnsConfiguration }) => [
  {
    Header: 'Report name',
    accessor: 'reportName',
    minWidth: 130
  },
  {
    Header: 'Frequency',
    accessor: 'scheduleDetails.scheduleFrequency',
    Cell: ({ value }) => {
      return (
        <div>
          {capitalize(value)}
        </div>
      )
    },
    sortMethod: (a, b) => a.scheduleFrequency.localeCompare(b.scheduleFrequency),
    show: Boolean(columnsConfiguration['scheduleDetails.scheduleFrequency'])
  },
  {
    Header: 'Last run',
    accessor: 'lastTimeSent',
    Cell: ({ value: date }) => date && moment.utc(date).fromNow(),
    show: Boolean(columnsConfiguration['lastTimeSent'])
  },
  {
    Header: 'Last status',
    accessor: 'status',
    Cell: ({ value: status, original }) => {
      if (!status) {
        return '-'
      }

      const { errorMessage } = original
      const statusMap: Record<string, { color: 'success' | 'error', text: string }> = {
        [SYNC_STATUS.FINISHED_SUCCESSFULLY]: { color: 'success', text: 'Completed' },
        [SYNC_STATUS.FINISHED_FAILED]: { color: 'error', text: 'Error' }
      }

      const { color = 'gray', text = 'In progress' } = statusMap[status] || {}

      return (
        errorMessage
          ? <Tooltip label={errorMessage ?? null}>
            <TextBadge color={color} size='Small'>{text}</TextBadge>
          </Tooltip>
          : <TextBadge color={color} size='Small'>{text}</TextBadge>
      )
    },
    show: Boolean(columnsConfiguration['status'])
  },
  {
    Header: 'Next schedule (UTC)',
    accessor: 'nextScheduledTime',
    Cell: ({ value: date }) => date && moment.utc(date).format('MMM D, YYYY, h:mmA'),
    show: Boolean(columnsConfiguration['nextScheduledTime'])
  },
  {
    Header: 'Created by',
    accessor: 'createdBy',
    Cell: ({ value: createdBy }) => {
      const user = usersMap[createdBy] || {}
      return <LinkableAvatar user={user} />
    },
    maxWidth: 130,
    show: Boolean(columnsConfiguration['createdBy'])
  },
  {
    Header: 'Created on',
    accessor: 'creationTime',
    Cell: ({ value: date }) => moment.utc(date).format(DATE_FORMAT),
    maxWidth: 250,
    show: Boolean(columnsConfiguration['creationTime'])
  },
  {
    Header: 'Last updated by',
    accessor: 'lastUpdatedBy',
    Cell: ({ value: lastUpdatedBy }) => {
      const user = usersMap[lastUpdatedBy] || {}
      return <LinkableAvatar user={user} />
    },
    maxWidth: 130,
    show: Boolean(columnsConfiguration['lastUpdatedBy'])
  },
  {
    Header: 'Last updated on',
    accessor: 'lastUpdatedOn',
    Cell: ({ value: date }) => moment.utc(date).format(DATE_FORMAT),
    maxWidth: 250,
    show: Boolean(columnsConfiguration['lastUpdatedOn'])
  },
  {
    Header: 'Active',
    accessor: 'isEnabled',
    Cell: ({ value, original }) => {
      const { isEnabled } = original
      return (
        <ToriiToggle
          id='isEnabledScheduledReport'
          checked={Boolean(value)}
          onToggle={() => handleEnableToggle({ scheduledReport: original, isEnabled: !isEnabled })}
        />
      )
    },
    maxWidth: 80,
    show: Boolean(columnsConfiguration['isEnabled'])
  },
  {
    accessor: 'id',
    show: false
  },
  {
    id: 'actions',
    Header: '',
    sortable: false,
    className: css(hoverActions).toString(),
    Cell: ({ original }) => {
      const { id: idReport } = original
      return (
        <ScheduledReportsActions
          handleDownloadAction={() => handleDownloadAction({ idReport })}
          handleEditAction={() => handleEditAction({ scheduledReport: original })}
          handleDeleteAction={() => handleDeleteAction({ scheduledReport: original })}
        />
      )
    }
  }
]
