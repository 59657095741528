import React, { ReactElement } from 'react'
import { TRIGGER_PAYLOAD_TEST_STATE } from '../types'
import { AlertBox, AlertBoxType, Link } from '@toriihq/design-system'
import { SUPPORT_ARTICLES } from '@root/articles'

interface Props {
  testState: TRIGGER_PAYLOAD_TEST_STATE | null
  testError: string | null
  testWarning: string | null
}

export const PayloadConfigAlertBox = ({
  testState,
  testError,
  testWarning
}: Props): ReactElement => {
  const shouldShowAlertBox = [
    TRIGGER_PAYLOAD_TEST_STATE.SUCCESS,
    TRIGGER_PAYLOAD_TEST_STATE.FAILURE
  ].some(state => state === testState)

  let type: AlertBoxType | undefined
  let title: string | undefined
  let description: ReactElement | string | undefined

  if (testState === TRIGGER_PAYLOAD_TEST_STATE.SUCCESS) {
    if (testWarning) {
      type = AlertBoxType.NOTICE
      title = 'An incoming request was received.'
      description = testWarning
    } else {
      type = AlertBoxType.POSITIVE
      title = 'Successfully received an incoming request.'
    }
  } else if (testState === TRIGGER_PAYLOAD_TEST_STATE.FAILURE) {
    type = AlertBoxType.NEGATIVE
    if (testError) {
      title = 'An error occurred while testing the trigger.'
      description = testError
    } else {
      title = 'No incoming requests received.'
      description = <Link
        target='_blank'
        href={SUPPORT_ARTICLES.CUSTOM_TRIGGER_WORKFLOW}
      >
        Learn how to configure Custom trigger.
      </Link>
    }
  }

  return <>{shouldShowAlertBox && <AlertBox
    type={type as AlertBoxType}
    title={title}
    description={description}
  />}</>
}
