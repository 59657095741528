import UserDetails from '@components/userDetails'
import { StatusDot, theme } from '@toriihq/design-system'
import { getFormattedDate } from '@lenses/utils'
import getColumnByFieldType from '@components/table/columnFieldTypes'
import React from 'react'
import Currency from '@components/currency'

const UTILIZATION_STATUS_CONFIG = {
  'active': {
    label: 'Active',
    color: theme.palette.chart.categorical10
  },
  'inactive': {
    label: 'Inactive',
    color: theme.palette.chart.categorical05
  }
}

export const getColumns = ({ tableInfo, appName, idLicense, license, appNotInUsePeriod }) => {
  const { columnsConfiguration, dynamicColumnsInfo } = tableInfo
  const UTILIZATION_STATUS_REASON_CONFIG = {
    'noUsage': `No usage in the last ${appNotInUsePeriod} days`,
    'noAccess': 'No access',
    'leftOrg': 'User has left the org',
    'leftOrgAndNoAccess': 'User has left the org'
  }
  return [
    {
      Header: 'Assigned to',
      id: 'fullName',
      accessor: ({ firstName, lastName, email }) => [firstName, lastName, email].join(' ').trim().toLowerCase(),
      minWidth: 220,
      Cell: ({ row: { firstName, lastName, idUser, email, isExternal, photoUrl, lifecycleStatus, isDeletedInIdentitySources } }) => (
        <UserDetails
          firstName={firstName}
          lastName={lastName}
          email={email}
          idUser={idUser}
          isExternal={isExternal}
          photoUrl={photoUrl}
          lifecycleStatus={lifecycleStatus}
          isDeletedInIdentitySources={isDeletedInIdentitySources}
          showPastUserBadge
        />
      )
    },
    {
      id: 'originEmail',
      Header: 'Email',
      accessor: 'originEmail',
      Cell: ({ row: { originEmail } }) => originEmail,
      show: Boolean(columnsConfiguration['originEmail'])
    },
    {
      Header: 'Utilization status',
      Cell: ({ row: { _original: appUser } }) => {
        const userLicense = appUser.licenses.find(({ id }) => id === idLicense)
        const { status } = userLicense?.calculatedStatus ?? {}
        const { label, color } = UTILIZATION_STATUS_CONFIG[status] || {}
        return <StatusDot label={label} color={color} />
      },
      sortable: false
    },
    {
      Header: 'Reason',
      accessor: 'reason',
      Cell: ({ row: { _original: appUser } }) => {
        const userLicense = appUser.licenses.find(({ id }) => id === idLicense)
        const { reason } = userLicense?.calculatedStatus ?? {}
        return UTILIZATION_STATUS_REASON_CONFIG[reason]
      },
      sortable: false
    },
    {
      Header: 'Annual cost per license',
      accessor: 'annualCostPerLicense',
      Cell: () => {
        return <Currency value={license.pricePerUserConverted} />
      }
    },
    {
      Header: 'Last used date',
      accessor: 'lastVisitTime',
      Cell: ({ value: lastVisitTime }) => getFormattedDate({ date: lastVisitTime }),
      minWidth: 200,
      show: Boolean(columnsConfiguration.lastVisitTime)
    },
    {
      Header: 'Recommendation',
      Cell: ({ row: { _original: appUser } }) => {
        const userLicense = appUser.licenses.find(({ id }) => id === idLicense)
        const { status } = userLicense?.calculatedStatus ?? {}
        return status === 'active' ? 'Keep license' : 'Remove license'
      }
    },
    {
      accessor: 'firstName',
      show: false
    },
    {
      accessor: 'lastName',
      show: false
    },
    {
      accessor: 'imageUrl',
      show: false
    },
    {
      accessor: 'idUser',
      show: false
    },
    {
      accessor: 'email',
      show: false
    },
    {
      accessor: 'isExternal',
      show: false
    },
    {
      accessor: 'photoUrl',
      show: false
    },
    {
      accessor: 'docId',
      show: false
    },
    {
      accessor: 'lifecycleStatus',
      show: false
    }
  ].concat(getColumnByFieldType({ columnsInfo: dynamicColumnsInfo, fieldIdentifier: 'key' }))
}
