import { SCHEDULE_FREQUENCY } from '@actions/reports/reports.types'

export const scheduleFrequencyOptions = [
  { label: 'Daily', value: SCHEDULE_FREQUENCY.DAILY },
  { label: 'Weekly', value: SCHEDULE_FREQUENCY.WEEKLY },
  { label: 'Monthly', value: SCHEDULE_FREQUENCY.MONTHLY }
]

export const daysOfWeekOptions = [
  { label: 'Sunday', value: '1' },
  { label: 'Monday', value: '2' },
  { label: 'Tuesday', value: '3' },
  { label: 'Wednesday', value: '4' },
  { label: 'Thursday', value: '5' },
  { label: 'Friday', value: '6' },
  { label: 'Saturday', value: '7' }
]

export const getDaysOfMonthOptions = (): { value: string, label: string }[] =>
  Array.from({ length: 28 }, (_, i) => i + 1).map(day => ({ value: String(day), label: String(day) }))
