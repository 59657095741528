import { useEffect, useRef } from 'react'

export const useCancelTestTriggerOnUnmount = (
  isInProgress: boolean,
  cancelTestTrigger: () => void
) => {
  const isInProgressRef = useRef(isInProgress)
  const cancelTestTriggerRef = useRef(cancelTestTrigger)

  useEffect(() => {
    isInProgressRef.current = isInProgress
  }, [isInProgress])

  useEffect(() => {
    cancelTestTriggerRef.current = cancelTestTrigger
  }, [cancelTestTrigger])

  useEffect(() => () => {
    if (isInProgressRef.current) {
      cancelTestTriggerRef.current()
    }
  }, [])
}
