import { createSelector } from 'reselect'
import identity from 'lodash/identity'
import get from 'lodash/get'
import { State } from '@reducers/types'

export const getScheduledReports = createSelector(
  (state: State) => get(state, ['scheduledReports', 'scheduledReports'], []),
  identity
)

export const getScheduledReportsResources = createSelector(
  (state: State) => get(state, ['scheduledReports', 'resources'], {}),
  identity
)

export const isLoadingScheduledReports = createSelector(
  (state: State) => get(state, ['scheduledReports', 'loading'], false),
  identity
)
