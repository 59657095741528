import React, { useEffect, useState } from 'react'
import Page from '../../components/page'
import { useDispatch, useSelector } from 'react-redux'
import { getAppAccountStatuses, getCurrentApp } from '@selectors/apps'
import { getAppV2 } from '@actions/apps'
import { getAppContracts, getLicensesTypes } from '@store/actions'
import { getLicensesTypes as getLicensesTypesSelector, isLoadingLicensesTypes } from '@selectors/licenses'
import { getCurrentOrg } from '@selectors/org'
import Wizard from '@root/components/popups/wizardPopup'
import { stepsConfig } from './wizardSteps/config'
import { useHistory } from 'react-router-dom'
import { WIZARD_STEPS } from './wizardSteps/types'

const parseUrlParams = (url: string): { idApp: number, idAppAccount: number, stepName: string } | null => {
  const regex = /\/team\/\d+\/app\/(\d+)\/account\/(\d+)\/licenseAudit\/(\w+)/
  const match = url.match(regex)

  if (match) {
    const [, idApp, idAppAccount, stepName] = match
    return { idApp: Number(idApp), idAppAccount: Number(idAppAccount), stepName }
  }

  return null
}

const LicenseAudit = (props) => {
  const dispatch = useDispatch()
  const { id: idOrg } = useSelector(getCurrentOrg)
  const parseResult = parseUrlParams(window.location.pathname)
  const { idApp, idAppAccount, stepName } = parseResult ?? { idApp: 1, idAppAccount: 1, stepName: '' }
  const { app = {}, loading: isCurrentAppLoading } = useSelector(getCurrentApp) ?? {}
  const { appAccounts: allAppAccounts = [], loading: isAppAccountLoading } = useSelector(getAppAccountStatuses)
  const appAccount = allAppAccounts.find((appAccount) => appAccount.idAppAccount === idAppAccount) || {}
  const { name: appName } = app
  const orgLicenses = useSelector(getLicensesTypesSelector)
  const isLoadingLicenses = useSelector(isLoadingLicensesTypes)
  const appLicenses = orgLicenses[idApp] || []
  const appAccountLicenses = appLicenses.filter(license => license.idAppAccount === idAppAccount)
  const history = useHistory()
  const firstStep = Object.entries(WIZARD_STEPS).find(([key, value]) => value === stepName)?.[0]
  const [hasAllDataLoaded, setIsAllDataLoaded] = useState(false)

  useEffect(() => {
    if ([isCurrentAppLoading, isAppAccountLoading, isLoadingLicenses].every(loading => !loading)) {
      setIsAllDataLoaded(true)
    }
  }, [isCurrentAppLoading, isAppAccountLoading, isLoadingLicenses])

  useEffect(() => {
    if (idApp) {
      dispatch(getAppV2({ idOrg, idApp }))
      dispatch(getLicensesTypes({ idOrg, idApp }))
      dispatch(getAppContracts({ idOrg, idApp }))
    }
  }, [dispatch, idApp, idOrg])

  const onClose = () => {
    history.push(`/team/${idOrg}/app/${idApp}`)
  }

  return (
    <Page title={'licenseAudit'} fillContainerHeight>
      {hasAllDataLoaded && <Wizard
        name='licenseAudit'
        initialSharedState={{
          initialAppAccountLicenses: appAccountLicenses,
          appAccountLicenses
        }}
        sharedProps={{
          idOrg,
          idApp,
          idAppAccount,
          appName,
          appAccount,
          licenses: appAccountLicenses,
          app
        }}
        onCancel={onClose}
        stepsConfig={stepsConfig()}
        startOnStep={firstStep ? WIZARD_STEPS[firstStep] : null}
      />}
    </Page>
  )
}

export default LicenseAudit
