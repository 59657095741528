import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getUser,
  deprecatedGetApps,
  getAppsOfUserFromServer,
  getUserOffboardingAuditLogs,
  updateUserLifecycleStatus,
  getOffboardingStatusOfUser,
  getUserDetailsFields,
  getUserDetails,
  toggleUserDetailHistoryPopup
} from '@actions/'
import { getLazyLoadedUsers, getLoadingLazyLoadedUsers, isUsersLoadingES } from '@selectors/users'
import { getUsedApps, isAppsLoading, shouldShowNewBadge } from '@selectors/apps'
import { getAppsOfUserES, isLoadingAppUsers } from '@selectors/appUsers'
import { isNew } from '@lenses/apps'
import { APP_USER_STATUS, USER_LIFECYCLE_STATUS } from '@shared/types'
import get from 'lodash/get'
import omit from 'lodash/omit'
import { getOffboardingUserStatus } from '@selectors/offboarding'
import { getLazyLoadedUsersDetails, getLoadingUserDetails, getUserDetailsFieldsSelector } from '@selectors/userDetails'
import keyBy from 'lodash/keyBy'
import AppDetails from '@components/appDetails'
import React from 'react'
import flatten from 'lodash/flatten'
import compact from 'lodash/compact'
import sortBy from 'lodash/sortBy'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@root/constants'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const idUser = parseInt(ownProps.match.params.idUser, 10)
  const users = getLazyLoadedUsers(state)
  const loading = getLoadingLazyLoadedUsers(state)
  const user = get(users, [idUser], {})
  const usersDetails = getLazyLoadedUsersDetails(state) ?? EMPTY_OBJECT
  const userDetails = get(usersDetails, [idUser], EMPTY_ARRAY)
  const startedByUser = get(user, ['offboardingStartedByUser'])

  const isOffboarding = user.lifecycleStatus === USER_LIFECYCLE_STATUS.OFFBOARDING
  const isOffboarded = user.lifecycleStatus === USER_LIFECYCLE_STATUS.OFFBOARDED
  const { apps, loadingApps } = getAppsByUserStatus(state, user, isOffboarding, isOffboarded)

  const userFields = getUserDetailsFieldsSelector(state)
  const userFieldsById = keyBy([...userFields], 'id')
  const usersById = userDetails?.resources?.users
  const appsById = userDetails?.resources?.apps
  const loadingDetails = getLoadingUserDetails(state) || userFields.length === 0

  const groups = sortBy(userDetails?.details?.map((appAccount, i) => {
    const { idApp } = appAccount
    const app = appsById[idApp]
    const name = `${app.name} - ${appAccount.appAccountName}`

    return {
      id: appAccount.idAppAccount,
      label: <AppDetails id={idApp} name={name} category={app.category} imageUrl={app.imageUrl} component='User Details' />,
      name,
      position: i,
      isReadOnly: true,
      isPredefined: true
    }
  }), 'name')

  const details = sortBy(flatten(compact(userDetails?.details?.map(appAccount => {
    return appAccount.details.map(detail => {
      const field = userFieldsById[detail.idUserField]

      if (!field) {
        return null
      }

      return {
        idGroup: appAccount.idAppAccount,
        idField: field.id,
        name: field.name,
        systemKey: field.key,
        options: field.options,
        values: [detail.value.join(', ')],
        type: field.type
      }
    })
  }))), 'name')

  return {
    idOrg,
    idUser,
    apps,
    user,
    startedByUser,
    loadingBasicInfo: loading && !user.id,
    loadingApps,
    loadingDetails,
    userFields,
    details,
    groups,
    usersById
  }
}

const mapDispatchToProps = {
  deprecatedGetApps,
  getAppsOfUserFromServer,
  updateUserLifecycleStatus,
  getUserOffboardingAuditLogs,
  getOffboardingStatusOfUser,
  getUser,
  getUserDetailsFields,
  getUserDetails,
  toggleUserDetailHistoryPopup
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { idOrg, idUser } = stateProps
  return {
    ...stateProps,
    ...omit(dispatchProps, ['updateUserLifecycleStatus']),
    updateUserLifecycleStatus: (lifecycleStatus) =>
      dispatchProps.updateUserLifecycleStatus({ idOrg, idUser, lifecycleStatus }),
    ...ownProps
  }
}

const isPrimaryOwner = (app, idUser) => {
  const isPrimaryOwner = app.owner === idUser || Number(app.primaryOwner) === idUser
  return isPrimaryOwner
}

const isOwner = (app, idUser) => {
  const isOwner = isPrimaryOwner(app, idUser) || app.appOwners?.some(idOwner => Number(idOwner) === idUser)
  return isOwner
}

const getAppsByUserStatus = (state, user, isOffboarding, isOffboarded) => {
  const loadingAppUsers = isLoadingAppUsers(state)
  const usersLoading = isUsersLoadingES(state)
  const appsLoading = isAppsLoading(state)

  if (isOffboarding || isOffboarded) {
    const offboardingUserStatus = getOffboardingUserStatus(state)

    return { apps: offboardingUserStatus.apps, loadingApps: offboardingUserStatus.loading }
  }

  const idUser = user && user.id
  const allAppsES = getAppsOfUserES(state) || {}
  const allAppsOfUser = allAppsES[idUser] || []
  const loadingApps = appsLoading || usersLoading || loadingAppUsers
  let appsOfUser = allAppsOfUser
    .map(appUser => {
      return {
        ...appUser,
        isNew: shouldShowNewBadge(state) && isNew(appUser),
        isOwner: user?.allIdUsers?.some(idUser => isOwner(appUser, idUser)),
        isPrimaryOwner: user?.allIdUsers?.some(idUser => isPrimaryOwner(appUser, idUser)),
        score: appUser.score || 0,
        sources: appUser.sources || [],
        lastVisitTime: appUser.lastVisitTime,
        id: `${appUser.idApp}_${appUser.idAppAccount}`
      }
    })

  const allApps = getUsedApps(state)
  appsOfUser = appsOfUser.filter(appUser => appUser.status === APP_USER_STATUS.ACTIVE)
  const additionalOwnedApps = allApps.filter(app => user.allIdUsers?.some(idUser => isOwner(app, idUser)) && !appsOfUser.find(appUser => appUser.idApp === app.id))
  const cleandAdditionalOwnedApps = additionalOwnedApps.map(app => ({
    ...app,
    score: 0,
    isOwner: true,
    isPrimaryOwner: user.allIdUsers?.some(idUser => isPrimaryOwner(app, idUser)),
    sources: [],
    idApp: app.id,
    isNew: isNew(app),
    originEmail: user.allUsers?.find(({ idUser }) => idUser === user.allIdUsers?.find(idUser => isOwner(app, idUser)))?.originEmail ?? null,
    lastVisitTime: null
  }))
  appsOfUser = appsOfUser.concat(cleandAdditionalOwnedApps)

  return { apps: appsOfUser, loadingApps }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
