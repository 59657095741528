import moment from 'moment'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import ReactJson from 'react-json-view'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  Body2,
  Button,
  ButtonState,
  Caption1,
  Divider,
  ExpandableSection,
  Stack,
  Subtitle2,
  Tooltip
} from '@toriihq/design-system'
import {
  getWorkflow,
  getWorkflowTriggerConfigurationField
} from '@actions/workflows'
import EnableFor from '@components/enableFor'
import { getFormattedDate } from '@lenses/utils'
import { SCOPES } from '@root/constants'
import { usePolling } from '@shared/hooks/usePolling'
import { useWorkflowEditorContext } from '@pages/workflow_v2/workflowEditor/context'
import { getTestState } from './utils/getTestState'
import { useCancelTestTriggerOnUnmount } from './utils/useCancelTestTriggerOnUnmount'
import { Styles } from './styles'
import { InputValue, TRIGGER_PAYLOAD_TEST_STATE } from './types'
import { getSelf } from '@selectors/me'
import { useSelectedWorkflow } from '@pages/workflow_v2/hooks/useSelectedWorkflow'
import { PayloadConfigAlertBox } from './components/payloadConfigAlertBox'
import Analytics from '@helpers/analytics'

interface Props {
  field: { id: string }
  input: {
    onChange: (data: InputValue) => void
    value: InputValue
    disabled?: boolean
  }
}

export const CustomWebhookPayloadConfig = ({
  field,
  input
}: Props): ReactElement => {
  const { id: idField } = field
  const {
    value,
    disabled,
    onChange
  } = input

  const {
    testStartTime,
    testPayloadReceivedTime,
    payload,
    testError,
    testWarning
  } = value ?? {}

  const { isActive } = useSelectedWorkflow()

  const dispatch = useDispatch()

  const currentUser = useSelector(getSelf)

  const [testState, setTestState] = useState<TRIGGER_PAYLOAD_TEST_STATE>()

  const [isPayloadVisible, setIsPayloadVisible] = useState(false)

  const {
    isAppCatalogPoliciesView,
    isRequestNewAppPoliciesView
  } = useWorkflowEditorContext()

  const { idOrg, idWorkflow: idWorkflowString } = useParams()
  const idWorkflow = Number(idWorkflowString)

  useEffect(() => {
    const setTestStateFromValueData = () => {
      const newTestState = getTestState({
        testStartTime,
        testPayloadReceivedTime,
        payload,
        testError
      })
      newTestState !== testState && setTestState(newTestState)
    }

    setTestStateFromValueData()
    const intervalId = setInterval(setTestStateFromValueData, 1000)

    return () => clearInterval(intervalId)
  }, [testStartTime, testPayloadReceivedTime, payload, testError, testState])

  const isInProgress = testState === TRIGGER_PAYLOAD_TEST_STATE.IN_PROGRESS

  const fetchUpdatedWorkflowConfiguration = useCallback(async () => {
    const updatedField = await dispatch(getWorkflowTriggerConfigurationField({
      idOrg,
      idWorkflow,
      isAppCatalogPoliciesView,
      isRequestNewAppPoliciesView,
      idField
    })) as { value: InputValue } | null

    const value = updatedField?.value

    const isNewPayloadConfigReceived =
      value?.testStartTime &&
      value?.testPayloadReceivedTime &&
      moment(value.testStartTime).utc().isBefore(moment(value.testPayloadReceivedTime).utc())

    if (isNewPayloadConfigReceived) {
      setIsPayloadVisible(true)

      // After a new payload is received, some actions might become invalid because they use personalization tokens
      // from the old payload configuration that no longer exist so we need to fetch the updated `actions` and `isValid`:
      dispatch(getWorkflow({
        idOrg,
        idWorkflow,
        isAppCatalogPoliciesView,
        isRequestNewAppPoliciesView,
        fields: ['actions', 'isValid']
      }))
    }
  }, [dispatch, idOrg, idWorkflow, isAppCatalogPoliciesView, isRequestNewAppPoliciesView, idField])

  usePolling({
    shouldPoll: isInProgress,
    pollingFunction: fetchUpdatedWorkflowConfiguration
  })

  const formattedTestResultReceivedTime = testPayloadReceivedTime && getFormattedDate({
    date: testPayloadReceivedTime,
    includeTime: true
  })

  const handleTestTrigger = () => {
    onChange({
      ...value,
      testStartTime: moment().utc().toISOString(),
      testStartedBy: currentUser.id,
      testError: null
    })
    Analytics.track('Click on test-button')
  }

  const handleCancelTestTrigger = () => {
    onChange({
      ...value,
      testStartTime: null,
      testStartedBy: null
    })
    Analytics.track('Click on cancel-button')
  }

  const togglePayloadVisibility = () => {
    Analytics.track('Click on test-button', {
      'State': isPayloadVisible ? 'Close' : 'Open'
    })
    setIsPayloadVisible(!isPayloadVisible)
  }

  useCancelTestTriggerOnUnmount(
    isInProgress,
    handleCancelTestTrigger
  )

  return (
    <Stack
      direction='column'
      gap='space-300'
    >
      <Divider orientation='Vertical' />
      <Stack
        direction='column'
        gap='space-100'
      >
        <Subtitle2>
          Test this trigger
        </Subtitle2>
        <Body2 color='secondary'>
          Torii is listening for incoming requests to confirm the trigger configuration is correct.
          <br />
          The data from this test can be used in actions.
        </Body2>
      </Stack>
      <Stack
        direction='row'
        alignItems='center'
        gap='space-100'
      >
        <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
          <Tooltip label='The workflow is live. Turn it off to perform a test' hide={!isActive}>
            <Button
              htmlButtonType='button'
              onClick={handleTestTrigger}
              label='Test trigger'
              buttonState={isInProgress ? ButtonState.loading : ButtonState.neutral}
              disabled={disabled || Boolean(isActive)}
            />
          </Tooltip>
        </EnableFor>
        {isInProgress && <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
          <Button
            htmlButtonType='button'
            onClick={handleCancelTestTrigger}
            type='tertiary'
            label='Cancel'
            disabled={disabled}
          />
        </EnableFor>}
      </Stack>
      <PayloadConfigAlertBox
        testState={testState ?? null}
        testError={testError}
        testWarning={testWarning}
      />
      {payload && <Stack
        direction='column'
        gap='space-100'
      >
        <Subtitle2>
          Request results
        </Subtitle2>
        <Body2 color='secondary'>
          Use the data from the test to configure actions in this workflow.
        </Body2>
        <Caption1 color='tertiary'>
          Webhook received at {formattedTestResultReceivedTime} UTC
        </Caption1>
        <Styles.PayloadSectionContainer>
          <ExpandableSection
            title='Raw JSON'
            isOpen={isPayloadVisible}
            onToggle={togglePayloadVisibility}
          >
            <ReactJson
              src={payload}
              name={false}
              displayDataTypes={false}
              displayObjectSize={false}
            />
          </ExpandableSection>
        </Styles.PayloadSectionContainer>
      </Stack>}
    </Stack>
  )
}
