import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import Select from '../select'
import colors from '../../shared/style/colors'
import ToriiPopup from '../popups/ToriiPopupV2'
import Analytics from '../../helpers/analytics'
import { SHARE_STATUS } from '../../constants'
import AddOrEditForm from './addOrEditForm'
import validate from './addOrEditForm/validation'
import { Button, ButtonSize, ButtonType } from '@toriihq/design-system'

const CSS = {
  optionContainer: css({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      ' .actionsContainer': {
        opacity: 1
      }
    }
  }),
  optionLabel: css({
    width: '70%',
    color: colors.black
  }),
  addNewOptionLabel: css({
    width: '100%',
    color: colors.black
  }),
  actionsContainer: css({
    opacity: 0,
    display: 'flex',
    alignItems: 'center',
    gap: 4
  })
}

export const POPUP_MODES = {
  add: 'add',
  edit: 'edit',
  delete: 'delete'
}

class EditableSelect extends React.Component {
  state = {
    isPopupOpen: false,
    option: null,
    popupMode: null
  }

  onOptionDeleted = (option) => {
    const { onOptionDeleted, analyticsProps, name } = this.props

    Analytics.track(`Deleted ${name}`, analyticsProps)
    this.setState({ isPopupOpen: false })
    onOptionDeleted(option)
  }

  renderDeletePopup () {
    const { name } = this.props
    const { isPopupOpen, popupMode, option } = this.state

    if (!option) {
      return null
    }

    return (<ToriiPopup isOpen={isPopupOpen && popupMode === POPUP_MODES.delete} onCloseAction={() => this.setState({ isPopupOpen: false })}>
      <ToriiPopup.Header header={`Delete "${option.label}" ${name}?`} />
      <ToriiPopup.Content>
        {`By clicking "Delete", you will delete the ${name}`}
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        mainButtonAction={() => this.onOptionDeleted(option)}
        mainButtonText='Delete'
        cancelButtonText='Cancel'
        mainButtonType={ButtonType.destructive}
      />
    </ToriiPopup>)
  }

  onAddOrEditPopupSubmit = ({ label, properties, shareStatus }) => {
    const { onOptionAdded, onOptionEdited, analyticsProps, name } = this.props
    const { option, popupMode } = this.state

    this.setState({ isPopupOpen: false, option: null })
    if (popupMode === POPUP_MODES.add) {
      Analytics.track(`Created ${name}`, analyticsProps)
      onOptionAdded({ label, properties, shareStatus })
    } else {
      Analytics.track(`Edited ${name}`, analyticsProps)
      onOptionEdited({ value: option.value, newLabel: label, shareStatus })
    }
  }

  renderAddOrEditPopup () {
    const { name, renderAddOrEditPopup, options } = this.props
    const { isPopupOpen, option, popupMode } = this.state

    if (!isPopupOpen || (popupMode !== POPUP_MODES.add && popupMode !== POPUP_MODES.edit)) {
      return null
    }

    const cancel = () => this.setState({ isPopupOpen: false, option: null })

    if (renderAddOrEditPopup) {
      return renderAddOrEditPopup({ popupMode, option, name, onSubmit: this.onAddOrEditPopupSubmit, cancel, allOptions: options })
    }

    const initialValues = {
      value: popupMode === POPUP_MODES.edit ? option.label : null,
      shareStatus: popupMode === POPUP_MODES.edit ? option.shareStatus : SHARE_STATUS.notShared,
      id: popupMode === POPUP_MODES.edit ? option.id : null
    }

    return <ToriiPopup
      isOpen
      onCloseAction={cancel}
    >
      <ToriiPopup.Header header={popupMode === POPUP_MODES.add ? `Add new ${name}` : `Edit "${option.label}" view`} />
      <ToriiPopup.Form
        initialValues={initialValues}
        validate={validate}
        render={(formProps) => (
          <AddOrEditForm
            name={name}
            option={option}
            allOptions={options}
            popupMode={popupMode}
            cancel={cancel}
            onAddOrEditPopupSubmit={this.onAddOrEditPopupSubmit}
            {...formProps} />
        )}
        renderFooter={(formProps) => (
          <ToriiPopup.Footer
            cancelButtonText={'Cancel'}
            mainButtonText={popupMode === POPUP_MODES.add ? `Create new ${name}` : `Update ${name}`}
            isMainSubmit
            formProps={formProps}
            isMainButtonDisabled={!formProps.valid}
          />
        )}
      />

    </ToriiPopup>
  }

  onChange = (selectedOption) => {
    const { analyticsProps, name, onChange } = this.props
    Analytics.track(`Switched ${name}`, {
      ...analyticsProps,
      'Selected option': selectedOption?.label ?? 'None'
    })
    onChange(selectedOption)
  }

  openAddPopup = () => {
    const { analyticsProps, name } = this.props
    Analytics.track(`Clicked to create ${name}`, analyticsProps)
    this.setState({ isPopupOpen: true, popupMode: POPUP_MODES.add })
  }

  optionRenderer = ({ data: option }) => {
    const { options, optionRenderer, analyticsProps, name, editable } = this.props

    const onEditClicked = e => {
      e.stopPropagation()
      Analytics.track(`Clicked to edit ${name}`, analyticsProps)
      this.setState({ option, isPopupOpen: true, popupMode: POPUP_MODES.edit })
    }
    const onDeleteClicked = e => {
      e.stopPropagation()
      Analytics.track(`Clicked to delete ${name}`, analyticsProps)
      this.setState({ option, isPopupOpen: true, popupMode: POPUP_MODES.delete })
    }

    return <div {...CSS.optionContainer}>
      <div {...CSS.optionLabel}>
        {(optionRenderer && optionRenderer(option)) || option.label}
      </div>
      {editable && <div {...CSS.actionsContainer} className='actionsContainer'>
        <Button type={ButtonType.compact} size={ButtonSize.small} onClick={onDeleteClicked} disabled={option.actionsDisabled || options.length === 1} icon='Trash' />
        <Button type={ButtonType.compact} size={ButtonSize.small} onClick={onEditClicked} disabled={option.actionsDisabled} icon='Edit' />
      </div>}
    </div>
  }

  render () {
    const { options, selectedOption, valueRenderer, disabled = false, editable } = this.props

    return (
      <div>
        {this.renderDeletePopup()}
        {this.renderAddOrEditPopup()}

        <Select
          options={options}
          optionRenderer={this.optionRenderer}
          valueRenderer={({ data: option }) => (valueRenderer && valueRenderer(option)) || option.label}
          value={selectedOption}
          onChange={this.onChange}
          clearable={false}
          key={`editable_select`}
          name={`editable_select`}
          openOnFocus
          disabled={disabled}
          selectWidth={210}
          menuWidth={300}
          customMenuOption={editable ? <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }} onClick={this.openAddPopup}><span>Create new view</span><Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.openAddPopup} icon='Plus' /></div> : null}
        />
      </div>
    )
  }
}

EditableSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
    actionDisabled: PropTypes.bool
  })),
  name: PropTypes.string.isRequired,
  selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showChooseTemplate: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onOptionEdited: PropTypes.func.isRequired,
  onOptionDeleted: PropTypes.func.isRequired,
  onOptionAdded: PropTypes.func.isRequired,
  renderAddOrEditPopup: PropTypes.func,
  optionRenderer: PropTypes.func.isRequired,
  valueRenderer: PropTypes.func,
  analyticsProps: PropTypes.object,
  editable: PropTypes.bool
}

EditableSelect.defaultProps = {
  analyticsProps: {},
  editable: true
}

export default EditableSelect
