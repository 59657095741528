import { RendererProps } from '@root/components/popups/wizardPopup/types'
import { WIZARD_STEPS, SharedProps, SharedState } from '../types'
import ToriiPopup from '@root/components/popups/ToriiPopupV2'
import { useDispatch } from 'react-redux'
import { toggleImportUsers } from '@actions/'
import { IMPORT_TYPE } from '@selectors/importUsers/types'

const Failure = ({
  sharedState, sharedProps
}: RendererProps<SharedState, SharedProps, WIZARD_STEPS>): JSX.Element => {
  const dispatch = useDispatch()

  const closePopup = () => {
    dispatch(toggleImportUsers({ isOpen: false }))
  }

  const header = sharedProps.importMode === IMPORT_TYPE.USERS ? 'Import Users Failed' : 'License Adjustment Failed'

  return (
    <>
      <ToriiPopup.Header header={header} subHeader={sharedProps.appAccountName} />
      <ToriiPopup.Content>
        {sharedState.failureMessage ?? 'Something went wrong during the import. Please try again later.'}
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        mainButtonText='Close'
        mainButtonAction={closePopup}
        showCancelButton={false}
      />
    </>
  )
}

export default Failure
