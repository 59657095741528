import React, { ReactElement } from 'react'
import { formFieldLabelActionTypes } from '@root/constants'
import SendFormPreviewButton from '@components/workflows/sendFormPreviewButton'
import LicenseInfoTooltip from '@root/components/licenses/licenseInfoTooltip'
import get from 'lodash/get'
import { ActionWithIdNode } from '../../types'

interface LabelComponent {
  component: ReactElement
}

interface LabelActionProps {
  action?: ActionWithIdNode
  field?: any
}

export const labelActionMapping: Record<string, { component: (props: LabelActionProps) => LabelComponent }> = {
  [formFieldLabelActionTypes.previewSendForm]: {
    component: ({ action }) => {
      return {
        component: <SendFormPreviewButton action={action} />
      }
    }
  },
  [formFieldLabelActionTypes.licenseInfoTooltip]: {
    component: ({ field }) => {
      const idApp = get(field, ['value', 'idApp'], null)
      return { component: <LicenseInfoTooltip idApp={idApp} /> }
    }
  }
}
