interface License {
  id: number
  name: string
  totalAmount: number
  currency: string
  pricePerUser: number
  totalAmountEnteredByUser: number,
  isPaid: boolean
}

export interface SharedState {
  initialAppAccountLicenses: License[]
  appAccountLicenses: License[]
}
export interface SharedProps {
  idOrg: number,
  idApp: number
  idAppAccount: number
  appName: string
  licenses: License[]
  appAccount: { lastSyncTime: string, appAccountName: string, licensesManagedManually: boolean }
  app: { name: string, imageUrl: string, isCustom: boolean }
}

export enum WIZARD_STEPS {
  USER_REVIEW = 'userReview',
  LICENSES_REVIEW = 'licensesReview',
  RECOMMENDATIONS = 'recommendations',
}
