import {
  DELETE_CONTRACTS,
  GET_CONTRACTS,
  GET_UNMATCHED_CONTRACTS,
  GET_CONTRACTS_FIELDS,
  UPDATE_CONTRACT_DETAILS,
  UPDATE_CONTRACTS_DETAILS,
  GET_CONTRACTS_GROUPS,
  GET_CONTRACT,
  CREATE_CONTRACT,
  GET_CONTRACT_DETAIL_FIELD_HISTORY,
  GET_APP_CONTRACTS,
  ADD_CONTRACT_DETAILS_GROUPS,
  EDIT_CONTRACT_DETAILS_GROUPS,
  DELETE_CONTRACT_DETAILS_GROUPS,
  DELETE_CONTRACT_DETAILS_FIELD,
  UPDATE_CONTRACT_DETAILS_FIELD,
  ADD_CONTRACT_DETAILS_FIELD,
  REORDER_CONTRACT_DETAILS_GROUPS,
  REORDER_CONTRACT_DETAILS_FIELDS,
  GET_CONTRACT_DETAILS_FROM_AI,
  SET_DONE_CONTRACTS_APP_MATCHING,
  GET_CONTRACT_ATTACHMENTS
} from '@root/constants'
import uniqBy from 'lodash/uniqBy'
import keyBy from 'lodash/keyBy'
import { replaceOneItem, updateOneItem } from '@lenses/utils'
import { GET_RENEWALS_SUMMARY } from '@root/constants.t'

const initialState = {
  contracts: {
    loadingSingleContract: false,
    loading: false,
    contracts: [],
    resources: { apps: {}, users: {}, contracts: {} }
  },
  unmatchedContracts: {
    loadingSingleContract: false,
    loading: false,
    contracts: [],
    resources: { apps: {}, users: {}, contracts: {} }
  },
  fields: {
    loading: false,
    fields: []
  },
  groups: {
    loading: false,
    groups: []
  },
  renewalsSummary: {
    loading: false,
    data: {}
  },
  fieldsHistory: {},
  contractAttachments: {
    loading: false,
    data: []
  }
}

const contractsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_CONTRACTS}_PENDING`: {
      return {
        ...state,
        contracts: {
          ...state.contracts,
          loading: true
        }
      }
    }
    case `${GET_CONTRACTS}_FAILED`: {
      return {
        ...state,
        contracts: {
          ...state.contracts,
          loading: false
        }
      }
    }
    case `${GET_CONTRACTS}_RESPONSE`: {
      const { contracts, resources } = action.payload
      return {
        ...state,
        contracts: {
          loading: false,
          contracts,
          resources
        }
      }
    }
    case `${GET_CONTRACT}_PENDING`: {
      return {
        ...state,
        contracts: {
          ...state.contracts,
          loadingSingleContract: true
        }
      }
    }
    case `${GET_CONTRACT}_FAILED`: {
      return {
        ...state,
        contracts: {
          ...state.contracts,
          loadingSingleContract: false
        }
      }
    }
    case `${GET_CONTRACT}_RESPONSE`: {
      const { contracts, resources } = action.payload

      const mergedContracts = mergeById(contracts, state.contracts.contracts)
      const updatedResources = getUpdatedResources(resources, state.contracts.resources)

      return {
        ...state,
        contracts: {
          ...state.contracts,
          loadingSingleContract: false,
          contracts: mergedContracts,
          resources: updatedResources
        }
      }
    }
    case `${GET_APP_CONTRACTS}_PENDING`: {
      return {
        ...state,
        contracts: {
          ...state.contracts,
          loadingAppContract: true
        }
      }
    }
    case `${GET_APP_CONTRACTS}_FAILED`: {
      return {
        ...state,
        contracts: {
          ...state.contracts,
          loadingAppContract: false
        }
      }
    }
    case `${GET_APP_CONTRACTS}_RESPONSE`: {
      const { contracts, resources } = action.payload
      const mergedContracts = mergeById(contracts, state.contracts.contracts)
      const updatedResources = getUpdatedResources(resources, state.contracts.resources)

      return {
        ...state,
        contracts: {
          ...state.contracts,
          loadingAppContract: false,
          contracts: mergedContracts,
          resources: updatedResources
        }
      }
    }
    case `${GET_CONTRACTS_FIELDS}_PENDING`: {
      return {
        ...state,
        fields: {
          ...state.fields,
          loading: true
        }
      }
    }
    case `${GET_CONTRACTS_FIELDS}_FAILED`: {
      return {
        ...state,
        fields: {
          ...state.fields,
          loading: false
        }
      }
    }
    case `${GET_CONTRACTS_FIELDS}_RESPONSE`: {
      const { fields } = action.payload
      return {
        ...state,
        fields: {
          loading: false,
          fields
        }
      }
    }
    case `${GET_UNMATCHED_CONTRACTS}_PENDING`: {
      return {
        ...state,
        unmatchedContracts: {
          ...state.unmatchedContracts,
          loading: true
        }
      }
    }
    case `${GET_UNMATCHED_CONTRACTS}_FAILED`: {
      return {
        ...state,
        unmatchedContracts: {
          ...state.unmatchedContracts,
          loading: false
        }
      }
    }
    case `${GET_UNMATCHED_CONTRACTS}_RESPONSE`: {
      const { contracts, resources } = action.payload
      return {
        ...state,
        unmatchedContracts: {
          loading: false,
          contracts,
          resources
        }
      }
    }
    case `${UPDATE_CONTRACTS_DETAILS}_PENDING`:
    case `${UPDATE_CONTRACT_DETAILS}_PENDING`: {
      const { idContract, idContracts, details } = action.meta
      const { contracts } = state.contracts
      const idContractsArray = idContracts || [idContract]
      const updatedContracts = contracts.map(contract => {
        if (idContractsArray.includes(contract.id)) {
          return { ...contract, ...details }
        } else {
          return contract
        }
      })
      return {
        ...state,
        contracts: {
          ...state.contracts,
          contracts: updatedContracts
        }
      }
    }
    case `${UPDATE_CONTRACTS_DETAILS}_RESPONSE`:
    case `${UPDATE_CONTRACT_DETAILS}_RESPONSE`: {
      const { resources, contracts: updatedContracts } = action.payload
      const updatedContractsById = keyBy(updatedContracts, 'id')
      const { contracts } = state.contracts
      const updatedContractsState = contracts.map(contract => {
        if (updatedContractsById[contract.id]) {
          return { ...contract, ...updatedContractsById[contract.id] }
        }
        return contract
      })

      const updatedResources = getUpdatedResources(resources, state.contracts.resources)

      return {
        ...state,
        contracts: {
          ...state.contracts,
          contracts: updatedContractsState,
          resources: updatedResources
        }
      }
    }
    case `${CREATE_CONTRACT}_RESPONSE`: {
      const { id, resources } = action.payload
      const { contractToUpdateStore } = action.meta

      const updatedContracts = [...state.contracts.contracts, { id, ...contractToUpdateStore }]
      const updatedResources = getUpdatedResources(resources, state.contracts.resources)

      return {
        ...state,
        contracts: {
          ...state.contracts,
          contracts: updatedContracts,
          resources: updatedResources
        }
      }
    }
    case `${GET_CONTRACTS_GROUPS}_PENDING`: {
      return {
        ...state,
        groups: {
          ...state.groups,
          loading: true
        }
      }
    }
    case `${GET_CONTRACTS_GROUPS}_RESPONSE`: {
      const { groups } = action.payload
      return {
        ...state,
        groups: {
          groups,
          loading: false
        }
      }
    }
    case `${GET_CONTRACTS_GROUPS}_FAILED`: {
      return {
        ...state,
        groups: {
          ...state.groups,
          loading: false
        }
      }
    }
    case `${REORDER_CONTRACT_DETAILS_GROUPS}_PENDING`: {
      const { reorderedGroups } = action.meta
      return {
        ...state,
        groups: {
          groups: reorderedGroups,
          loading: state.loading
        }
      }
    }
    case `${REORDER_CONTRACT_DETAILS_GROUPS}_RESPONSE`: {
      const { groups } = action.payload
      return {
        ...state,
        groups: {
          groups,
          loading: state.loading
        }
      }
    }
    case `${REORDER_CONTRACT_DETAILS_FIELDS}_PENDING`: {
      const { reorderedData } = action.meta

      const otherFields = state.fields.fields.filter(field => field.idGroup !== reorderedData[0].idGroup)

      return {
        ...state,
        fields: {
          ...state.fields,
          fields: [...reorderedData, ...otherFields]
        }
      }
    }
    case `${REORDER_CONTRACT_DETAILS_FIELDS}_FAILED`: {
      const { previousData, idGroup } = action.meta

      const otherFields = state.fields.fields.filter(field => field.idGroup !== idGroup)

      return {
        ...state,
        fields: {
          ...state.fields,
          fields: [...previousData, ...otherFields]
        }
      }
    }

    case `${DELETE_CONTRACTS}_RESPONSE`: {
      const { idContracts } = action.meta
      const { contracts } = state.contracts
      const { contracts: unmatchedContracts } = state.unmatchedContracts
      const updatedContracts = contracts.filter(contract => !idContracts.includes(contract.id))
      const updatedUnmatchedContracts = unmatchedContracts.filter(contract => !idContracts.includes(contract.id))

      return {
        ...state,
        contracts: {
          ...state.contracts,
          contracts: updatedContracts
        },
        unmatchedContracts: {
          ...state.unmatchedContracts,
          contracts: updatedUnmatchedContracts
        }
      }
    }

    case `${GET_CONTRACT_DETAIL_FIELD_HISTORY}_PENDING`: {
      return {
        ...state,
        loadingFieldsHistory: true
      }
    }
    case `${GET_CONTRACT_DETAIL_FIELD_HISTORY}_FAILED`: {
      return {
        ...state,
        loadingFieldsHistory: false
      }
    }
    case `${GET_CONTRACT_DETAIL_FIELD_HISTORY}_RESPONSE`: {
      const { history, resources } = action.payload
      const { systemKey, idContract } = action.meta

      return {
        ...state,
        fieldsHistory: {
          ...state.fieldsHistory,
          [idContract]: {
            ...state.fieldsHistory[idContract],
            [systemKey]: history,
            resources
          }
        }
      }
    }

    case `${ADD_CONTRACT_DETAILS_GROUPS}_RESPONSE`:
    case `${EDIT_CONTRACT_DETAILS_GROUPS}_RESPONSE`: {
      const { group } = action.payload
      const modifiedGroups = replaceOneItem(state.groups.groups, group, 'id')

      return {
        ...state,
        groups: {
          ...state.groups,
          groups: modifiedGroups
        }
      }
    }
    case `${DELETE_CONTRACT_DETAILS_GROUPS}_RESPONSE`: {
      const { isDeleted } = action.payload
      const { idGroup } = action.meta

      if (!isDeleted) {
        return state
      }

      const modifiedGroups = state.groups.groups.filter(group => group.id !== idGroup)

      return {
        ...state,
        groups: {
          ...state.groups,
          groups: modifiedGroups
        }
      }
    }
    case `${DELETE_CONTRACT_DETAILS_FIELD}_RESPONSE`: {
      const { isDeleted } = action.payload
      const { idField } = action.meta

      if (!isDeleted) {
        return state
      }

      const modifiedFields = state.fields.fields.filter(field => field.id !== idField)

      return {
        ...state,
        fields: {
          ...state.fields,
          fields: modifiedFields
        }
      }
    }
    case `${UPDATE_CONTRACT_DETAILS_FIELD}_PENDING`: {
      const { idField, isShown } = action.meta
      const modifiedFields = updateOneItem(state.fields.fields, 'id', idField, { isShown })

      return {
        ...state,
        fields: {
          ...state.fields,
          fields: modifiedFields
        }
      }
    }
    case `${UPDATE_CONTRACT_DETAILS_FIELD}_FAILED`: {
      const { idField, isShown } = action.meta
      const revertedIsShownValue = !isShown
      const modifiedFields = updateOneItem(state.fields.fields, 'id', idField, { isShown: revertedIsShownValue })

      return {
        ...state,
        fields: {
          ...state.fields,
          fields: modifiedFields
        }
      }
    }
    case `${UPDATE_CONTRACT_DETAILS_FIELD}_RESPONSE`: {
      const { field } = action.payload
      const modifiedFields = replaceOneItem(state.fields.fields, field, 'id')
      return {
        ...state,
        fields: {
          ...state.fields,
          fields: modifiedFields
        }
      }
    }
    case `${ADD_CONTRACT_DETAILS_FIELD}_RESPONSE`: {
      const { field } = action.payload
      const modifiedFields = replaceOneItem(state.fields.fields, field, 'id')
      return {
        ...state,
        fields: {
          ...state.fields,
          fields: modifiedFields
        }
      }
    }
    case `${GET_CONTRACT_DETAILS_FROM_AI}_RESPONSE`: {
      const { resources } = action.payload
      const updatedResources = getUpdatedResources(resources, state.contracts.resources)

      return {
        ...state,
        contracts: {
          ...state.contracts,
          resources: updatedResources
        }
      }
    }
    case SET_DONE_CONTRACTS_APP_MATCHING: {
      const { isAppMatchingDone } = action.meta

      return {
        ...state,
        contracts: {
          ...state.contracts,
          isAppMatchingDone
        }
      }
    }
    case `${GET_RENEWALS_SUMMARY}_PENDING`: {
      return {
        ...state,
        renewalsSummary: {
          ...state.renewalsSummary,
          loading: true
        }
      }
    }
    case `${GET_RENEWALS_SUMMARY}_RESPONSE`: {
      const renewalsSummary = action.payload
      return {
        ...state,
        renewalsSummary: {
          loading: false,
          data: renewalsSummary
        }
      }
    }
    case `${GET_RENEWALS_SUMMARY}_FAILED`: {
      return {
        ...state,
        renewalsSummary: {
          ...state.renewalsSummary,
          loading: false
        }
      }
    }
    case `${GET_CONTRACT_ATTACHMENTS}_PENDING`: {
      return {
        ...state,
        contractAttachments: {
          loading: true
        }
      }
    }
    case `${GET_CONTRACT_ATTACHMENTS}_FAILED`: {
      return {
        ...state,
        contractAttachments: {
          loading: false,
          data: []
        }
      }
    }
    case `${GET_CONTRACT_ATTACHMENTS}_RESPONSE`: {
      const contractAttachments = action.payload
      return {
        ...state,
        contractAttachments: {
          loading: false,
          data: contractAttachments
        }
      }
    }
    default: {
      return state
    }
  }
}

export default contractsReducer

const mergeById = (newArray = [], oldArray = []) => {
  const allValues = newArray.concat(oldArray)
  return uniqBy(allValues, 'id')
}

const getUpdatedResources = (newResources = {}, prevResources = {}) => {
  const { apps, users, contracts } = prevResources
  return {
    apps: keyBy(mergeById(Object.values((newResources.apps ?? {})), Object.values(apps)), 'id'),
    users: keyBy(mergeById(Object.values(newResources.users ?? {}), Object.values(users)), 'id'),
    contracts: keyBy(mergeById(Object.values(newResources.contracts ?? {}), Object.values(contracts)), 'id')
  }
}
