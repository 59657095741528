import React from 'react'
import PropTypes from 'prop-types'
import { AlertBox, AlertBoxType, Col, Grid, Row } from '@toriihq/design-system'
import AppAboutBox from '@components/appAboutBox'
import RiskLevel from '@components/riskLevel'
import AppPermissionsBox from '@components/appPermissionsBox'
import { css } from 'glamor'
import DataSourcesBox from '@components/dataSourcesBox'
import UsageTrendChartBox from '@components/usageTrendChartBox'
import ActiveUsersTrendChartBox from '@components/activeUsersTrendChartBox'
import AppExpensesChartBox from '@components/appExpensesChartBox'
import texts from '@shared/style/texts'
import colors from '@shared/style/colors'
import { fontWeight } from '@shared/style/sizes'
import LicensesChart from '@components/licensesChart'
import AppOffboardingStatusBox from '@components/appOffboardingStatusBox'
import { INTEGRATION_CATEGORY, SCOPES } from '@root/constants'
import VisibleFor from '@components/visibleFor'
import AppInCatalogBox from '@components/appInCatalogBox'
import RenewalAlertBox from '@components/renewalAlertBox'
import NotInUse from '@media/notInUse.svg'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'
import openConnectServicePopup from '@shared/services/openConnectServicePopup'
import Contacts from './components/contacts'
import Owners from './components/owners'
import Placeholder from '@components/placeholder'
import { FEATURES } from '@root/shared/features'
import { WIDGETS } from '@root/widgets/constants'
import WidgetsBox from './components/widgetsBox'
import { WIZARD_STEPS } from '@root/pages/licenseAudit/wizardSteps/types'

const CSS = {
  row: css({
    ' .col>*': {
      marginBottom: '30px'
    }
  }),
  smallRow: css({
    ' .col>*': {
      marginBottom: '24px'
    }
  }),
  main: css(texts.subheading, {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.grey1,
    fontWeight: fontWeight.normal,
    height: '100%'
  }),
  image: css({
    display: 'block',
    marginBottom: '20px'
  }),
  alertBox: css({
    marginBottom: '30px'
  }),
  widgetsContainer: css({
    display: 'grid',
    gap: 24,
    [`@media(min-width: 1250px)`]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    }
  })
}

class Overview extends React.Component {
  notInUseMessage () {
    const { isSmallScreen } = this.props
    return (
      <Col sm={isSmallScreen ? 12 : 8}>
        <div {...CSS.main}>
          <div {...CSS.image}><img src={NotInUse} alt='No in use application' /></div>
          <div>This application is not in use by your organization</div>
        </div>
      </Col>
    )
  }

  onLicenseAuditClick () {
    const { idOrg, idApp, toggleLicenseAuditNotSupportedPopup, appAccounts, history, setLicenseAuditData, name: appName } = this.props
    if (appAccounts.length > 1) {
      toggleLicenseAuditNotSupportedPopup({ isOpen: true, appName })
    } else {
      setLicenseAuditData({ idApp, idAppAccount: appAccounts[0].idAppAccount })
      history.push(`/team/${idOrg}/app/${idApp}/account/${appAccounts[0].idAppAccount}/licenseAudit/${WIZARD_STEPS.USER_REVIEW}`)
    }
  }

  usedAppData () {
    const { idOrg, idApp, name, isSmallScreen, isLoadedFeatures, history, appearsInCatalog } = this.props
    return (
      <Col sm={isSmallScreen ? 12 : 8}>
        <WidgetsBox idOrg={idOrg} idApp={idApp} />
        <LicensesChart idApp={idApp} appName={name} />
        <VisibleFor scopes={[SCOPES.CONTRACTS_READ, getScopeByIdOrgAndIdApp(SCOPES.CONTRACTS_READ, idOrg, idApp)]} >
          <RenewalAlertBox idOrg={idOrg} idApp={idApp} appName={name} history={history} onLicenseAuditClick={() => this.onLicenseAuditClick()} />
        </VisibleFor>
        <Placeholder loading={!isLoadedFeatures}>
          <Owners idApp={idApp} idOrg={idOrg} />
        </Placeholder>
        <Contacts idApp={idApp} idOrg={idOrg} />
        <VisibleFor scopes={[SCOPES.OFFBOARDING_READ, getScopeByIdOrgAndIdApp(SCOPES.OFFBOARDING_READ, idOrg, idApp)]} feature={FEATURES.OFFBOARDING.PAGE}>
          <AppOffboardingStatusBox idApp={idApp} />
        </VisibleFor>
        {appearsInCatalog && <AppInCatalogBox idApp={idApp} appName={name} />}
        <RiskLevel idApp={idApp} />
        <AppPermissionsBox idOrg={idOrg} idApp={idApp} />
      </Col>
    )
  }

  getAlertDescription = () => {
    const { name: appName, service } = this.props
    const {
      supportsUsage,
      integrationCategory
    } = service

    let description
    switch (integrationCategory) {
      case INTEGRATION_CATEGORY.EXPENSE_SOFTWARE:
        description = `Get all of your expenses automatically synced into Torii by connecting ${appName} integration.`
        break
      case INTEGRATION_CATEGORY.CONTRACT_MANAGEMENT_SOFTWARE:
        description = `Get all of your contracts automatically synced into Torii by connecting ${appName} integration.`
        break
      default:
        description = `Get more insights by connecting ${appName} integration ${supportsUsage ? 'and syncing users, licenses and usage from' : 'and syncing users and licenses from'} ${appName} API.`
    }

    return description
  }

  getAppCharts = () => {
    const { idOrg, idApp, isSmallScreen, currencySymbol, service, hasExpensesAccess, last12MonthsExpensesAggs } = this.props
    const numColumnsInLargerScreen = hasExpensesAccess ? 4 : 6
    return (
      <Row {...css(isSmallScreen ? CSS.smallRow : CSS.row)}>
        <Col sm={isSmallScreen ? 12 : numColumnsInLargerScreen}>
          <ActiveUsersTrendChartBox idOrg={idOrg} idApp={idApp} isSmallScreen={isSmallScreen} service={service} pageName='App' />
        </Col>
        <Col sm={isSmallScreen ? 12 : numColumnsInLargerScreen}>
          <UsageTrendChartBox idOrg={idOrg} idApp={idApp} isSmallScreen={isSmallScreen} service={service} pageName='App' />
        </Col>
        <VisibleFor scopes={[SCOPES.EXPENSE_READ, getScopeByIdOrgAndIdApp(SCOPES.EXPENSE_READ, idOrg, idApp)]}>
          <Col sm={isSmallScreen ? 12 : numColumnsInLargerScreen}>
            <AppExpensesChartBox idApp={idApp} isSmallScreen={isSmallScreen} currencySymbol={currencySymbol} header={'Expenses'} description={'App expenses in last 12 months'} expensesAggs={last12MonthsExpensesAggs} />
          </Col>
        </VisibleFor>
      </Row>
    )
  }

  handleConnectClick = () => {
    const { idApp, name: appName, service, toggleConnectSource, toggleConnectService, toggleConnectSCIMService } = this.props
    const {
      source,
      integrationType,
      supportsMultipleAccounts
    } = service

    return openConnectServicePopup({
      idApp,
      appName,
      integrationType,
      source,
      supportsMultipleAccounts,
      calledFrom: 'App page notification',
      dispatchers: {
        toggleConnectSource,
        toggleConnectService,
        toggleConnectSCIMService
      }
    })
  }

  render () {
    const { idApp, idOrg, isSmallScreen, isInUse, loading, service, showAlertBox, name: appName } = this.props
    const showAppInUseData = loading || isInUse

    return (
      <div>
        <VisibleFor scopes={[SCOPES.INTEGRATIONS_READ]}>
          {showAlertBox && <div {...CSS.alertBox}><AlertBox type={AlertBoxType.INFORMATIVE} description={this.getAlertDescription()} secondaryButton={{ label: `Connect ${appName}`, onClick: this.handleConnectClick }} /></div>}
        </VisibleFor>
        <Grid>
          {showAppInUseData ? this.getAppCharts() : null}
          <Row {...css(isSmallScreen ? CSS.smallRow : CSS.row)}>
            {showAppInUseData ? this.usedAppData() : this.notInUseMessage()}
            <Col sm={isSmallScreen ? 12 : 4}>
              <AppAboutBox idApp={idApp} idOrg={idOrg} />
              <DataSourcesBox isSmallScreen={isSmallScreen} service={service} />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

Overview.propTypes = {
  idOrg: PropTypes.number,
  idApp: PropTypes.number,
  name: PropTypes.string,
  widgets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.oneOf[Object.keys(WIDGETS)],
    header: PropTypes.element,
    fields: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    }))
  }))
}

Overview.defaultProps = {
  widgets: []
}

export default Overview
