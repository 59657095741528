import React from 'react'
import Table from '../../table'
import colors from '../../../shared/style/colors'
import { Tooltip, Icon, theme } from '@toriihq/design-system'
import { css } from 'glamor'
import texts from '../../../shared/style/texts'
import { TABLES } from '@root/constants'
import { get, isNil, isNumber } from 'lodash'
import Currency, { CurrencyFormat } from '@root/components/currency'
import Input from '@components/form/input'
import { LicenseEditSelectedColumn } from '@components/licenseEdit'

const CSS = {
  regular: css(texts.body, {
    color: colors.black
  }),
  main: css({
    width: '100%'
  }),
  tableHeader: css({
    display: 'flex'
  }),
  licenseName: css({
    whiteSpace: 'normal'
  }),
  tableMainHeader: {
    display: 'none'
  },
  tableStyle: {
    minHeight: '107px',
    borderTop: 'none'
  },
  noLicenses: css(texts.heading, {
    textAlign: 'center'
  }),
  totalLicenses: css({
    width: '80%'
  }),
  invalidTotalLicensesIcon: css({
    width: '20%',
    marginTop: '6px',
    marginLeft: '4px'
  }),
  invalidInput: css({
    border: `1px solid ${theme.palette.icon.error}`
  })
}

const setNoDataProps = () => {
  return {
    className: 'rt-noData',
    style: {
      pointerEvents: 'all',
      position: 'relative',
      top: '30px'
    }
  }
}

class LicenseCostAndTotalAmountTable extends React.Component {
  columns = this.getColumns()

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.currencySymbol !== this.props.currencySymbol) {
      this.columns = this.getColumns()
    }
  }

  renderNoLicenses () {
    return (
      <div {...CSS.noLicenses}>
        No licenses
      </div>
    )
  }

  onPricePerUserChange = (editedLicenseId, e) => {
    const { onChange, licenses } = this.props

    const value = get(e, 'target.value', '')
    const pricePerUser = value === '' ? null : Math.floor(value * 100)
    const newLicensesDetails = licenses.map(license => {
      if (license.id === editedLicenseId) {
        const totalCost = (pricePerUser || pricePerUser === 0) && license.totalAmount ? pricePerUser * license.totalAmount : null
        return { ...license, totalCost, pricePerUser }
      }
      return license
    })

    onChange(newLicensesDetails)
  }

  onTotalAmountChange = (editedLicenseId, e) => {
    const { onChange, licenses } = this.props

    const value = get(e, 'target.value', '')
    const totalAmount = value === '' ? null : Math.round(Number(value))
    const newLicensesDetails = licenses.map(license => {
      if (license.id === editedLicenseId) {
        const totalCost = !isNil(license.pricePerUser) && totalAmount ? license.pricePerUser * totalAmount : null
        return { ...license, totalCost, totalAmountEnteredByUser: totalAmount, totalAmount, isTotalAmountEnteredByUser: true }
      }
      return license
    })

    onChange(newLicensesDetails)
  }

  getColumns () {
    return [
      {
        Header: 'License Name',
        id: 'name',
        resizable: false,
        Cell: ({ row: { name, licenseName } }) => (
          <div {...CSS.licenseName}>{name || licenseName}</div>
        ),
        width: 200
      },
      {
        Header: 'Total Licenses',
        accessor: 'totalAmount',
        resizable: false,
        Cell: ({ value: totalAmount, row: { id, appName, assignedAmount, unassignedAmount, isTotalAmountEnteredByUser } }) => {
          const { focusOnLicenseId, selectedColumn } = this.props
          const shouldFocus = selectedColumn === LicenseEditSelectedColumn.TotalAmount && focusOnLicenseId === id
          const invalidEnteredTotalAmount = isNumber(totalAmount) && isTotalAmountEnteredByUser && totalAmount < assignedAmount
          const disableEditTotalAmount = !isNil(unassignedAmount) && !isTotalAmountEnteredByUser
          return <Tooltip label={`This number is fetched from ${appName || this.props.appName} and cannot be edited.`} hide={!disableEditTotalAmount}>
            <span {...CSS.totalLicenses}>
              <Input value={isNil(unassignedAmount) && !isTotalAmountEnteredByUser ? null : totalAmount} overrideStyle={invalidEnteredTotalAmount ? CSS.invalidInput : {}} disabled={disableEditTotalAmount} min={assignedAmount} type='number' autoFocus={shouldFocus} onChange={(e) => this.onTotalAmountChange(id, e)} />
            </span>
            <span {...CSS.invalidTotalLicensesIcon}>{invalidEnteredTotalAmount && <Tooltip label='The total licenses number must be higher than the Assigned licenses.'><Icon name='Danger' color='error' /></Tooltip>}</span>
          </Tooltip>
        },
        width: 180
      },
      {
        Header: 'Assigned Licenses',
        accessor: 'assignedAmount',
        resizable: false,
        Cell: ({ value: assignedAmount }) => {
          return <div>
            <div {...CSS.regular}>{assignedAmount}</div>
          </div>
        },
        width: 130,
        ...Table.numericFieldProps
      },
      {
        Header: 'Annual cost per license',
        accessor: 'pricePerUser',
        resizable: false,
        Cell: ({ value: pricePerUser, row: { id } }) => {
          const { currencySymbol, focusOnLicenseId, selectedColumn } = this.props
          const shouldFocus = selectedColumn === LicenseEditSelectedColumn.PricePerUser && focusOnLicenseId === id
          return <Input autoFocus={shouldFocus} prefix={currencySymbol} value={pricePerUser || pricePerUser === 0 ? (pricePerUser / 100).toString() : ''} type='number' onChange={(e) => this.onPricePerUserChange(id, e)} />
        },
        width: 180,
        ...Table.numericFieldProps
      },
      {
        Header: <div {...CSS.tableHeader}>
          <Tooltip label='In last 12 months'>
            <div>Total Cost (estimated)</div>
          </Tooltip>
        </div>,
        accessor: 'totalCost',
        resizable: false,
        Cell: ({ value: totalCost }) => {
          const { currencySymbol } = this.props
          return <div {...CSS.regular}> {totalCost ? <Currency value={totalCost} currencySymbol={currencySymbol} format={CurrencyFormat.FLOAT} /> : '-'} </div>
        },
        width: 170,
        ...Table.numericFieldProps
      },
      {
        accessor: 'totalAmount',
        show: false
      },
      {
        accessor: 'licenseTypes',
        show: false
      },
      {
        accessor: 'name',
        show: false
      },
      {
        accessor: 'licenseName',
        show: false
      },
      {
        accessor: 'id',
        show: false
      },
      {
        accessor: 'isTotalAmountEnteredByUser',
        show: false
      },
      {
        accessor: 'unassignedAmount',
        show: false
      },
      {
        accessor: 'appName',
        show: false
      }
    ]
  }

  render () {
    const { isLoading, licenses } = this.props

    return (
      <div {...CSS.main}>
        <Table
          tableKey={TABLES.licenseCostTable.key}
          tableHeaderStyle={CSS.tableMainHeader}
          emptyStateMessage={this.renderNoLicenses()}
          data={licenses}
          columns={this.columns}
          style={CSS.tableStyle}
          loading={isLoading}
          getNoDataProps={setNoDataProps}
        />
      </div>
    )
  }
}

export default LicenseCostAndTotalAmountTable
