import { createSelector } from 'reselect'
import { APP_USER_STATUS } from '@shared/types'
import identity from 'lodash/identity'
import reduce from 'lodash/reduce'
import pick from 'lodash/pick'
import keyBy from 'lodash/keyBy'
import get from 'lodash/get'
import { deprecatedGetApps } from '../apps'
import { createIdAppParameterSelector } from '@shared/utils'
import { EMPTY_OBJECT } from '@root/constants'

export const getAppUsersOffboardingProgress = createSelector(
  state => {
    const apps = state.apps.apps || []
    const mergedUsers = {}
    apps
      .filter(app => app.users)
      .forEach(app => {
        app.users
          .filter(user => user.idUser)
          .forEach(user => {
            if (!mergedUsers[user.idUser]) {
              mergedUsers[user.idUser] = {
                idUser: user.idUser,
                apps: [],
                removedFromApps: [],
                ownerWasNotifiedApps: []
              }
            }
            mergedUsers[user.idUser].apps.push(app.id)
            if (user.isUserRemovedFromApp) {
              mergedUsers[user.idUser].removedFromApps.push(app.id)
            }

            if (user.isOwnerNotifiedToRemoveUser) {
              mergedUsers[user.idUser].ownerWasNotifiedApps.push(app.id)
            }
          })
      })
    return mergedUsers
  },
  identity
)

export const getAllAppUsersES = createSelector(
  state => state.appUsers.usersPerApp,
  identity
)

export const getFieldValues = createSelector(
  state => {
    const valuesPerApp = state.appUsers.fieldValuesPerApp
    return reduce(valuesPerApp, (result, valuesPerField, idApp) => {
      result[idApp] = reduce(valuesPerField, (res, values, field) => {
        res[field] = values.map(item => ({ value: item.key, label: get(item, ['key', 'fullName'], item.key) }))
        return res
      }, {})
      return result
    }, {})
  },
  identity
)

export const getAppFieldValues = createSelector(
  [getFieldValues, createIdAppParameterSelector],
  (fieldValues, idApp) => (idApp && fieldValues[idApp]) ?? EMPTY_OBJECT
)

export const getAllAppAccountLicenseUsersES = createSelector(
  state => state.appUsers.usersPerAccountLicense,
  identity
)

export const getAppUsersES = createSelector(
  [getAllAppUsersES],
  allAppUsers => {
    return reduce(allAppUsers, (result, appUsers) => {
      result[appUsers.idApp] = appUsers.filter(user => user.status === APP_USER_STATUS.ACTIVE)
      return result
    }, {})
  },
  identity
)

export const getAllAppUsersState = createSelector(
  state => state.appUsers.statePerApp,
  identity
)

export const getAllAppsOfUserES = createSelector(
  state => state.appUsers.appsPerUser,
  identity
)

export const getAppsOfUserES = createSelector(
  [deprecatedGetApps, getAllAppsOfUserES],
  (apps, allAppsOfUser) => {
    const appsByIdApp = keyBy(apps, 'id')
    return reduce(allAppsOfUser, (result, userApps) => {
      const activeUsersWithAppData = userApps.map(userApp => {
        const app = appsByIdApp[userApp.idApp] || {}
        return {
          ...userApp,
          ...pick(app, ['name', 'imageUrl', 'category', 'owner', 'primaryOwner', 'appOwners'])
        }
      })

      if (userApps[0]) {
        result[userApps[0].idUser] = activeUsersWithAppData
      }

      return result
    }, {})
  }
)

export const isLoadingAppUsers = createSelector(
  state => state.appUsers.loading,
  identity
)

export const isLoadingAppUsersES = createSelector(
  state => state.appUsers.loadingUsersPerApp,
  identity
)

export const getImportAppUsersStatus = createSelector(
  state => state.appUsers.importAppUsersStatus,
  identity
)
