import ToriiPopup from '@components/popups/ToriiPopupV2'
import { ErrorSubtitle, ErrorText } from './styles'
import { RendererProps } from '@root/components/popups/wizardPopup/types'
import { SharedProps, SharedState, WIZARD_STEPS } from '../types'

const DeletionFailure = ({ sharedProps, onCancel }: RendererProps<SharedState, SharedProps, WIZARD_STEPS>): JSX.Element => {
  const { appAccountName } = sharedProps

  return <>
    <ToriiPopup.Header header='Delete users' subHeader={appAccountName} />
    <ToriiPopup.Content>
      <ErrorText>
        <ErrorSubtitle>Delete Users Failed</ErrorSubtitle>
        <br />
        Something went wrong, please try again later.
      </ErrorText>
    </ToriiPopup.Content>
    <ToriiPopup.Footer
      mainButtonText='Close'
      mainButtonAction={onCancel}
      showCancelButton={false} />
  </>
}

export default DeletionFailure
