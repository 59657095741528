import styled from 'styled-components'

export const PopOverContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 400px;
`

export const LinkCopy = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const PopoverWrapper = styled.div`
  width: 400px;
`
