import {
  GET_USER_DETAIL_FIELD_HISTORY,
  GET_USER_DETAILS,
  GET_USER_FIELD_DISPLAY_NAMES,
  GET_USER_FIELDS
} from '@root/constants'

const initialState = {
  loadingFields: false,
  fields: [],
  fieldsDisplayNames: {},
  loadingDetails: false,
  loadingDetailsHistory: false,
  lazyLoadedUsersDetails: {},
  lazyLoadedUsersDetailsHistory: {}
}

const userDetailsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_USER_FIELDS}_RESPONSE`: {
      const { fields } = action.payload
      return {
        ...state,
        loadingFields: false,
        fields
      }
    }
    case `${GET_USER_FIELD_DISPLAY_NAMES}_PENDING`: {
      const { userFieldKey } = action.meta

      return {
        ...state,
        fieldsDisplayNames: {
          ...state.fieldsDisplayNames,
          [userFieldKey]: {
            displayNames: [],
            ...state.fieldsDisplayNames[userFieldKey],
            isLoading: true
          }
        }
      }
    }
    case `${GET_USER_FIELD_DISPLAY_NAMES}_RESPONSE`: {
      const fieldDisplayNames = action.payload
      const { userFieldKey } = action.meta

      const fieldsDisplayNames = { ...state.fieldsDisplayNames }
      fieldsDisplayNames[userFieldKey] = {
        isLoading: false,
        displayNames: fieldDisplayNames
      }

      return {
        ...state,
        fieldsDisplayNames
      }
    }
    case `${GET_USER_DETAILS}_PENDING`: {
      return {
        ...state,
        loadingDetails: true
      }
    }
    case `${GET_USER_DETAILS}_FAILED`: {
      return {
        ...state,
        loadingDetails: false
      }
    }
    case `${GET_USER_DETAILS}_RESPONSE`: {
      const { appAccounts: detailsGroupedByAppAccount, resources } = action.payload
      const { idUser } = action.meta

      return {
        ...state,
        loadingDetails: false,
        lazyLoadedUsersDetails: {
          ...state.lazyLoadedUsers,
          [idUser]: {
            ...state.lazyLoadedUsers?.[idUser],
            details: detailsGroupedByAppAccount,
            resources
          }
        }
      }
    }
    case `${GET_USER_DETAIL_FIELD_HISTORY}_PENDING`: {
      return {
        ...state,
        loadingDetailsHistory: true
      }
    }
    case `${GET_USER_DETAIL_FIELD_HISTORY}_FAILED`: {
      return {
        ...state,
        loadingDetailsHistory: false
      }
    }
    case `${GET_USER_DETAIL_FIELD_HISTORY}_RESPONSE`: {
      const { history, resources } = action.payload
      const { idUser, idUserField, idAppAccount } = action.meta

      return {
        ...state,
        loadingDetailsHistory: false,
        lazyLoadedUsersDetailsHistory: {
          ...state.lazyLoadedUsersDetailsHistory,
          [idUser]: {
            ...state.lazyLoadedUsersDetailsHistory?.[idUser],
            [idUserField]: {
              ...state.lazyLoadedUsersDetailsHistory?.[idUser]?.[idUserField],
              [idAppAccount]: {
                history,
                resources
              }
            }
          }
        }
      }
    }

    default: {
      return state
    }
  }
}

export default userDetailsReducer
