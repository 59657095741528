import View from './view'
import { connect } from 'react-redux'
import { getCurrencySymbol } from '@selectors/org'

const mapStateToProps = (state, ownProps) => {
  const currencySymbol = getCurrencySymbol(state)

  return {
    currencySymbol
  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(View)
