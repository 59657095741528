import React, { useState } from 'react'
import { Tooltip, Body2, IconName, Link, Button, ButtonType, ButtonSize, Popover, Stack } from '@toriihq/design-system'
import Input from '@components/form/input'
import Analytics from '@helpers/analytics'
import copy from 'copy-to-clipboard'
import { LinkCopy, PopOverContent } from './style'
import noop from 'lodash/noop'
import ButtonOfFeature from '@components/buttonOfFeature'

type Props = {
  icon: IconName,
  label: string,
  textToCopy: string,
  analyticsText: string,
  bodyText: string,
  supportArticleLink: string,
  enableForFeature?: string
}

export const CopyTextPopup = (props: Props): JSX.Element => {
  const { icon, label, textToCopy, analyticsText, bodyText, supportArticleLink, enableForFeature } = props

  const [isLinkCopied, setIsLinkCopied] = useState(false)

  const onCopy = (e): void => {
    e.preventDefault()
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()

    Analytics.track(analyticsText)

    copy(textToCopy)
    setIsLinkCopied(true)
    setTimeout(() => setIsLinkCopied(false), 2000)
  }

  const buttonProps = {
    icon,
    label,
    onClick: noop,
    type: ButtonType.secondary
  }

  return (
    <Popover
      position='bottom'
      align='end'
      openOnClick
      content={<PopOverContent>
        <Stack direction='column' gap='space-100'>
          <Body2>{bodyText}</Body2>
          <LinkCopy>
            <Input
              type='text'
              value={textToCopy}
              readOnly
            />
            <Tooltip label={isLinkCopied ? 'Link copied' : 'Copy link'}>
              <Button type={ButtonType.compact} size={ButtonSize.small} icon={isLinkCopied ? 'Check' : 'Copy'} onClick={onCopy} />
            </Tooltip>
          </LinkCopy>
          <div>
            <Link href={supportArticleLink} target='_blank'>Learn more</Link>
          </div>
        </Stack>
      </PopOverContent>}
    >
      {enableForFeature
        ? <ButtonOfFeature {...buttonProps} feature={enableForFeature} tooltipPlacement={'top'} />
        : <Button {...buttonProps} />
      }
    </Popover>
  )
}
export default CopyTextPopup
