import React, { Fragment, ReactElement } from 'react'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { RendererProps } from '@components/popups/wizardPopup/types'
import { SharedProps, SharedState, WIZARD_STEPS } from '@components/popups/configureMergeUsersRulesPopup/stepConfig/types'
import mergeUsers from '../images/mergeUsers.svg'
import { Styles } from './style'
import { Body2 } from '@toriihq/design-system'
import { RuleSelector } from '@components/popups/configureMergeUsersRulesPopup/stepConfig/selectRule/ruleSelector'
import { PopupImage } from '@components/popups/configureMergeUsersRulesPopup/stepConfig/popupImage'

export const SelectRule = ({
  sharedState,
  sharedProps,
  setState,
  onCancel,
  navigateToStep
}: RendererProps<SharedState, SharedProps, WIZARD_STEPS>): ReactElement => {
  const { availableRules } = sharedState
  const isMainButtonDisabled = !availableRules.find(appRule => appRule.isEnabled)

  const TextItem = (props) => {
    return <li><Body2>{props.children}</Body2></li>
  }

  const onSelectionChange = ({ selectedRuleIdApp, selectedRuleUserFieldKey }) => {
    setState({
      ...sharedState,
      selectedRuleIdApp,
      selectedRuleUserFieldKey
    })
  }

  return (<Fragment>
    <ToriiPopup.Header header={'Merge users'} />
    <ToriiPopup.Content contentAreaStyle={{ padding: '0px 0px 24px', 'overflow-y': 'unset' }}>
      <PopupImage src={mergeUsers} alt={'Merge Users'} />
      <Styles.Container>
        <Styles.TextArea>
          <Body2>You can merge Torii users automatically based on data received from one of the IDP source options
            detailed below</Body2>
          <div>
            <Styles.TextHeader>Merging users will allow you to:</Styles.TextHeader>
            <Styles.TextList>
              <TextItem>See all user data (such as app access, licenses, annual cost) for each person holistically</TextItem>
              <TextItem>Offboard people with confidence that all relevant users are dealt with</TextItem>
              <TextItem>Show your employees all apps they have access to via the App Catalog</TextItem>
            </Styles.TextList>
          </div>
        </Styles.TextArea>
        <Styles.RuleSelectorContainer>
          <Styles.TextHeader>Match users based on</Styles.TextHeader>
          <RuleSelector
            onSelectionChange={onSelectionChange}
            sharedState={sharedState}
            sharedProps={sharedProps}
          />
        </Styles.RuleSelectorContainer>
      </Styles.Container>
    </ToriiPopup.Content>
    <ToriiPopup.Footer
      cancelButtonText={'Cancel'}
      cancelButtonAction={onCancel}
      mainButtonText={'Next'}
      isMainButtonDisabled={isMainButtonDisabled}
      mainButtonAction={() => navigateToStep(WIZARD_STEPS.APPLY_MERGE)}
      showBackButton
      isBackButtonDisabled
    />
  </Fragment>)
}
