import emptyImage from '@media/dance.svg'
import { useSelector } from 'react-redux'
import { EmptyState } from '@toriihq/design-system'
import { getAllAppAccountLicenseUsersES } from '@selectors/appUsers'
import { EMPTY_OBJECT } from '@root/constants'

const UsersEmptyState = (props): JSX.Element | null => {
  const { idAppAccount, idLicense } = props
  const allUsers = useSelector(getAllAppAccountLicenseUsersES)
  const licenseUsers = allUsers?.[idAppAccount]?.[idLicense] || EMPTY_OBJECT
  const { loading: isLoading } = licenseUsers

  if (isLoading) {
    return null
  }

  return <EmptyState
    image={<img src={emptyImage} alt='No users' />}
    title={`No users to display`}
  />
}

export default UsersEmptyState
