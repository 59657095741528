import send from '@shared/redux-fetch'
import {
  ADD_APP,
  ADD_CUSTOM_APP,
  APP_COMPARISON_GET_ACTIVE_USERS,
  APP_COMPARISON_GET_APP_ACTIVE_USERS,
  APP_COMPARISON_GET_OVERLAPS,
  APP_COMPARISON_GET_OVERLAPS_USERS,
  APP_COMPARISON_GET_RECOMMENDATIONS,
  APP_COMPARISON_GET_USAGE,
  APP_COMPARISON_UPDATE_ID_APPS,
  CALCULATED_LICENSE_STATUS,
  CHANGE_APP_HIDDEN_STATE,
  DELETE_APP_DETAILS_FIELD_VALUE,
  EDIT_CUSTOM_APP,
  GET_APP_INACTIVE_LICENSES,
  GET_APP_LICENSES,
  GET_APP_ACCOUNT_LICENSES_USERS,
  GET_APP_USERS,
  GET_APP_USERS_FIELD_VALUES,
  GET_APPS,
  GET_APPS_FIELD_VALUES,
  GET_APPS_V2,
  GET_APPS_V2_AMOUNT,
  GET_COMPARISON_APPS,
  GET_HIDDEN_APPS,
  GET_SIMILAR_APPS,
  GET_SINGLE_APP,
  GET_SUGGESTED_OWNER,
  UPDATE_APPS_DETAILS,
  UPLOAD_APP_ATTACHMENT,
  GET_SPECIFIC_APP_V2,
  DEFAULT_API_PAGE_LIMIT,
  GET_APP_OWNERS,
  GET_APP_COMPLIANCE,
  LICENSE_AUDIT_DATA
} from '@root/constants'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'
import { ops } from '@lenses/filters'
import { isArray, isEmpty } from 'lodash'

/**
 * @deprecated use apps from opensearch (getAppsV2)
 */
export const deprecatedGetApps = ({ idOrg }) => {
  return send(GET_APPS, {
    url: `/api/orgs/${idOrg}/apps/usage`
  })
}

export const getAppsV2 = ({
  actionTarget = GET_APPS_V2,
  idOrg,
  fields = [],
  limit = DEFAULT_API_PAGE_LIMIT,
  offset = 0,
  sort,
  q,
  reset,
  filters = [],
  withoutContent
}) => {
  return send(actionTarget, {
    url: `/api/orgs/${idOrg}/apps`,
    query: omitBy({
      limit,
      offset,
      sort,
      q,
      fields: fields.join(','),
      filters: JSON.stringify(filters),
      withoutContent
    }, isUndefined),
    meta: {
      reset
    }
  })
}

export const getAppsV2Amount = ({ idOrg }) => {
  return send(GET_APPS_V2_AMOUNT, {
    url: `/api/orgs/${idOrg}/apps`,
    query: {
      withoutContent: true
    }
  })
}

export const getComparisonApps = ({ idOrg, idApps, fields = [] }) => {
  const idAppsFilters = [{
    key: { value: 'id' },
    op: { value: ops.anyOf },
    value: idApps.map(idApp => ({ value: idApp }))
  }]

  return send(GET_COMPARISON_APPS, {
    url: `/api/orgs/${idOrg}/apps`,
    query: {
      fields: fields.join(','),
      filters: JSON.stringify(idAppsFilters),
      limit: 4
    },
    meta: {
      idApps
    }
  })
}

export const updateComparisonIdApps = (idApps) => ({
  type: APP_COMPARISON_UPDATE_ID_APPS,
  payload: { idApps }
})

export const setLicenseAuditData = ({ idApp, idAppAccount, data }) => ({
  type: LICENSE_AUDIT_DATA,
  payload: {
    idApp,
    idAppAccount,
    data
  }
})

export const addApplication = ({ idOrg, idApp, state }) => {
  return send(ADD_APP, {
    method: 'POST',
    url: `/api/orgs/${idOrg}/apps`,
    body: {
      idApp,
      state
    }
  })
}

export const addCustomApplication = ({ idOrg, name, url, imageUrl, category, description, tags = [], state }) => {
  return send(ADD_CUSTOM_APP, {
    method: 'POST',
    url: `/api/orgs/${idOrg}/apps/custom`,
    body: {
      name,
      url: url.trim(),
      imageUrl,
      category,
      description,
      tags: tags.join(),
      state
    }
  })
}

export const editCustomApplication = ({ idOrg, idApp, name, url, imageUrl, category, description, tags = [] }) => {
  return send(EDIT_CUSTOM_APP, {
    method: 'PUT',
    url: `/api/orgs/${idOrg}/apps/${idApp}`,
    body: {
      name,
      url: url.trim(),
      imageUrl,
      category,
      description,
      tags: tags.join()
    }
  })
}

export const getHiddenApps = ({ idOrg }) => {
  return send(GET_HIDDEN_APPS, {
    url: `/api/orgs/${idOrg}/apps/hidden`
  })
}

export const getSimilarApps = ({ idApp, idOrg }) =>
  send(GET_SIMILAR_APPS, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/similar`
  })

export const getSuggestedOwners = ({ idOrg, idApp }) =>
  send(GET_SUGGESTED_OWNER, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/suggestedOwners`,
    meta: {
      idApp
    }
  })

export const getSingleApp = ({ idOrg, idApp }) => {
  return send(GET_SINGLE_APP, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/usage`
  })
}

export const assignOwners = ({ fieldsValues, idApp, idOrg, user }) => {
  if (!fieldsValues || isEmpty(fieldsValues) || !isArray(fieldsValues)) {
    return
  }

  if (!idApp || !idOrg || !user) {
    return
  }

  return send(UPDATE_APPS_DETAILS, {
    url: `/api/orgs/${idOrg}/apps/details`,
    method: 'PUT',
    body: {
      idApps: idApp,
      fieldsValues: JSON.stringify(fieldsValues)
    },
    meta: {
      idOrg,
      idApps: [idApp],
      fieldsValues,
      user
    }
  })
}

export const setAppsDetails = ({ idOrg, idApps, fieldsValues, isBulk, user }) => {
  return send(UPDATE_APPS_DETAILS, {
    url: `/api/orgs/${idOrg}/apps/details`,
    method: 'PUT',
    meta: {
      idOrg,
      idApps,
      fieldsValues,
      isBulk,
      user
    },
    body: {
      idApps: isBulk ? JSON.stringify(idApps) : idApps[0],
      fieldsValues: JSON.stringify(fieldsValues)
    }
  })
}

export const uploadAppAttachment = ({ idOrg, idApp, idField, idUpload }) =>
  send(UPLOAD_APP_ATTACHMENT, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/details/import/${idField}`,
    method: 'POST',
    meta: {
      idField,
      idApp
    },
    body: {
      idUpload
    }
  })

export const deleteAppDetailsFieldValue = ({ idOrg, idApp, idField, id }) =>
  send(DELETE_APP_DETAILS_FIELD_VALUE, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/details`,
    method: 'DELETE',
    meta: {
      idField,
      idApp
    },
    body: {
      idField,
      id
    }
  })

export const getCompareActiveUsers = ({ idOrg, idApps }) => {
  return send(APP_COMPARISON_GET_ACTIVE_USERS, {
    url: `/api/orgs/${idOrg}/apps/compare/activeUsers`,
    query: {
      idApps: JSON.stringify(idApps)
    }
  })
}

export const getAppActiveUsers = ({ idOrg, idApp, limit = DEFAULT_API_PAGE_LIMIT, offset = 0, q, sort, reset, filters = [] }) => {
  return send(APP_COMPARISON_GET_APP_ACTIVE_USERS, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/activeUsers`,
    query: omitBy({
      limit,
      offset,
      q,
      sort,
      filters: JSON.stringify(filters)
    }, isUndefined),
    meta: {
      idApp,
      reset
    }
  })
}

export const getCompareUsage = ({ idOrg, idApps }) => {
  return send(APP_COMPARISON_GET_USAGE, {
    url: `/api/orgs/${idOrg}/apps/compare/usage`,
    query: {
      idApps: JSON.stringify(idApps)
    }
  })
}

export const getAppAccountLicenseUsers = ({ idOrg, idApp, idAppAccount, calculatedStatus, idLicense, limit = DEFAULT_API_PAGE_LIMIT, offset = 0, q, sort, reset, filters = [] }) => {
  return send(GET_APP_ACCOUNT_LICENSES_USERS, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/users`,
    query: omitBy({
      limit,
      offset,
      q,
      sort,
      idAppAccount,
      calculatedStatus,
      idLicense,
      filters: JSON.stringify(filters)
    }, isUndefined),
    meta: {
      idApp,
      idAppAccount,
      idLicense,
      reset
    }
  })
}

export const getAppUsers = ({ idOrg, idApp, idAppAccount, calculatedStatus, idLicense, limit = DEFAULT_API_PAGE_LIMIT, offset = 0, q, sort, reset, status, filters = [] }) =>
  send(GET_APP_USERS, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/users`,
    query: omitBy({
      limit,
      offset,
      q,
      sort,
      status,
      idAppAccount,
      filters: JSON.stringify(filters)
    }, isUndefined),
    meta: {
      idApp,
      reset
    }
  })

export const getAppUsersFieldValues = ({ idOrg, idApp, status, fields }) =>
  send(GET_APP_USERS_FIELD_VALUES, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/users/fieldValues`,
    query: omitBy({
      status,
      fields: JSON.stringify(fields)
    }, isUndefined),
    meta: {
      idApp
    }
  })

export const getAppsFieldValues = ({ actionTarget = GET_APPS_FIELD_VALUES, idOrg, fields }) =>
  send(actionTarget, {
    url: `/api/orgs/${idOrg}/apps/fieldValues`,
    query: omitBy({
      fields: JSON.stringify(fields)
    }, isUndefined)
  })

export const getAppInactiveLicenses = ({ idOrg, idApp, idLicense, isLicenseTypePaid, limit = DEFAULT_API_PAGE_LIMIT, offset = 0, q, sort, fields, reset }) => {
  return send(GET_APP_INACTIVE_LICENSES, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/licenses`,
    query: omitBy({
      limit,
      offset,
      q,
      sort,
      fields,
      idLicense,
      isLicenseTypePaid,
      calculatedStatus: CALCULATED_LICENSE_STATUS.INACTIVE
    }, isUndefined),
    meta: {
      idApp,
      idLicense,
      reset
    }
  })
}

export const getAppsLicenses = ({ idOrg }) => {
  return send(GET_APP_LICENSES, {
    url: `/api/orgs/${idOrg}/apps/licenses`
  })
}

export const changeAppHiddenState = ({ idOrg, idApp, isHidden }) =>
  send(CHANGE_APP_HIDDEN_STATE, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/hidden`,
    method: 'PUT',
    body: {
      isHidden
    },
    meta: {
      idApp,
      isHidden
    }
  })

export const getOverlapsAppsComparison = ({ idOrg, idApps, withLicenses }) => {
  return send(APP_COMPARISON_GET_OVERLAPS, {
    url: `/api/orgs/${idOrg}/apps/compare/overlaps`,
    query: {
      idApps: JSON.stringify(idApps),
      withLicenses
    },
    meta: {
      withLicenses
    }
  })
}

export const getOverlapsUsersAppsComparison = ({ idOrg, idApps, limit = DEFAULT_API_PAGE_LIMIT, offset = 0, reset = false, sort, q, withLicenses }) => {
  return send(APP_COMPARISON_GET_OVERLAPS_USERS, {
    url: `/api/orgs/${idOrg}/apps/compare/overlaps/users`,
    query: omitBy({
      sort,
      q,
      limit,
      offset,
      idApps: JSON.stringify(idApps),
      withLicenses
    }, isUndefined),
    meta: {
      reset
    }
  })
}

export const getComparisonRecommendations = ({ idOrg }) => {
  return send(APP_COMPARISON_GET_RECOMMENDATIONS, {
    url: `/api/orgs/${idOrg}/apps/compare`
  })
}

export const getAppV2 = ({ idOrg, idApp, fields = [] }) => {
  return send(GET_SPECIFIC_APP_V2, {
    url: `/api/orgs/${idOrg}/apps/${idApp}`,
    query: {
      fields: fields.join(',')
    }
  })
}

export const getAppOwners = ({ idOrg }) => {
  const appOwnersFilters = [
    {
      key: { label: 'App owners', value: 'appOwners', type: 'user' },
      op: { label: 'is set', value: 'isSet' }
    }
  ]

  return send(GET_APP_OWNERS, {
    url: `/api/orgs/${idOrg}/apps`,
    query: {
      fields: 'id,primaryOwner,appOwners',
      filters: JSON.stringify(appOwnersFilters)
    }
  })
}

export const getAppCompliance = ({ idOrg, idApp }) => {
  return send(GET_APP_COMPLIANCE, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/appCompliance`,
    meta: {
      idApp
    }
  })
}
