import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { useDispatch, useSelector } from 'react-redux'
import { getShareReportPopup } from '@selectors/ui'
import { getWorkflowsActionsConfig, toggleShareReportPopup, updateReport } from '@actions/'
import { WORKFLOW_ACTION_TYPES } from '@root/constants'
import Placeholder from '@components/placeholder'
import EditWorkflowAction from '@components/popups/configureAppForOffboardingPopup/editWorkflowAction'
import { getActionsConfigByType, getFieldsDefaultValues, getFlattenActionsConfig } from '@selectors/workflows'
import { getIdOrg } from '@selectors/org'
import { updateActionFieldValue } from '@shared/workflows/updateActionFieldValue'
import { Styles } from '@components/popups/shareReportPopup/style'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import { type Dictionary } from 'lodash'
import { ActionShareReport, InputSchemaFieldWithValue, SHARE_METHOD, SHARE_OPTION, ShareReportPopupParams } from './types'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import { createReport } from '@actions/reports/reports'
import {
  Body1,
  Body2,
  Button,
  ButtonSize,
  ButtonType,
  Divider,
  FormElement,
  Icon,
  Input,
  Link,
  RadioButton,
  Spacer,
  Stack,
  toast,
  ToastType,
  Tooltip
} from '@toriihq/design-system'
import { buildCron, createAction, getActionFieldsValues, parseCron } from '@components/popups/shareReportPopup/utils'
import { SCHEDULE_FREQUENCY } from '@actions/reports/reports.types'
import ToriiSelect from '@components/select'
import { daysOfWeekOptions, getDaysOfMonthOptions, scheduleFrequencyOptions } from './constants'
import { hourOptions } from '@components/workflows/workflowSchedule/consts'
import SelectTimezone from '@components/selectTimezone'
import { FEATURES } from '@shared/features'
import AccessControl from '@lenses/accessControl'

const ShareReportPopup = (): ReactElement => {
  const dispatch = useDispatch()
  const history = useHistory()

  const actionType = WORKFLOW_ACTION_TYPES.SHARE_REPORT

  const idOrg = useSelector(getIdOrg)
  const { isOpen, report, isEditMode }: ShareReportPopupParams = useSelector(getShareReportPopup)
  const { config, reportName: reportNameFromProps, reportKey } = report
  const actionsConfig = useSelector(getFlattenActionsConfig)
  const fieldsDefaultValues = useSelector(getFieldsDefaultValues)
  const actionsConfigByType = useSelector(getActionsConfigByType)

  const isScheduleReportsFeatureInPlan = AccessControl.useIsFeatureEnabledInPlan({ feature: FEATURES.REPORTS.OPTIONS.SCHEDULED_REPORTS })

  const [reportName, setReportName] = useState<string>(reportNameFromProps)
  const [action, setAction] = useState<ActionShareReport>()
  const [shareOption, setShareOption] = useState<SHARE_OPTION>(isScheduleReportsFeatureInPlan ? SHARE_OPTION.SCHEDULED : SHARE_OPTION.ONCE)
  const [scheduleFrequency, setScheduleFrequency] = useState<Exclude<SCHEDULE_FREQUENCY, SCHEDULE_FREQUENCY.ONCE>>(SCHEDULE_FREQUENCY.DAILY)
  const [dayOfWeek, setDayOfWeek] = useState<string>('2')
  const [dayOfMonth, setDayOfMonth] = useState<string>('1')
  const [timeOfDay, setTimeOfDay] = useState<string>('10')
  const [timeZone, setTimeZone] = useState<string>('UTC')
  const [shouldRenderTestButton, setShouldRenderTestButton] = useState<boolean>(true)

  const isScheduled = (shareOption === SHARE_OPTION.SCHEDULED)

  useEffect(() => {
    if (idOrg) {
      dispatch(getWorkflowsActionsConfig(idOrg))
    }
  }, [dispatch, idOrg])

  useEffect(() => {
    if (!isEmpty(actionsConfig)) {
      const fieldsToValue = { 'Report name': reportNameFromProps }
      const newAction = createAction({
        actionType,
        actionsConfig,
        fieldsDefaultValues,
        actionsConfigByType,
        fieldsToValue
      })

      if (isEditMode && report) {
        const updatedAction = getActionFieldsValues({ action: newAction, report })
        setAction(updatedAction)
      } else {
        setAction(newAction)
      }
    }
  }, [actionsConfig, actionType, actionsConfigByType, fieldsDefaultValues, reportNameFromProps, isEditMode, report])

  useEffect(() => {
    if (isEditMode) {
      const { scheduleFrequency: editScheduleFrequency, timeZone: editTimeZone, cron: editCron } = report.scheduleDetails
      setScheduleFrequency(editScheduleFrequency)
      setTimeZone(editTimeZone)

      const { hour, dayOfMonth, dayOfWeek } = parseCron(editCron)
      setTimeOfDay(hour)

      if (editScheduleFrequency === SCHEDULE_FREQUENCY.WEEKLY) {
        setDayOfWeek(dayOfWeek)
      } else if (editScheduleFrequency === SCHEDULE_FREQUENCY.MONTHLY) {
        setDayOfMonth(dayOfMonth)
      }
    }
  }, [isEditMode, report.scheduleDetails])

  const closePopup = () => {
    dispatch(toggleShareReportPopup({ isOpen: false, isEditMode: false, report: {} }))
    setAction(undefined)
  }

  const handleCancel = () => {
    closePopup()
  }

  const buildCreateReportPayload = ({ forceSendingOnce = false }: { forceSendingOnce?: boolean } = {}) => {
    const fieldsById: Dictionary<InputSchemaFieldWithValue> = keyBy(action?.fields, 'id')
    const sharingMethod = fieldsById.shareMethod.value.value

    const fieldSets = {
      [SHARE_METHOD.SLACK]: ['shareMethod', 'account', 'users', 'channels', 'messageTitle', 'messageText'],
      [SHARE_METHOD.EMAIL]: ['shareMethod', 'to', 'cc', 'subject', 'content']
    }

    const getFields = (method: SHARE_METHOD) =>
      action?.fields
        .filter((field) => fieldSets[method].includes(field.id))
        .map(({ id, value, isValid }) => ({ id, value, isValid }))

    const messageDetails = { fields: getFields(sharingMethod) }

    const basePayload = { reportKey, reportName, config, messageDetails }
    const payload = forceSendingOnce || shareOption === SHARE_OPTION.ONCE
      ? { ...basePayload, scheduleFrequency: SCHEDULE_FREQUENCY.ONCE as SCHEDULE_FREQUENCY.ONCE }
      : {
        ...basePayload,
        scheduleFrequency,
        timeZone,
        cron: buildCron({
          scheduleFrequency,
          dayOfMonth: parseInt(dayOfMonth, 10),
          dayOfWeek: parseInt(dayOfWeek, 10),
          timeOfDay
        })
      }

    return payload
  }

  const handleSubmit = () => {
    const payload = buildCreateReportPayload()
    if (isEditMode) {
      const { reportName, messageDetails, scheduleFrequency } = payload
      let scheduleDetails

      if (scheduleFrequency !== SCHEDULE_FREQUENCY.ONCE) {
        const { cron, timeZone } = payload
        scheduleDetails = { cron, timeZone }
      }
      dispatch(updateReport({ idOrg, idReport: report.id, reportName, messageDetails, scheduleDetails: { ...scheduleDetails, scheduleFrequency } }))
    } else {
      dispatch(createReport({ idOrg, payload }))
      const toastParams = isScheduled
        ? {
          message: `Report scheduled successfully`,
          type: ToastType.SUCCESS,
          action: { label: 'View', onClick: () => history.push(`/team/${idOrg}/reports/scheduledReports`) }
        }
        : {
          message: `Your report is being shared. You’ll receive an email confirmation soon`,
          type: ToastType.INFO
        }

      toast(toastParams)
    }

    closePopup()
  }

  const handleFieldChange = ({ selectedValue, fieldId }) => {
    const isValid = true
    const updatedAction = updateActionFieldValue(action, selectedValue, fieldId, isValid)
    setAction(updatedAction)
    setShouldRenderTestButton(true)
  }

  const isDataValid = (): boolean => {
    if (!action?.type) {
      return false
    }

    let isDataValid = true
    const actionConfig = actionsConfig.find(actionConfig => actionConfig.type === action.type) || { inputSchema: [] }

    Object.keys(actionConfig.inputSchema).forEach(fieldId => {
      const fieldConfig = actionConfig.inputSchema[fieldId]
      const fieldInAction = action.fields.find(field => field.id === fieldConfig.id)

      let isHiddenField = false
      if (fieldConfig.showOnFieldSpecificValue) {
        const dependsOnField = action.fields.find(field => field.id === fieldConfig.showOnFieldSpecificValue.id)
        const dependsOnFieldValue = get(dependsOnField, ['value', 'value']) || dependsOnField?.value
        isHiddenField = dependsOnFieldValue !== fieldConfig.showOnFieldSpecificValue.value
      }

      if (!isHiddenField && fieldConfig.validations.includes('required') && (!fieldInAction?.value || (isArray(fieldInAction.value) && fieldInAction.value.length === 0))) {
        isDataValid = false
      }

      if (!isHiddenField && fieldConfig.validations.includes('notEmptyArray') && (!fieldInAction?.value || fieldInAction.value.length === 0)) {
        isDataValid = false
      }
    })

    return isDataValid
  }

  const handleTestReportButton = () => {
    const payload = buildCreateReportPayload({ forceSendingOnce: true })
    dispatch(createReport({ idOrg, payload }))
    setShouldRenderTestButton(false)
  }

  const renderTestReportButton = () => (
    <Stack gap={'space-050'} direction={'row'}>
      <Button label={'Test this report now'} onClick={handleTestReportButton} disabled={!isDataValid()} type={ButtonType.compact} size={ButtonSize.medium} />
      <Tooltip label={'This will share the report immediately based on the current setting'}>
        <Icon name={'Info'} />
      </Tooltip>
    </Stack>
  )

  const renderWeHaveSentIt = () => (
    <Stack gap={'space-050'} direction={'row'}>
      <Icon name={'CheckCircle'} color={'success'} />
      <Body1 color={'success'}>We've sent it! You'll receive an email confirmation soon</Body1>
    </Stack>
  )

  const renderScheduleDetails = () => (
    <>
      <FormElement label={'Set report schedule'}>
        <Body2>
          The report columns & filters are static, and future updates to your views will not be reflected. <Link href={'https://support.toriihq.com/hc/en-us/articles/28179790220699-Share-Schedule-Reports-from-Torii'} target='_blank'>Learn more</Link>
        </Body2>
      </FormElement>
      <FormElement label={'Schedule frequency'} required>
        <ToriiSelect
          options={scheduleFrequencyOptions}
          value={scheduleFrequency}
          onChange={option => { setScheduleFrequency(get(option, 'value')) }}
        />
      </FormElement>
      {scheduleFrequency === SCHEDULE_FREQUENCY.WEEKLY && <FormElement label='Day of week' required>
        <ToriiSelect
          options={daysOfWeekOptions}
          value={dayOfWeek}
          onChange={option => { setDayOfWeek(get(option, 'value')) }}
        />
      </FormElement>}
      {scheduleFrequency === SCHEDULE_FREQUENCY.MONTHLY && <FormElement label='Day of month' required>
        <ToriiSelect
          options={getDaysOfMonthOptions()}
          value={dayOfMonth}
          onChange={option => { setDayOfMonth(get(option, 'value')) }}
        />
      </FormElement>}
      <Stack direction='row' gap='space-100'>
        <FormElement label={'Time of day'} required>
          <ToriiSelect
            options={hourOptions}
            value={timeOfDay}
            onChange={option => { setTimeOfDay(get(option, 'value')) }}
          />
        </FormElement>
        <FormElement label={'Timezone'} required>
          <SelectTimezone
            selectedValue={timeZone}
            onChange={option => { setTimeZone(get(option, 'value')) }}
          />
        </FormElement>
      </Stack>
    </>
  )

  return (
    <ToriiPopup
      isOpen={isOpen}
      onCloseAction={handleCancel}
      styles={{ modal: { width: '520px' } }}
    >
      <ToriiPopup.Header header={isEditMode ? 'Edit report' : 'Share as report'} subHeader={isEditMode ? '' : 'Report will be shared as a CSV'} />
      <ToriiPopup.Content contentAreaStyle={{ paddingBottom: '0px' }}>
        <Placeholder loading={!action?.type} rows={20} type={'text'} style={Styles.placeholderStyle}>
          <FormElement label={'Report name'} required>
            <Input value={reportName} onChange={e => {
              setReportName(get(e, 'target.value'))
              setShouldRenderTestButton(true)
            }} />
          </FormElement>
          <Spacer top={'space-200'}><></></Spacer>
          <Divider orientation='Vertical' />
          { action && <EditWorkflowAction action={action} onChange={handleFieldChange} noBorder /> }
          <Stack gap={'space-200'} direction={'column'}>
            <Divider orientation='Vertical' />
            <FormElement label='Select your preferred sharing option' required>
              <Stack gap={'space-100'} direction={'column'}>
                <RadioButton
                  label={<Tooltip label='Enterprise plan feature' hide={isScheduleReportsFeatureInPlan}><Stack gap={'space-050'} direction={'row'}>Scheduled (Recurring){!isScheduleReportsFeatureInPlan && <Icon name='Lock' color='disabled' />}</Stack></Tooltip>}
                  onChange={() => { setShareOption(SHARE_OPTION.SCHEDULED) }}
                  checked={shareOption === SHARE_OPTION.SCHEDULED}
                  disabled={!isScheduleReportsFeatureInPlan}
                />
                <RadioButton
                  label='Share now (Once)'
                  onChange={() => { setShareOption(SHARE_OPTION.ONCE) }}
                  checked={shareOption === SHARE_OPTION.ONCE}
                />
              </Stack>
            </FormElement>
            {shareOption === SHARE_OPTION.SCHEDULED && renderScheduleDetails()}
            {isScheduled && (shouldRenderTestButton ? renderTestReportButton() : renderWeHaveSentIt())}
          </Stack>
        </Placeholder>
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        showCancelButton
        cancelButtonAction={handleCancel}
        cancelButtonText='Cancel'
        mainButtonAction={handleSubmit}
        mainButtonText={isEditMode ? 'Save' : isScheduled ? 'Schedule' : 'Share'}
        isMainButtonDisabled={!isDataValid()}
      />
    </ToriiPopup>
  )
}

export default ShareReportPopup
