import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../../../shared/style/colors'
import texts from '../../../../shared/style/texts'
import { clearButton } from '../../../../shared/style/mixins'
import { TextBadge } from '@toriihq/design-system'

const CSS = {
  main: css(clearButton, {
    display: 'inline-block',
    verticalAlign: 'top',
    width: '202px',
    height: '248px',
    borderRadius: '4px',
    backgroundColor: colors.white,
    border: `1px solid ${colors.border}`,
    margin: '10px',
    transition: 'boxShadow .2s, borderColor, .2s',
    '&:hover,&:focus': {
      boxShadow: '0 9px 12px 0 rgba(0,0,0,0.05)',
      borderColor: colors.darkBorder
    },
    ':disabled': {
      opacity: 0.5,
      cursor: 'not-allowed'
    }
  }),
  isSelected: css({
    borderColor: `${colors.blue} !important`
  }),
  content: css({
    position: 'relative',
    height: '100%',
    padding: '10px 0'
  }),
  badgeWrapper: css({
    position: 'absolute',
    top: '16px',
    right: '16px'
  }),
  imageContainer: css({
    height: '130px',
    marginBottom: '10px',
    opacity: 0.5,
    transition: 'opacity .2s'
  }),
  imageContainerSelected: css({
    opacity: 1
  }),
  label: css(texts.heading, {
    marginBottom: '5px'
  }),
  labelSelected: css({
    color: colors.blue
  }),
  disabled: css({
    cursor: 'not-allowed !important'
  })
}

const SelectionBlockItem = ({ label, description, badge, badgeColor = 'blue', isSelected, disabled, src, onChange }) => (
  <button {...css(CSS.main, isSelected && CSS.isSelected, disabled && CSS.disabled)} onClick={onChange} disabled={disabled}>
    <div {...CSS.content}>
      <div {...css(CSS.imageContainer, isSelected && CSS.imageContainerSelected)}><img src={src} alt={label} height='100%' /></div>
      <div {...css(CSS.label, isSelected && CSS.labelSelected)}>{label}</div>
      <div {...texts.body}>{description}</div>
      {badge && (
        <div {...CSS.badgeWrapper}>
          <TextBadge color={badgeColor} size='Small'>{badge}</TextBadge>
        </div>
      )}
    </div>
  </button>
)

SelectionBlockItem.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  badge: PropTypes.string,
  badgeColor: PropTypes.string,
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  src: PropTypes.string,
  onChange: PropTypes.func
}

export default SelectionBlockItem
