import { createSelector } from 'reselect'
import identity from 'lodash/identity'
import capitalize from 'lodash/capitalize'

const ssoProviderName = (ssoProvider) => {
  const ssoProviderSourceToName = {
    google: 'Google',
    okta: 'Okta',
    azure_ad: 'Microsoft Entra ID',
    onelogin: 'OneLogin',
    jump_cloud: 'JumpCloud',
    rippling: 'Rippling'
  }

  return ssoProvider && (ssoProviderSourceToName[ssoProvider] || capitalize(ssoProvider))
}

export const isSamlConfigurationLoading = createSelector(
  state => state.samlConfiguration.loading,
  identity
)

export const getIsSSOEnabled = createSelector(
  state => state.samlConfiguration.enabled,
  identity
)

export const getIsSSOEnforced = createSelector(
  state => !state.samlConfiguration.allowLoginsViaEmailAndPassword,
  identity
)

export const getSSOProvider = createSelector(
  state => state.samlConfiguration.source,
  ssoProviderName
)
