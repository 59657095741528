import React, { ReactElement } from 'react'
import { StyleAttribute } from 'glamor'
import FormGroup from '@components/form/formGroup'
import { WORKFLOW_TRIGGER_TYPE } from '@root/shared/types'
import { formFieldTypeToConfigMap } from './mapping/fieldMapping'
import { labelActionMapping } from './mapping/labelMapping'
import { ActionWithIdNode } from '../types'
import { FormFieldInputProps } from './types'
import { FetchTriggerValuesParams } from '@pages/workflow_v2/triggerConfiguration/triggerStepSetup/types'
import InfoTooltip from '@components/infoTooltip'

interface Props {
  className?: string
  field: any
  input: FormFieldInputProps
  index: number
  meta: { error?: string, touched: boolean }
  addFieldIndex?: boolean
  action: ActionWithIdNode
  triggerType: WORKFLOW_TRIGGER_TYPE
  personalization: { id: string, display: string}[]
  refreshDynamicFieldsOptions: () => void
  fetchTriggerValues: (params?: FetchTriggerValuesParams) => void
  getTriggerPreview?: ({ idOrg, idWorkflow }: {idOrg: number, idWorkflow: number }) => void
  idActionExe?: number
  id?: string
  secret?: string
  overrideLabel?: string
  overrideStyleComponent?: StyleAttribute | {}
  fieldParams?: any
}

export const FormFieldV2 = ({
  className = '',
  field,
  input,
  index,
  meta: { touched, error },
  addFieldIndex = true,
  action,
  triggerType,
  personalization,
  refreshDynamicFieldsOptions,
  fetchTriggerValues,
  getTriggerPreview,
  idActionExe,
  id,
  secret,
  overrideLabel,
  fieldParams
}: Props): ReactElement => {
  const labelPrefix = addFieldIndex ? `${index + 1}. ` : ''
  const hasLabel = overrideLabel || field.formQuestion || field.name || field.label
  const label = hasLabel ? overrideLabel || `${labelPrefix}${field.formQuestion || field.name || field.label}` : null
  const details = field.details
  const component = formFieldTypeToConfigMap[field.type]?.component({
    field,
    input,
    action,
    triggerType,
    personalization,
    refreshDynamicFieldsOptions,
    fetchTriggerValues,
    getTriggerPreview,
    idActionExe,
    id,
    secret,
    className,
    fieldParams
  })
  const labelAction = field.labelAction ? labelActionMapping[field.labelAction].component({ action, field }) : null
  const tooltip = field.tooltipText ? <InfoTooltip tooltipText={field.tooltipText} /> : null

  return (
    <FormGroup label={label || null} tooltip={tooltip} labelAction={labelAction} details={details} error={touched && error} isRequired={field.isRequired || false} learnMoreUrl={field.learnMoreUrl}>
      {component}
    </FormGroup>
  )
}
