import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import defer from 'lodash/defer'
import colors from '../../../shared/style/colors'
import texts from '../../../shared/style/texts'
import { fontSize } from '../../../shared/style/sizes'
import Analytics from '../../../helpers/analytics'
import InactiveUsersPopup from '../../popups/inactiveUsersPopup'
import LicenseTooltipContent from '../licenseTooltipContent'
import { formatNumber } from '@root/shared/utils'
import { Popover } from '@toriihq/design-system'
import EnableFor from '@root/components/enableFor'
import { SCOPES } from '@root/constants'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'

const CSS = {
  main: css({
    display: 'flex',
    alignItems: 'center'
  }),
  barContainer: css({
    display: 'flex',
    alignItems: 'center',
    marginRight: '15px',
    width: '100%'
  }),
  licenseName: css(texts.body, {
    width: '120px',
    minWidth: '120px',
    color: colors.grey1,
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: '5px 5px 5px 0'
  }),
  totalAmount: css(texts.body, {
    width: '50px',
    color: colors.grey1,
    marginLeft: 'auto',
    textAlign: 'right',
    height: '16px',
    margin: '10px 0 10px auto'
  }),
  bar: css({
    position: 'relative',
    display: 'flex',
    verticalAlign: 'middle',
    overflow: 'hidden',
    borderRadius: '2px',
    margin: '10px 0 10px 5px',
    width: '100%',
    transition: 'width .3s ease-in-out, min-width .3s ease-in-out'
  }),
  progress: css({
    width: '100%'
  }),
  active: css({
    backgroundColor: colors.lightBlue4
  }),
  inactive: css({
    backgroundColor: colors.warning,
    cursor: 'pointer',
    '[disabled]': {
      cursor: 'inherit'
    }
  }),
  disabledInactive: css({
    backgroundColor: colors.warning,
    cursor: 'default',
    '[disabled]': {
      cursor: 'inherit'
    }
  }),
  unassigned: css({
    backgroundColor: colors.grey2
  }),
  square: css({
    width: '8px',
    height: '8px',
    marginRight: '5px',
    backgroundColor: colors.lightBlue2
  }),
  headerLine: css(texts.heading, {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px'
  }),
  commentLower: css(texts.caption, {
    color: colors.grey2,
    fontSize: fontSize.small
  }),
  number: css(texts.headers.regular, {
    marginLeft: 'auto'
  }),
  description: css(texts.subheading, {
    '> div': { paddingTop: '10px' },
    '> div > span': { float: 'right', color: colors.black }
  })
}

class LicenseBar extends React.Component {
  state = {
    rendered: false,
    isOpen: false
  }

  componentDidMount () {
    defer(this.initialAnimation)
  }

  initialAnimation = () => {
    this.setState({
      rendered: true
    })
  }

  showHidePopup = () => {
    const { idApp } = this.props
    Analytics.track(`Clicked ${!this.state.isOpen ? 'show' : 'hide'} application wasted users`, {
      'App id': idApp
    })
  }

  togglePopup ({ savings }) {
    const { disableInactiveClick } = this.props
    if (!disableInactiveClick) {
      this.showHidePopup()
      this.setState({ isOpen: !this.state.isOpen, savings })
    }
  }

  closePopup = () => {
    this.showHidePopup()
    this.setState({ isOpen: !this.state.isOpen })
  }

  render () {
    const { rendered, isOpen } = this.state
    const { inactiveAmount, inactiveReasons, unassignedAmount, activeAmount, barWidth, minBarWidth = '50px', name, totalAmount, barHeight = '16px', barOnly, idLicense, idApp, idOrg, disableInactiveClick } = this.props
    const totalLicenses = inactiveAmount + unassignedAmount + activeAmount
    const activeWidth = (activeAmount / totalLicenses) * 100
    const inactiveWidth = (inactiveAmount / totalLicenses) * 100
    const unassignedWidth = (unassignedAmount / totalLicenses) * 100
    const heightCSS = { height: barHeight }
    const { noUsage, noAccess, leftOrg } = inactiveReasons
    return (
      <div {...CSS.main}>
        <div {...CSS.barContainer}>
          {!barOnly &&
            <div {...CSS.licenseName}>{name}</div>
          }
          <div id={`${idLicense}_${idApp}_bar`} {...css(CSS.bar)} style={{ width: `${rendered ? barWidth : 0}%`, minWidth: rendered ? minBarWidth : 0, display: 'grid', gridTemplateColumns: `${activeWidth}% ${inactiveWidth}% ${unassignedWidth}%` }}>
            <Popover
              showArrow
              content={<LicenseTooltipContent
                header='Active Licenses'
                description='Assigned and being used'
                amount={activeAmount}
                colorCss={CSS.active}
              />}
            >
              <div {...css(CSS.progress, CSS.active, heightCSS)} />
            </Popover>
            <Popover
              showArrow
              content={<LicenseTooltipContent
                header='Inactive Licenses'
                description={(
                  <div {...CSS.description}>
                    {noUsage > 0 && <div>
                      Assigned but not being used
                      <span>{formatNumber(noUsage)}</span>
                    </div>}
                    {noAccess > 0 && <div>
                      Assigned but with no access to service
                      <span>{formatNumber(noAccess)}</span>
                    </div>}
                    {leftOrg > 0 && <div>
                      Assigned to past users
                      <span>{formatNumber(leftOrg)}</span>
                    </div>}
                  </div>
                )}
                amount={inactiveAmount}
                colorCss={CSS.inactive}
              />}
            >
              <EnableFor
                scopes={[
                  `${SCOPES.USERS_READ}`,
                  `${getScopeByIdOrgAndIdApp(SCOPES.USERS_READ, idOrg, idApp)}`
                ]}
                allowForToriiAdmin
                showAsDisabled={false}
              >
                <div
                  id='inactive'
                  onClick={this.togglePopup.bind(this)}
                  {...css(CSS.progress, disableInactiveClick ? CSS.disabledInactive : CSS.inactive, heightCSS)}
                />
              </EnableFor>
            </Popover>
            <Popover
              showArrow
              content={<LicenseTooltipContent
                header='Unassigned Licenses'
                description='Available licenses not assigned to users'
                amount={unassignedAmount}
                colorCss={CSS.unassigned}
              />}
            >
              <div {...css(CSS.progress, CSS.unassigned, heightCSS)} />
            </Popover>
          </div>
        </div>
        {!barOnly && <div {...CSS.totalAmount}>{formatNumber(totalAmount)}</div>}
        {isOpen && <InactiveUsersPopup isOpen={isOpen} idApp={idApp} idLicense={idLicense} savings={this.state.savings}
          cancel={this.closePopup} inactiveAmount={inactiveAmount} />}
      </div>
    )
  }
}

LicenseBar.propTypes = {
  idLicense: PropTypes.number,
  unassignedAmount: PropTypes.number,
  inactiveAmount: PropTypes.number,
  inactiveReasons: PropTypes.shape({
    noAccess: PropTypes.number,
    leftOrg: PropTypes.number,
    noUsage: PropTypes.number
  }),
  activeAmount: PropTypes.number,
  name: PropTypes.string,
  barWidth: PropTypes.number,
  onInactiveClicked: PropTypes.func
}

export default LicenseBar
