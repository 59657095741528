import React, { Component } from 'react'
import { ToriiSelectAsync } from '../select'
import PropTypes from 'prop-types'
import debouncePromise from 'debounce-promise'
import UserDetails from '../userDetails'
import { getDisplayName } from '../../lenses/users'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import { simpleEmailRegex } from '@root/constants'
import { keyBy } from 'lodash'

const VALUE_KEY = 'id'

class SelectUsers extends Component {
  state = {
    selectedUser: this.props.value
  }

  componentDidUpdate (prevProps) {
    if (prevProps.value !== this.props.value) {
      if (isString(this.props.value)) {
        this.setState({ selectedUser: this.props.specialUsers[this.props.value] })
      }
    }
  }

  onChange = (selectedUser) => {
    const { onChange } = this.props
    this.setState({ selectedUser }, () => {
      onChange && onChange(selectedUser)
    })
  }

  fetchData = async ({ searchValue, idUser }) => {
    const { idOrg, searchUsers, isExternal, excludePastUsers, showSelectedChildren } = this.props
    const idUsers = idUser ? [idUser] : undefined
    const includeChildren = showSelectedChildren && idUser
    const result = await searchUsers({ idOrg, idUsers, q: searchValue, limit: 10, isExternal, excludePastUsers, includeChildren })
    return result.users
  }

  debouncedFetchData = debouncePromise(this.fetchData, 500)

  initialGetOptions = true
  getOptions = async (searchValue) => {
    const { specialUsers, value, creatable } = this.props

    if (!searchValue) {
      if (this.initialGetOptions) {
        this.initialGetOptions = false

        if (isNumber(value)) {
          const users = await this.fetchData({ searchValue, idUser: value })
          this.setState({ selectedUser: users[0] })
        } if (isString(value)) {
          this.setState({ selectedUser: specialUsers[value] })
        } else if (isString(value?.value)) {
          this.setState({ selectedUser: specialUsers[value.value] })
        }
      }

      const initialRandomUsers = await this.fetchData({})
      return { options: Object.values(specialUsers).concat(initialRandomUsers) }
    }

    const specialUsersFiltered = Object.values(specialUsers).filter(user => {
      return user.label && user.label.toUpperCase().includes(searchValue.toUpperCase())
    })

    if (creatable && simpleEmailRegex.test(searchValue)) {
      return { options: [...specialUsersFiltered, { id: searchValue, label: searchValue, customEmail: true, noCheckbox: true }].map(o => ({ ...o, value: o.value || o.id, label: o.label || o.name })) }
    }

    const options = await this.debouncedFetchData({ searchValue })
    return { options: [...specialUsersFiltered, ...options] }
  }

  renderOption = ({ data: user }) => {
    const { specialUsers, idAppForUsingAppBadge } = this.props

    const badges = []
    if (specialUsers[user.id] && specialUsers[user.id].isSuggestedOwner) {
      badges.push({ text: 'Suggested', color: 'navy' })
    }
    if (user.idApps && user.idApps.includes(idAppForUsingAppBadge)) {
      badges.push({ text: 'Using app', color: 'gray' })
    }

    return <UserDetails
      key={user.id}
      overrideStyle={{ padding: 0, width: '100%' }}
      badge={badges}
      idUser={user.id}
      linkToUserPage={false}
      {...user}
    />
  }

  renderValue = ({ data: user }) => getDisplayName(user)

  filterOption = ({ data: user }) => {
    const { selectedUser } = this.state
    const { value, excludedOptions = [] } = this.props

    const filteredOptionsById = keyBy(excludedOptions, VALUE_KEY)
    if (filteredOptionsById[user[VALUE_KEY]]) {
      return false
    }

    if (!selectedUser && !value) {
      return true
    }

    return [].concat((selectedUser || value)).filter(Boolean).find(selectedUser => selectedUser[VALUE_KEY] === user[VALUE_KEY]) === undefined
  }

  render () {
    const {
      placeholder,
      value,
      closeOnSelect,
      excludedOptions,
      ...restSelectParams
    } = this.props
    const { selectedUser } = this.state

    return (
      <ToriiSelectAsync
        ref={select => { this.select = select }}
        optionRenderer={this.renderOption}
        valueRenderer={this.renderValue}
        valueKey={VALUE_KEY}
        labelKey='name'
        placeholder={placeholder}
        backspaceRemoves={false}
        clearable={false}
        autoBlur
        filterOption={this.filterOption}
        noResultsText='No users found'
        loadOptions={this.getOptions}
        cache={false}
        loadingPlaceholder={'Loading...'}
        defaultOptions
        {...restSelectParams}
        value={selectedUser || value}
        onChange={this.onChange}
        closeOnSelect={closeOnSelect ?? !restSelectParams.multi}
        selectWidth='100%'
      />
    )
  }
}

SelectUsers.propTypes = {
  placeholder: PropTypes.string,
  idAppForUsingAppBadge: PropTypes.number,
  creatable: PropTypes.bool,
  excludedOptions: PropTypes.array
}

SelectUsers.defaultProps = {
  placeholder: 'Search User...',
  specialUsers: {},
  excludedOptions: [],
  borderless: false
}

export default SelectUsers
