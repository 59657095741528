import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ToriiPopup from '../ToriiPopupV2'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import { Field } from 'react-final-form'
import { WORKFLOW_OUTPUT_FIELD_TYPES, WORKFLOW_TRIGGER_TYPES, WORKFLOW_TYPE_TO_ITEM_NAME, WORKFLOW_TYPES } from '@root/constants'
import FormGroup from '../../form/formGroup'
import SelectApps from '../../selectApps'
import SelectContracts from '../../selectContracts'
import SelectLicenses from '../../selectLicenses'
import SelectUsers from '../../selectUsers'
import { FORM_ERROR } from 'final-form'
import Analytics from '../../../helpers/analytics'
import { fontSize, fontWeight } from '@shared/style/sizes'
import { customJoin } from '@lenses/utils'
import pluralize from 'pluralize'
import { getPopupHeader } from '@components/popups/manuallyRunWorkflowPopup/utils'
import Spinner from '@media/spinner.svg'
import { Link, AlertBox, AlertBoxType, TextArea } from '@toriihq/design-system'
import { UserDetailChange } from './userDetailChange'

const CSS = {
  formTitle: css({
    fontWeight: fontWeight.normal,
    fontSize: fontSize.medium,
    lineHeight: '19px',
    color: colors.grey1
  }),
  noFieldsTitle: css({
    textAlign: 'center',
    fontWeight: fontWeight.normal,
    fontSize: fontSize.medium,
    lineHeight: '15px',
    color: colors.grey1
  }),
  singleUserSelect: css({
    width: '100%'
  }),
  loadingImage: css({
    marginBottom: 10
  }),
  select: css({
    width: '100%'
  }),
  alignment: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }),
  loadingText: css({
    fontSize: fontSize.small,
    fontWeight: fontWeight.normal,
    color: colors.grey1
  })
}

const componentsMap = {
  [WORKFLOW_OUTPUT_FIELD_TYPES.USER]: props => <SelectUsers className={CSS.select.toString()} {...props} />,
  [WORKFLOW_OUTPUT_FIELD_TYPES.APP]: (props) => <SelectApps {...props} includeAllApps={false} />,
  [WORKFLOW_OUTPUT_FIELD_TYPES.LICENSE]: SelectLicenses,
  [WORKFLOW_OUTPUT_FIELD_TYPES.CONTRACT]: SelectContracts,
  [WORKFLOW_OUTPUT_FIELD_TYPES.TEXT]: TextArea,
  [WORKFLOW_OUTPUT_FIELD_TYPES.USER_DETAIL_CHANGE]: UserDetailChange,
  [WORKFLOW_OUTPUT_FIELD_TYPES.OBJECT]: TextArea
}

const validationsMap = {
  [WORKFLOW_OUTPUT_FIELD_TYPES.OBJECT]: value => {
    if (typeof value !== 'string') {
      return 'Invalid JSON'
    }
    try {
      const parsedInput = JSON.parse(value)
      if (typeof parsedInput !== 'object') {
        return 'Invalid JSON'
      }
      return undefined
    } catch (e) {
      return 'Invalid JSON'
    }
  }
}

const isValidFieldValue = value => value && value.isValid !== false

class ManuallyRunWorkflowPopup extends React.Component {
  state = { done: false, loading: false }

  component = ({ input, label, meta: { touched, error, initial } }) => {
    const { triggerConfiguration } = this.props
    const hasInitialValue = Boolean(initial)
    const Component = componentsMap[input.type]
    return <FormGroup label={label} error={touched && error}>
      <Component {...input} triggerConfiguration={triggerConfiguration} disabled={hasInitialValue} />
    </FormGroup>
  }

  onSubmit = async data => {
    const { idOrg, idWorkflow, triggerWorkflow, workflowName, triggerType, close, toggleWorkflowStartedPopup, workflowType } = this.props

    try {
      this.setState({ loading: true })
      await triggerWorkflow({ idOrg, idWorkflow, data })
      Analytics.track('Run workflow-actions-dialog', {
        'Workflow name': workflowName,
        'Workflow ID': idWorkflow,
        'Trigger name': triggerType
      })

      this.setState({ loading: false })
      close()
      toggleWorkflowStartedPopup({ isOpen: true, idWorkflow, workflowName, triggerType, referrer: getPopupHeader(workflowType), entitiesAmount: 1, workflowType })
    } catch (e) {
      this.setState({ loading: false })
      return { [FORM_ERROR]: 'Error, please try again' }
    }
  }

  onCancel = ({ buttonLabel }) => {
    const { idWorkflow, triggerType, close } = this.props

    Analytics.track(`Close run-workflow-actions-dialog`, {
      'Trigger name': triggerType,
      'Button label': buttonLabel,
      'Workflow ID': idWorkflow
    })

    close()
  }

  renderLoading () {
    return (
      <div {...css(CSS.alignment)}>
        <img alt='Running...' src={Spinner} width='26px' height='26px' {...CSS.loadingImage} />
        <div {...CSS.loadingText}>Running...</div>
      </div>
    )
  }

  getAlertBoxDescriptionByTriggerType = ({ fieldsTypes, itemName, triggerType }) => {
    switch (triggerType) {
      case WORKFLOW_TRIGGER_TYPES.USER_DETAIL_CHANGED:
        return `Run the ${itemName} actions on the selected user.`
      case WORKFLOW_TRIGGER_TYPES.CUSTOM_WEBHOOK_RECEIVED:
        return `Run the ${itemName} actions on the manually added JSON.If you leave the field empty, the actions will run ignoring any input from the trigger.`
      default:
        return `Run the ${itemName} actions on the selected ${customJoin(fieldsTypes)}. The trigger criteria will be ignored.`
    }
  }

  render () {
    const { isOpen, workflowName, outputSchema, formInitialValues, workflowType, triggerType } = this.props
    const { loading } = this.state
    const outputKeys = outputSchema ? Object.keys(outputSchema).filter(key => outputSchema[key].isMainData) : []
    const outputKeyTypes = outputKeys.map(outputKey => outputSchema[outputKey].type).filter(outputKeyType => outputKeyType && outputKeyType !== WORKFLOW_OUTPUT_FIELD_TYPES.TEXT)
    const hasFields = Boolean(outputKeys.length)
    const itemName = WORKFLOW_TYPE_TO_ITEM_NAME[workflowType]

    return <ToriiPopup
      isOpen={isOpen}
      onCloseAction={() => this.onCancel({ buttonLabel: 'X' })}
      styles={{ modal: { display: 'flex', flexDirection: 'column' } }}
    >
      <ToriiPopup.Header header={getPopupHeader(workflowType)} subHeader={workflowName} />
      <ToriiPopup.Form
        onSubmit={this.onSubmit}
        initialValues={formInitialValues}
        render={(formProps) => {
          if (loading) {
            return this.renderLoading()
          } else {
            const { handleSubmit } = formProps
            formProps.pristine = outputKeys.length ? formProps.pristine : false
            return <Fragment>
              {formProps.submitError && <span style={{ color: colors.red }}>{formProps.submitError}</span>}
              {hasFields && <AlertBox type={AlertBoxType.INFORMATIVE} description={(
                <div>
                  <div>{this.getAlertBoxDescriptionByTriggerType({ fieldsTypes: outputKeyTypes, itemName, triggerType })}</div>
                  <Link href={'https://support.toriihq.com/hc/en-us/articles/4702411787163'} target='_blank'>{`Read more about running ${pluralize(itemName)} manually`}</Link>
                </div>
              )} />}
              {!hasFields && <div {...CSS.noFieldsTitle}>Test your workflow by running it now</div>}
              <form {...CSS.form} onSubmit={handleSubmit}>
                <div>
                  {hasFields && outputKeys.map(outputKey => {
                    const { id, type, name, optionalForManualRun } = outputSchema[outputKey]
                    let initialValue = type === WORKFLOW_OUTPUT_FIELD_TYPES.TEXT ? '' : undefined
                    return <Field
                      key={id}
                      type={type}
                      name={id}
                      label={name}
                      component={this.component}
                      validate={value => {
                        if (!value && optionalForManualRun) {
                          return undefined
                        } else if (validationsMap[type]) {
                          return validationsMap[type](value)
                        } else if (isValidFieldValue(value)) {
                          return undefined
                        } else {
                          return 'Required'
                        }
                      }}
                      parse={value => value}
                      initialValue={initialValue}
                    />
                  })}
                </div>
              </form>
            </Fragment>
          }
        }
        }
        renderFooter={(formProps) => {
          return (<ToriiPopup.Footer
            cancelButtonText={'Cancel'}
            cancelButtonAction={() => this.onCancel({ buttonLabel: 'Cancel' })}
            mainButtonText={'Run now'}
            formProps={formProps}
            isMainButtonDisabled={!formProps.valid}
            isMainSubmit
            allowPristine
            recoveryTime={500}
          />)
        }} />
    </ToriiPopup>
  }
}

ManuallyRunWorkflowPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func,
  idWorkflow: PropTypes.number,
  triggerType: PropTypes.string,
  workflowName: PropTypes.string,
  workflowType: PropTypes.string,
  formInitialValues: PropTypes.object
}

ManuallyRunWorkflowPopup.defaultProps = {
  workflowType: WORKFLOW_TYPES.regular
}

export default ManuallyRunWorkflowPopup
