import React from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import TabsPage from '@pages/tabs'
import Reports from './reportsTabs/reports'
import ScheduledReports from '@components/scheduledReportsTable'
import { FEATURES } from '@shared/features'
import { getIdOrg } from '@selectors/org'
import { TABLES } from '@root/constants'
import { isFeatureEnabled } from '@lenses/features'

const ReportsPage = ({ match }) => {
  const idOrg = useSelector(getIdOrg)
  const shouldDisplayScheduledReportsTab = useSelector(state => isFeatureEnabled(state, 'scheduledReports'))

  const tabsName = shouldDisplayScheduledReportsTab ? [
    'reports',
    'scheduledReports'
  ] : ['reports']

  const tabsHeader = shouldDisplayScheduledReportsTab ? [
    { header: 'Reports' },
    { header: 'Scheduled reports' }
  ] : [{ header: 'Reports' }]

  const tabsContent = shouldDisplayScheduledReportsTab ? [
    <Reports idOrg={idOrg} />,
    <ScheduledReports idOrg={idOrg} tableKey={TABLES.scheduledReportsTable.key} />
  ] : [<Reports idOrg={idOrg} />]

  const tabsFeature = shouldDisplayScheduledReportsTab ? [
    FEATURES.REPORTS.TABS.REPORTS,
    shouldDisplayScheduledReportsTab ? FEATURES.REPORTS.TABS.SCHEDULED_REPORTS : null
  ] : [FEATURES.REPORTS.TABS.REPORTS]

  return (
    <TabsPage
      pageHeader='Reports'
      pageTitle='Reports'
      pageName='Reports'
      path={`/team/${idOrg}/reports`}
      tabsName={tabsName}
      tabsHeader={tabsHeader}
      tabsContent={tabsContent}
      tabsFeature={tabsFeature}
      forceShowTabs
    />
  )
}

export default withRouter(ReportsPage)
