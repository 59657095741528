import React from 'react'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { toggleLicenseAuditNotSupportedPopup } from '@actions/'
import { useDispatch, useSelector } from 'react-redux'
import { getLicenseAuditNotSupportedPopup } from '@selectors/ui'
import Analytics from '@helpers/analytics'
import { Body2 } from '@toriihq/design-system'

const LicenseAuditNotSupportedPopup = (): JSX.Element => {
  const dispatch = useDispatch()

  const { isOpen, appName } = useSelector(getLicenseAuditNotSupportedPopup)
  const closeButtonText = 'Close'

  const closePopup = () => {
    dispatch(toggleLicenseAuditNotSupportedPopup({ isOpen: false }))
  }

  const trackButtonClick = (buttonLabel: string) => {
    Analytics.track('Click on button', {
      'Dialog name': 'LicenseAuditNotSupportedPopup',
      'Button label': buttonLabel,
      'App name': appName
    })
  }
  const onClose = () => {
    trackButtonClick(closeButtonText)
    closePopup()
  }

  return (
    <ToriiPopup
      isOpen={isOpen}
      onCloseAction={onClose}
      styles={{ modal: { width: '600px' } }}>
      <ToriiPopup.Header
        header={`License audits not supported for this setup`}
      />
      <ToriiPopup.Content>
        <Body2>
          It looks like {appName} users are coming from multiple accounts.<br />
          Unfortunately, Torii doesn't yet support license audits for this setup.<br />
          We're actively exploring ways to enhance this functionality.
        </Body2>
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        showCancelButton
        cancelButtonAction={onClose}
        cancelButtonText={closeButtonText}
        showMainButton={false}
      />
    </ToriiPopup>
  )
}

export default LicenseAuditNotSupportedPopup
