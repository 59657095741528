import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { getContracts } from '@selectors/contracts'
import { maxBy, minBy, partition } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { getAppContracts } from '@actions/'
import EnableFor from '@components/enableFor'
import { SCOPES } from '@root/constants'
import { AlertBox, AlertBoxType, Body2, Link, Stack } from '@toriihq/design-system'
import { isFeatureEnabled } from '@root/lenses/features'

const RenewalAlertBox = (props) => {
  const dispatch = useDispatch()
  const { appName, idOrg, idApp, history, onLicenseAuditClick } = props
  const isRightsizingRecommendationEnabled = useSelector(state => isFeatureEnabled(state, 'rightsizingRecommendation'))
  const allContracts = useSelector(getContracts)
  const nextRenewalContract = getNextRenewalContract(idApp, allContracts)
  const alertBoxContent = getAlertBoxContent({
    contract: nextRenewalContract,
    appName,
    idApp,
    history,
    idOrg,
    onLicenseAuditClick,
    isRightsizingRecommendationEnabled
  })

  useEffect(() => {
    if (idOrg && idApp) {
      dispatch(getAppContracts({ idOrg, idApp }))
    }
  }, [idOrg, idApp, dispatch])

  return (alertBoxContent) ? (
    <AlertBox
      type={AlertBoxType.INFORMATIVE}
      title={alertBoxContent.title}
      description={alertBoxContent.description}
      primaryButton={alertBoxContent.button}
    />
  ) : null
}

const getNextRenewalContract = (idApp, allContracts) => {
  const contracts = allContracts.filter(contract => {
    return contract.idApp === idApp && contract.endDate && contract.status && contract.status.toLowerCase() !== 'closed'
  })

  const [past, future] = partition(contracts, contract => moment(contract.endDate).isBefore(moment()))

  if (future.length) {
    return minBy(future, 'endDate')
  }

  if (past.length) {
    return maxBy(past, 'endDate')
  }

  return null
}

const formatDate = (date) => date ? moment(date).format('D MMMM, YYYY') : ''

export const getAlertBoxContent = ({
  contract,
  appName,
  idApp,
  history,
  idOrg,
  onLicenseAuditClick,
  isRightsizingRecommendationEnabled
}) => {
  if (!contract || !appName) {
    return null
  }

  const { endDate: renewalDate, cancellationDeadline, autoRenewal } = contract

  const renewalAlertClicked = () => history.push(`/team/${idOrg}/app/${idApp}/contracts`)
  const link = <EnableFor scopes={[SCOPES.CONTRACTS_READ]}><Link onClick={renewalAlertClicked}>View contracts</Link></EnableFor>
  const auditLicenseText = <Body2>Now is the time to audit licenses, remove unused ones, and ensure an accurate license count for the renewal.</Body2>
  const auditLicenseDescription = <Stack direction='column'>{auditLicenseText}{link}</Stack>
  const cancelUntilText = `You can cancel until ${formatDate(cancellationDeadline)}.`
  const formattedRenewalDate = formatDate(renewalDate)
  const daysRemainToRenewal = moment(renewalDate).diff(moment(), 'days')
  const isRenewalInLessThan120Days = daysRemainToRenewal <= 120
  const autoRenewTitleWithDays = `${appName} will auto renew in ${daysRemainToRenewal} days (${formattedRenewalDate})`
  const autoRenewTitleWithoutDays = `${appName} will auto renew on ${formattedRenewalDate}`

  const renewalTimeFrame = isRenewalInLessThan120Days && isRightsizingRecommendationEnabled ? 'lessThan120Days' : 'moreThan120Days'

  const startLicenseButton = { label: 'Start license audit', onClick: onLicenseAuditClick }

  const renewalAlertStates = {
    lessThan120Days: {
      autoRenewal: {
        title: `${appName}'s next renewal is in ${daysRemainToRenewal} days (${formattedRenewalDate})`,
        description: auditLicenseDescription,
        button: startLicenseButton
      },
      noCancellationDeadline: {
        title: autoRenewTitleWithDays,
        description: auditLicenseDescription,
        button: startLicenseButton
      },
      renewal: {
        title: autoRenewTitleWithDays,
        description: <Stack direction='column'><Body2>{cancelUntilText}</Body2>{auditLicenseText}{link}</Stack>,
        button: startLicenseButton
      }
    },
    moreThan120Days: {
      autoRenewal: {
        title: `${appName}'s next renewal is on ${formattedRenewalDate}`,
        description: link,
        button: null
      },
      noCancellationDeadline: {
        title: autoRenewTitleWithoutDays,
        description: link,
        button: null
      },
      renewal: {
        title: autoRenewTitleWithoutDays,
        description: <div>{cancelUntilText} {link}</div>,
        button: null
      }
    },
    renewalDatePassed: {
      title: `${appName}'s renewal date has passed: ${formattedRenewalDate}`,
      description: link,
      button: null
    },
    cancellationDeadlinePassed: {
      title: autoRenewTitleWithoutDays,
      description: <div>Cancellation date has passed: {formatDate(cancellationDeadline)}. {link}</div>,
      button: null
    }
  }

  if (moment().isAfter(renewalDate)) {
    return renewalAlertStates.renewalDatePassed
  }

  if (autoRenewal !== 'Yes') {
    return renewalAlertStates[renewalTimeFrame].autoRenewal
  }

  if (!cancellationDeadline) {
    return renewalAlertStates[renewalTimeFrame].noCancellationDeadline
  }

  if (moment().isAfter(cancellationDeadline)) {
    return renewalAlertStates.cancellationDeadlinePassed
  }
  return renewalAlertStates[renewalTimeFrame].renewal
}

RenewalAlertBox.propTypes = {
  idOrg: PropTypes.number.isRequired,
  idApp: PropTypes.number.isRequired,
  appName: PropTypes.string
}

export default RenewalAlertBox
