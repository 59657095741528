import emptyImage from '@media/dance.svg'
import { useSelector } from 'react-redux'
import { EmptyState } from '@toriihq/design-system'
import { getAppUsersES, isLoadingAppUsersES } from '@selectors/appUsers'
import { EMPTY_ARRAY } from '@root/constants'

const UsersEmptyState = (props): JSX.Element | null => {
  const { idApp } = props
  const allAppUsers = useSelector(getAppUsersES)
  const users = allAppUsers[idApp] || EMPTY_ARRAY
  const isLoading = useSelector(isLoadingAppUsersES)
  if (isLoading || users.length > 0) {
    return null
  }

  return <EmptyState
    image={<img src={emptyImage} alt='No users' />}
    title={`No users to display`}
  />
}

export default UsersEmptyState
