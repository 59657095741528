import {
  GET_APP_ACCOUNT_LICENSES_USERS,
  GET_APP_USERS,
  GET_APP_USERS_COUNT,
  GET_APP_USERS_FIELD_VALUES,
  GET_APPS_OF_USER,
  GET_INACTIVE_USERS,
  UPDATE_APP_USER_INFO,
  UPDATE_IMPORT_APP_USERS_STATUS
} from '@root/constants'
import { Action, ActionTypeStatus } from '@reducers/types'
import { AppUser, AppUsersResponse } from '@actions/appUsers/types'

type AppUsersByIdApp = Record<number, AppUser[] | undefined>
type AppUserPerAccountLicense = Record<number, Record<number, {
  users?: AppUser[],
  total?: number,
  loading: boolean
} | undefined> | undefined>

export enum IMPORT_APP_USERS_STATUS {
  SYNC_IN_PROGRESS = 'SYNC_IN_PROGRESS',
  SYNC_PRESUMABLY_ENDED = 'SYNC_PRESUMABLY_ENDED'
}

type GetAppUsersAction = Action<
  `${typeof GET_APP_USERS}_${ActionTypeStatus}`,
  AppUsersResponse,
  { idApp: number, reset?: boolean }
>
type AppState = {
  loadingMore: boolean
  totalUsers?: number
}

type AppUsersStateByIdApp = Record<number, AppState | undefined>

type AppFieldName = string
type AppFieldValue = { key: any, count?: number }
type AppFieldValues = Record<AppFieldName, AppFieldValue[]>

type AppFieldValuesByIdApp = Record<number, AppFieldValues | undefined>
type GetAppUsersFieldValuesAction = Action<
  `${typeof GET_APP_USERS_FIELD_VALUES}_${ActionTypeStatus}`,
  AppFieldValues,
  { idApp: number }
>

type UserAppsByIdUser = Record<number, AppUser[] | undefined>

type AppUsersCount = {
  loading: boolean
  count?: number
}
type AppUsersCountByIdApp = Record<number, AppUsersCount | undefined>

type InactiveUsersResources = {
  apps: {
    id: number
    name: string
    category: string
    imageUrl: string
  }[],
  users: ({ id: number } & Pick<AppUser,
    'email' |
    'firstName' |
    'lastName' |
    'photoUrl' |
    'isExternal' |
    'lifecycleStatus' |
    'isDeletedInIdentitySources'
  >)[]
}

type InactiveUsersState = {
  loading: boolean,
  loadingMore: boolean,
  isLoaded: boolean,
  users: AppUser[],
  resources: InactiveUsersResources,
  total: number
}

export type AppUsersState = {
  loading: boolean
  statePerApp: AppUsersStateByIdApp
  usersPerApp: AppUsersByIdApp
  loadingUsersPerApp:boolean
  usersPerAccountLicense: AppUserPerAccountLicense
  fieldValuesPerApp: AppFieldValuesByIdApp
  appsPerUser: UserAppsByIdUser
  inactiveUsers: InactiveUsersState
  importAppUsersStatus?: IMPORT_APP_USERS_STATUS
  appUsersCount: AppUsersCountByIdApp
}

type GetAppsOfUserAction = Action<
  `${typeof GET_APPS_OF_USER}_${ActionTypeStatus}`,
  { apps: AppUser[] },
  { idUser: number }
>

type UpdateAppUserInfoAction = Action<
  `${typeof UPDATE_APP_USER_INFO}_${ActionTypeStatus}`,
  {},
  { idUser: number, idApp: number, isRemoved: boolean, reset: boolean }
>

type GetInactiveUsersAction = Action<
  `${typeof GET_INACTIVE_USERS}_${ActionTypeStatus}`,
  {
    inactiveUsers: AppUser[]
    total: number
    limit: number
    offset: number
    resources: InactiveUsersResources
  },
  { reset?: boolean }
>

type UpdateImportAppUsersStatusAction = Action<
  typeof UPDATE_IMPORT_APP_USERS_STATUS,
  { importAppUsersStatus: IMPORT_APP_USERS_STATUS }
>

type GetAppUsersCountAction = Action<
  `${typeof GET_APP_USERS_COUNT}_${ActionTypeStatus}`,
  { total?: number },
  { idApp: number }
>

type GetAppAccountLicenseUsersAction = Action<
  `${typeof GET_APP_ACCOUNT_LICENSES_USERS}_${ActionTypeStatus}`,
  AppUsersResponse,
  { idApp: number, idAppAccount: number, idLicense: number, reset: boolean }
  >

export type AppUsersAction =
  | GetAppUsersAction
  | GetAppUsersFieldValuesAction
  | GetAppsOfUserAction
  | UpdateAppUserInfoAction
  | GetInactiveUsersAction
  | UpdateImportAppUsersStatusAction
  | GetAppUsersCountAction
  | GetAppAccountLicenseUsersAction
