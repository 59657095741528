import {
  UPDATE_WORKFLOW,
  CREATE_WORKFLOW,
  DUPLICATE_WORKFLOW,
  GET_WORKFLOWS_BRANCH_ACTION_FIELDS,
  GET_WORKFLOWS_BRANCHING_FILTERS_OPTION_VALUES,
  GET_WORKFLOWS,
  GET_WORKFLOW,
  CLEAR_WORKFLOW_ACTION_FIELDS,
  GET_WORKFLOW_TAGS,
  UPDATE_WORKFLOW_TAGS,
  GET_WORKFLOWS_EXECUTION_AUDIT,
  DELETE_WORKFLOW_TAG,
  UPDATE_WORKFLOW_TAG,
  CREATE_WORKFLOW_TAGS,
  GET_WORKFLOWS_EXECUTION_AUDIT_FIELD_VALUES,
  CLEAR_WORKFLOW_TRIGGER_FIELD_OPTIONS,
  GET_WORKFLOW_EDIT_HISTORY_INFO,
  GET_WORKFLOW_EDIT_HISTORY_LOGS,
  GET_WORKFLOW_TRIGGER_CONFIGURATION_FIELD
} from '@root/constants'
import { omitBy, pick, isUndefined, isNil } from 'lodash'
import send from '@shared/redux-fetch'
import {
  DeleteWorkflowActionV2Params,
  GetWorkflowProps,
  UpdateWorkflowLocallyParams,
  UpdateWorkflowParams,
  CreateWorkflowProps,
  UpdateWorkflowNameParams,
  DuplicateWorkflowParams,
  GetBranchActionFieldsOptions,
  GetWorkflowsExecutionsAuditParams,
  GetWorkflowEditHistoryInfoParams,
  GetWorkflowEditHistoryLogsParams,
  GetWorkflowTriggerConfigurationProps
} from './types'
import { getBaseApiPath } from '@shared/utils'
import { IdWorkflowNode } from '@shared/workflows/types'
import { Dispatch } from 'redux'

const getWorkflowsApiPath = (idOrg: number) => `${getBaseApiPath(idOrg)}/workflows`

export const getWorkflows = ({ idOrg, type, isHidden = false }) => {
  return send(GET_WORKFLOWS, {
    url: getWorkflowsApiPath(idOrg),
    query: { type, isHidden, includeDeleted: false }
  })
}

export const updateWorkflow = ({
  idOrg,
  idWorkflow,
  workflow,
  executeWorkflow = false,
  previewEntitiesAmount = undefined,
  executionFlow = undefined,
  idRecommendation = undefined,
  isDefaultOffboarding = false,
  shouldAutoTag = false
}: UpdateWorkflowParams) => {
  const workflowFields = [
    'actions',
    'idApp',
    'triggerType',
    'isActive',
    'name',
    'triggerConfiguration',
    'triggerIdApp',
    'triggerIdAppAccount',
    'schedules'
  ]

  const meta = {
    idWorkflow,
    workflow,
    type: workflow.type,
    version: Date.now(),
    executeWorkflow,
    isDefaultOffboarding
  }

  const body = omitBy({
    ...pick(workflow, workflowFields),
    executeWorkflow,
    previewEntitiesAmount,
    executionFlow,
    idRecommendation,
    shouldAutoTag
  }, isUndefined)

  return send(UPDATE_WORKFLOW, {
    method: 'PUT',
    url: `${getWorkflowsApiPath(idOrg)}/${idWorkflow}`,
    body: JSON.stringify(body),
    meta
  })
}

export const deleteWorkflowActionV2 = ({
  idOrg,
  idWorkflow,
  workflow
}: DeleteWorkflowActionV2Params) => (dispatch) => {
  dispatch(updateWorkflow({
    idOrg,
    idWorkflow,
    workflow
  }))
}

export const updateWorkflowLocally = ({
  idWorkflow,
  workflow
}: UpdateWorkflowLocallyParams) => (dispatch) => {
  const version = Date.now()

  dispatch({
    type: UPDATE_WORKFLOW,
    meta: {
      idWorkflow,
      workflow,
      type: workflow.type,
      locally: true,
      version
    }
  })
}

export const clearTriggerFieldOptions = (
  { idWorkflow, fieldIds }:
  { idWorkflow: number, fieldIds: string[] }
) => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_WORKFLOW_TRIGGER_FIELD_OPTIONS,
    payload: { fieldIds, idWorkflow }
  })
}

export const getWorkflow = ({
  idOrg,
  idWorkflow,
  isAppCatalogPoliciesView = false,
  isRequestNewAppPoliciesView = false,
  fields
}: GetWorkflowProps) => {
  const url = `${getWorkflowsApiPath(idOrg)}/${idWorkflow}`
  const queryParams = fields ? encodeURIComponent(fields.join()) : undefined
  return send(GET_WORKFLOW, {
    url: `${url}${queryParams ? `?fields=${queryParams}` : ''}`,
    meta: {
      isAppCatalogPoliciesView,
      isRequestNewAppPoliciesView,
      idWorkflow,
      fields,
      isPartialGet: Boolean(fields)
    }
  })
}

export const getWorkflowTriggerConfigurationField = ({
  idOrg,
  idWorkflow,
  idField,
  isAppCatalogPoliciesView = false,
  isRequestNewAppPoliciesView = false
}: GetWorkflowTriggerConfigurationProps) => {
  const url = `${getWorkflowsApiPath(idOrg)}/${idWorkflow}/triggerConfiguration/${idField}`
  return send(GET_WORKFLOW_TRIGGER_CONFIGURATION_FIELD, {
    url,
    meta: {
      idWorkflow,
      idField,
      isAppCatalogPoliciesView,
      isRequestNewAppPoliciesView
    }
  })
}

export const createWorkflow = ({
  idOrg,
  type,
  triggerIdApp,
  triggerIdAppAccount,
  triggerType,
  triggerConfiguration,
  triggerTypeIdApp,
  idWorkflowTemplate,
  isHiddenForCustomers
}: CreateWorkflowProps) => {
  const body = omitBy({
    type,
    triggerIdApp,
    triggerIdAppAccount,
    idWorkflowTemplate,
    isHiddenForCustomers,
    triggerType,
    triggerConfiguration,
    idApp: triggerTypeIdApp
  }, isUndefined)
  return send(CREATE_WORKFLOW, {
    method: 'POST',
    url: `/api/orgs/${idOrg}/workflows`,
    body: JSON.stringify(body)
  })
}

export const updateWorkflowName = ({
  idOrg,
  idWorkflow,
  workflow,
  name
}: UpdateWorkflowNameParams) => {
  return updateWorkflow({
    idOrg,
    idWorkflow,
    workflow: { ...workflow, name }
  })
}

export const duplicateWorkflow = ({
  idOrg,
  idWorkflow,
  name,
  triggerName,
  buttonLocation
}: DuplicateWorkflowParams) => {
  return send(DUPLICATE_WORKFLOW, {
    url: `/api/orgs/${idOrg}/workflows/${idWorkflow}/duplicate`,
    method: 'POST',
    meta: { name, idWorkflow, triggerName, buttonLocation }
  })
}

export const getBranchActionFieldsOptions = ({ idOrg, triggerType }: GetBranchActionFieldsOptions) => {
  return send(GET_WORKFLOWS_BRANCH_ACTION_FIELDS, {
    url: `/api/orgs/${idOrg}/workflows/branchingFilters/options`,
    query: {
      triggerType
    }
  })
}

export const getBranchingFilterOptionsValues = ({ idOrg, filters }) => {
  return send(GET_WORKFLOWS_BRANCHING_FILTERS_OPTION_VALUES, {
    method: 'POST',
    url: `/api/orgs/${idOrg}/workflows/branchingFilters/values`,
    body: JSON.stringify({ filters })
  })
}

export const getWorkflowEditHistoryInfo = ({ idOrg, idWorkflow, idWorkflowEditHistory }: GetWorkflowEditHistoryInfoParams) => {
  return send(GET_WORKFLOW_EDIT_HISTORY_INFO, {
    url: `/api/orgs/${idOrg}/workflows/${idWorkflow}/editHistory/${idWorkflowEditHistory}`
  })
}

export const getWorkflowEditHistoryLogs = ({ idOrg, idWorkflow, limit = 50, lastIdWorkflowEditHistory, reset = true }: GetWorkflowEditHistoryLogsParams) => {
  const query = omitBy({
    limit,
    lastIdWorkflowEditHistory
  }, isNil) as Record<string, number>

  return send(GET_WORKFLOW_EDIT_HISTORY_LOGS, {
    url: `/api/orgs/${idOrg}/workflows/${idWorkflow}/editHistory`,
    query,
    meta: { idWorkflow, reset }
  })
}

export const clearActionFieldsOptions = ({ idAction }: { idAction: IdWorkflowNode }) => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_WORKFLOW_ACTION_FIELDS,
    payload: { idAction }
  })
}

export const getWorkflowTags = ({ idOrg }: { idOrg: number }) => {
  return send(GET_WORKFLOW_TAGS, {
    url: `/api/orgs/${idOrg}/workflows/tags`
  })
}

export const updateWorkflowTags = ({ idOrg, idWorkflow, idTags }: { idOrg: number, idWorkflow: number, idTags: number[] }) => {
  return send(UPDATE_WORKFLOW_TAGS, {
    method: 'PUT',
    url: `/api/orgs/${idOrg}/workflows/${idWorkflow}/tags`,
    meta: {
      idWorkflow,
      idTags
    },
    body: JSON.stringify({ idTags })
  })
}

export const deleteWorkflowTag = ({ idOrg, idTag }: { idOrg: number, idTag: number }) => {
  return send(DELETE_WORKFLOW_TAG, {
    method: 'DELETE',
    url: `/api/orgs/${idOrg}/workflows/tags/${idTag}`,
    meta: { idTag }
  })
}

export const getWorkflowsExecutionsAudit = ({
  idOrg,
  offset,
  reset,
  limit,
  sort,
  filters,
  q
}: GetWorkflowsExecutionsAuditParams) => {
  type Query = Omit<GetWorkflowsExecutionsAuditParams, 'idOrg' | 'reset' | 'filters'> & { filters: string }

  const query: Query = { limit, sort, filters: JSON.stringify(filters) }
  if (offset) {
    query.offset = offset
  }
  if (q) {
    query.q = q
  }

  return send(GET_WORKFLOWS_EXECUTION_AUDIT, {
    url: `/api/orgs/${idOrg}/workflows/executions`,
    query,
    meta: { reset: reset || !offset }
  })
}

export const getWorkflowExecutionsFieldValues = ({ idOrg, fields }: { idOrg: number, fields: string[] }) =>
  send(GET_WORKFLOWS_EXECUTION_AUDIT_FIELD_VALUES, {
    url: `/api/orgs/${idOrg}/workflows/executions/fieldValues`,
    query: {
      fields: JSON.stringify(fields)
    }
  })

export const updateWorkflowTag = ({ idOrg, idTag, label }: { idOrg: number, idTag: number, label: string }) => {
  return send(UPDATE_WORKFLOW_TAG, {
    method: 'PUT',
    url: `/api/orgs/${idOrg}/workflows/tags/${idTag}`,
    meta: { idTag, label },
    body: JSON.stringify({ label })
  })
}

export const createWorkflowTags = ({ idOrg, tags }: { idOrg: number, tags: string[] }) => {
  return send(CREATE_WORKFLOW_TAGS, {
    method: 'POST',
    url: `/api/orgs/${idOrg}/workflows/tags`,
    body: JSON.stringify({ tagLabels: tags })
  })
}
